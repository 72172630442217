import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import { USER_LOGIN_URL } from '../urls.js';
import Api from '../Api.js';
import { grey } from '@material-ui/core/colors';

import {
  Card,
  Card as CardActions,
  Avatar,
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: grey[800],
    //background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  cardActions: {
    margin: '1em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary[500],
    width: '50px',
    height: '50px',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    width: '100%',
  },
});

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    redirectToReferrer: false,
    loginError: false
  }

  handleLogin = () => {
    var data = this.state;
    Api.userLogin(USER_LOGIN_URL, data, (data) => {
      if (data.loggedIn ) {
        this.setState({
          user: data,
          redirectToReferrer: true,
          isLoading: false,
        });
      }
      else {
        this.setState({
          loggedIn: false,
          isLoading: false,
          loginError: true,
        });
      }
    });

  };

  handleChange = name => event => {
    this.setState({
        [name]: event.target.value
    });
  };

  catchReturn = (event) =>  {
    if (event.key === 'Enter') {
      console.log("Enter was pressed!");
      this.handleLogin();
      event.preventDefault();
    }
  };

  render() {
    const { classes } = this.props;
    const { redirectToReferrer } = this.state;
    const { from } = this.props.location.state || { from: { pathname: "/" } };

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon color='secondary' style={{ fontSize: 36 }} />
            </Avatar>
          </div>
         <div className={classes.form}>
           { this.state.loginError && <Typography variant="body2" gutterBottom>Login failed</Typography> }
           <div className={classes.input}>
             <TextField data-testid="email" name="email" label="E-Mail" fullWidth onChange={this.handleChange('email')}/>
           </div>
           <div className={classes.input}>
             <TextField data-testid="password" fullWidth label="Password" name="password" onKeyPress={this.catchReturn} onChange={this.handleChange('password')} type="password" />
           </div>
         </div>
         <CardActions className={classes.cardActions}>
           <Button
             variant="contained"
             type="submit"
             color="primary"
             className={classes.button}
             onClick={this.handleLogin}
           >
             Login
           </Button>
         </CardActions>
         <div className={classes.form}>
           <Link to='/password/recover'>
             <Typography variant="body2" color="textSecondary">
               Kennwort vergessen?
             </Typography>
           </Link>
         </div>
        </Card>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
