export function ProductStatus(validFrom, validTo) {
  const today = new Date();

  if (validFrom) {
    if (new Date(Date.parse(validFrom)) > today) { // #valid_from in future
      return "inactive";
    } else {
      if ( validTo && new Date(Date.parse(validTo)) < today) { // #valid_to in past
        return "deactivated";
      } else {
        return "activ";
      }
    }
  } else {
    return "new";
  }

}
