import React from 'react';
import PropTypes from 'prop-types';
import Row from './Row';

import { Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
  table: {
    width: '750px',
 },
  tableRow: {
    height: '29px',
  },
  baseColumn: {
    borderBottom: 'none',
    paddingLeft: '10px',
  },
  keyColumn: {
    fontSize: '1.1em',
    fontWeight: '500',
    paddingRight: '0px',
    textAlign: 'right',
    verticalAlign: 'top',
    width: '10em',
  },
});

class KeyValue extends React.Component {

  state = {
    rows: [{
      keyItem: '',
      valueItem: ''
    }]
  };

  handleItemChange = (name, index) => (event) => {
    this.props.onRowChange(name, event.target.value, index);
  }

  handleRemove = (index) => {
    this.props.onRowRemove(index);
  }

  renderRows() {
    const { rows } = this.props;
    return rows.map((row, idx) => (
      <Row 
        handleItemChange={this.handleItemChange} 
        handleRemove={this.handleRemove} 
        idx={idx} 
        key={idx} 
        row={row} 
        keyInputPlaceholder={this.props.keyInputPlaceholder}
        valueInputPlaceholder={this.props.valueInputPlaceholder}
        labelNameValues={this.props.labelNameValues}
      />
    ));
  }


  render() {
    return (
      <Grid container item xs={12} spacing={1}>
        { this.renderRows() }
      </Grid>
    );
  }
}

KeyValue.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    keyItem: PropTypes.string,
    valueItem: PropTypes.string
  })),
  onChange: PropTypes.func,
  customAddButtonRenderer: PropTypes.func,
  keyInputPlaceholder: PropTypes.string,
  valueInputPlaceholder: PropTypes.string,
};

KeyValue.defaultProps = {
  rows: [],
  onChange: () => {},
  keyInputPlaceholder: 'Key',
  valueInputPlaceholder: 'Value',
  hideLabels: false
}

export default withStyles(styles)(KeyValue);
