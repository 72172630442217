import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ProductContext } from '../../../providers/ProductProvider';
import { Checkbox } from '@material-ui/core';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);


export default function VariantSetActiveCheckbox(props) {
  const {actions, ctxProduct } = React.useContext(ProductContext);
  const {variantSet} = props;

  if (!ctxProduct.active_variant_set) {
    return (
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        onChange={() => actions.onVarianSetActivate(variantSet)}
        indeterminate
      />
    );
  }
  else if (ctxProduct.active_variant_set.id === variantSet.id) {
    return (
      <GreenCheckbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        onChange={() => actions.onVarianSetActivate(variantSet)}
        checked={true}
      />
    );
  }
  else {
    return (
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        checked={false}
        onChange={() => actions.onVarianSetActivate(variantSet)}
      />
    );
  }
}

