import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import ReorderIcon from '@material-ui/icons/Reorder';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Row from './Row';
import ConfirmDialog  from './ConfirmDialog';
import Utils from '../../Utils';

import { IconButton, Grid, withStyles, RootRef } from '@material-ui/core';

import { red, blueGrey } from '@material-ui/core/colors';

const styles = theme => ({
  table: {
    width: '750px',
   },
});
const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  width: '100%',

  // change background colour if dragging
  background: isDragging ? '#ffffff' : '#ffffff',
  border: isDragging ? '1px solid #eeeeee' : '1px solid #bbbbbb',
  paddingTop: '20px',
  paddingBottom: '30px',

  // styles we need to apply on draggables
  ...draggableStyle,
});


const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#ebebeb' : '#ebebeb',
  padding: grid,
});

class DynamicRowForm extends React.Component {
  state = {
    confirmOpen: false,
  };

  handleChange = (attr,idx) => event => {
    this.props.onRowChange(attr, event.target.value, idx);
  };

  handleRtfChange = (attr,idx) => value => {
    //console.debug("Value: ", value);
    //console.debug("AttributeKey: ", attr);
    this.props.onRowChange(attr, value, idx);
  };

  /*
  handleRemove = (index) => {
    console.log("Remove", index);
    this.props.onRowRemove(index);
  };
  */

  openConfirmWithIdx = (value, idx) => {
    this.setState({ confirmOpen: value, deleteItemIdx: idx });
  };

  setConfirmOpen = (value) => {
    this.setState({ confirmOpen: value });
  }

  setConfirmDeclined() {
    this.setState({
      deleteItemIdx:null,
      confirmOpen: false,
    });
  }

  handleRemove() {
    console.log("Remove deleteItemIdx", this.state.deleteItemIdx);
    this.props.onRowRemove(this.state.deleteItemIdx);
    this.setState({deleteItemIdx:null });
  };


  onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) return;
    this.props.onRowReorder(source.index, destination.index);
  };

  rowsWithData = (schema, data) => {
    if (Utils.isEmpty(data)) {
      return [[...schema]];
    }
    return data.map((row) => {
      var newRow = [...schema];
      return newRow.map((x) => {
        return {...x, value: row[x.attr] || x.value};
      });
    });  
  };

  renderForm = () => {
    const { schema, classes, rows } = this.props;

    const formRows = this.rowsWithData(schema,rows);
    //console.debug("Rows with data: ", formRows);
    const formUi = formRows.map((row, idx) => {
      return (
        <Draggable
          draggableId={`x-${idx}`}
          key={idx}
          index={idx}
        >
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef} >
              <Grid 
                container item xs={12} 
                spacing={1}
                justify="space-between"
                alignItems="center"
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <Grid item xs={1}>
                  <ReorderIcon style={{ color: blueGrey[600], fontSize: 24 }} />
                </Grid>
                <Grid container item xs={10} spacing={1}>
                  <Row 
                    model={row} 
                    classes={classes} 
                    onChange={this.handleChange}  
                    onRtfChange={this.handleRtfChange}
                    idx={idx} />
                </Grid>
                <Grid item xs={1}>
                  { row.filter(col => (col.attr === "position" && col.value || col.value >= 0 )).length > 0  &&
                    <div>
                      <IconButton aria-label="delete" onClick={() => this.openConfirmWithIdx(true, idx)}>
                        <DeleteIcon style={{ color: red[600], fontSize: 24 }} />
                      </IconButton>
                      <ConfirmDialog
                        title="Löschen bestätigen"
                        open={this.state.confirmOpen}
                        setOpen={this.setConfirmOpen}
                        onConfirm={() => this.handleRemove()}
                        onDecline={() => this.setConfirmDeclined()}
                      >
                          Wirklich löschen?
                        <p style={{fontWeight: '200', fontSize: '0.75em'}}>
                          Speichern nicht vergessen!
                        </p>
                      </ConfirmDialog>
{/*
                      <Button 
                        color="primary" 
                        aria-label="Delete" size="small"
                        onClick={ () => this.handleRemove(idx) }>
                        <DeleteIcon style={{ color: red[600], fontSize: 24 }} />
                      </Button>
*/}
                  </div>}
                </Grid>
              </Grid>
            </RootRef>
          )}
        </Draggable>
      );
    });
    return formUi;

  };

  render() {
    if (this.props.rows.length < 1) {
      return ( <div></div> );
    }
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppabe-list">
          {(provided,snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <Grid container item xs={12} spacing={1}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                { this.renderForm() }
              {provided.placeholder}
              </Grid>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default withStyles(styles)(DynamicRowForm);
