import React from 'react';
import Api from '../../Api';
import to from '../../to';

import { 
  CircularProgress, 
  FormControl, 
  FormHelperText,
  Select, 
  MenuItem, 
  makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
}));

export default function RiskBearerSelect(props) {
  const classes = useStyles();
  const [items, setItems] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [riskBearer, setRiskBearer] = React.useState([]);


  const fetchRiskBearers = async() => {
    setLoading(true);
    const [err, data] = await to(Api.yfetch('get', '/risk_bearers'));
    if (err) console.log("scope=RiskBearer action=fetching result=", err);
    if (!err) {
      setItems(data.risk_bearers)
    }
    setLoading(false)
  }

  const handleChange = event => {
    props.onRiskBearerChange(event.target.value)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
      fetchRiskBearers();
    },[]
  );

  function renderItems() {
    console.log("Render item called, items:", items);
     return items.map((item, i) => {
       return (
         <MenuItem
           key={item.id} 
           value={item.id}
         >
           {item.name}
         </MenuItem>
         
       );
     });
    }

  if(loading) return (
    <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
      <CircularProgress size={20} />
    </div>
   );

  return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Select
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={props.currentRiskBearerId}
            onChange={handleChange}
            fullWidth={true}
          >
            {renderItems()}
          </Select>
          <FormHelperText>Bitte wählen, abwählen durch zurücksetzen</FormHelperText>
        </FormControl>
      </div>

  );
}
