/*
 * An action is an object with a type and arbitrary other properties,
 * e.g. a value. The reducer is called by the component with 
 * dispatch({type: "setSearchTerm", value: "dogs*"});
 *
 * With an ReactComponent, the context can be used like this:
 *
 * static contextType = ProductsContext;
 *
 * render() {
 *   const [{counter}, dispatch] = this.context;
 *   ...
 *   return (
 *   <>
 *   ...
 *   <>
 *   );
 * }
 *
 */
export const productsReducer = (state, action) => {
  switch (action.type) {
    case "setProductSearchTerm":
      return {
        ...state,
        productSearch: {
          ...state.productSearch,
          query: {
            ...state.productSearch.query,
            multi_match: {
              ...state.productSearch.query.multi_match,
              searchTerm: action.payload
            }
          }
        },
        page: {
          page: 1,
          per_page: 10,
        },
      };
    case "setFilter":
      return {
        ...state,
        productSearch: {
          ...state.productSearch,
          ...{ filters: action.payload }
        }, 
        page: {
          page: 1,
          per_page: 10,
        },
      };
    case "setSort":
      const col = action.payload;
      return {
        ...state,
        productSearch: {
          ...state.productSearch,
          ...{sort: { 
            field: col.id,
            fieldType: col.type,
            direction: state.productSearch.sort.direction == 'asc' ? 'desc' : 'asc'
          }}
        },
        page: {
          page: 1,
          per_page: 10,
        },
      };
    case "setPaginator":
      return {
        ...state,
        paginator: action.payload
      };
    case "setPage":
      return {
        ...state,
        page: action.payload // {page: 1, per_page: 10}
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
