import React from 'react';
import { extractDistinctValues } from "../../../utils/schemaUtils";


import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  withStyles,
} from '@material-ui/core';


function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function TableHeader()  {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Spaltenname</TableCell>
        <TableCell>Attributname</TableCell>
        <TableCell>Titel</TableCell>
        <TableCell>Datentyp</TableCell>
        <TableCell>mögl. Werte</TableCell>
      </TableRow>
    </TableHead>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = {
  formControl: {
    margin: 0,
    //    fullWidth: true,
    minWidth: '120px',
    maxWidth: '220px',
    display: "flex",
    wrap: "nowrap"
  }
};



function ProductVariantSchemaMapper(props) {
  const { csvData, classes, schema, setSchema, } = props;
  //const [schema, setSchema] = React.useState(buildSchema(csvData[0]));

  const csvColumns = csvData[0];

  const allSelected = (idx, attributeName) => {
    return schema['attributes'][attributeName]['enum'].length === extractDistinctValues(csvData.slice(1), idx).length;
  };

  const handleChange = (attributeName, attribute_key) => (e) => {
    let _schema = {...schema};
    _schema['attributes'][attributeName][attribute_key] = e.target.value; 
    setSchema(_schema);
  }

  const handleTextfieldChange = (name,idx) => event => {
    //setValues({ ...values, [name]: event.target.value });
  };

  const handleAllowedValuesChange = (idx,attributeName) => (e) => { 
    let _schema = {...schema};
    const allEnumSelected = allSelected(idx, attributeName);

    if (e.target.value.includes('selectAll') ) {
      if(allEnumSelected) {
        _schema['attributes'][attributeName]['enum'] = []; 
      }
      else {
        _schema['attributes'][attributeName]['enum'] = extractDistinctValues(csvData.slice(1), idx); 
      }
    }
    else {
      _schema['attributes'][attributeName]['enum'] = e.target.value; 
    }
    setSchema(_schema);
  };

  return (
    <div>
      {(csvColumns.length > 0 ) && <div>
        <Paper style={{overflowX: 'auto',}}>
          <Table>
            <TableHeader />
            <TableBody>
              {csvColumns.map( (col, idx) => {
                const attribute_name = col.split(/(?=[A-Z])/).join('_').toLowerCase();
                return (
                <TableRow key={idx}>
                    <TableCell>
                      {col}
                    </TableCell>
                    <TableCell>
                      {attribute_name}
                    </TableCell>
                    <TableCell>
                      <FormControl className={classes.formControl}>
                        <TextField
                          id="title"
                          label="Titel"
                          value={schema['attributes'][attribute_name]['title'] || ""}
                          onChange={handleChange(attribute_name,'title')}
                          margin="normal"
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="allowed-data-type">Datentyp des Attributes</InputLabel>
                      <Select
                        value={schema['attributes'][attribute_name]['type']}
                        onChange={handleChange(attribute_name,'type')}
                        inputProps={{
                          name: 'dataType',
                          id: 'allowed-data-type',
                        }}
                      >
                        <MenuItem value="">
                          <em>Datentyp wählen</em>
                        </MenuItem>
                        <MenuItem value={'string'}>Zeichenkette</MenuItem>
                        <MenuItem value={'number'}>Zahl</MenuItem>
                        <MenuItem value={'integer'}>Ganzzahl</MenuItem>
                        <MenuItem value={'money'}>Preis</MenuItem>
                        <MenuItem value={'boolean'}>Boolesch</MenuItem>
                      </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <div>
                      <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="select-multiple-checkbox">Erlaubte Werte</InputLabel>
                      <Select
                        multiple
                        value={schema['attributes'][attribute_name]['enum'] || []}
                        onChange={handleAllowedValuesChange(idx, attribute_name, 'enum')}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="selectAll" >
                          <Checkbox checked={allSelected(idx, attribute_name)} />
                          <ListItemText primary={allSelected(idx, attribute_name) ? "Select None" : "Select all"} />
                        </MenuItem>
                        {extractDistinctValues(csvData.slice(1), idx).map(optionName => (
                          <MenuItem key={optionName} value={optionName}>
                            <Checkbox checked={schema['attributes'][attribute_name]['enum'].indexOf(optionName) > -1} />
                            <ListItemText primary={optionName} />
                          </MenuItem>

                        ))}
                      </Select>
                      </FormControl>
                      </div>
                      {false && <div>
                        <TextField
                          id="minimum"
                          label="Minimaler Wert"
                          value={schema['attributes'][attribute_name]['minimum']}
                          onChange={handleTextfieldChange('minimum',idx)}
                          margin="normal"
                          type="number"
                        />
                      </div>}
                      {false && <div>
                        <TextField
                          disabled={!(schema['attributes'][attribute_name]['type'] === 'integer')}
                          id="maximum"
                          label="Maximaler Wert"
                          value={schema['attributes'][attribute_name]['maximum']}
                          onChange={handleTextfieldChange('maximum',idx)}
                          margin="normal"
                          type="number"
                        />
                      </div>}
                    </TableCell>
                </TableRow>
                )})}
            </TableBody>
          </Table>
        </Paper>
      </div>}
    </div>
  );
}

export default withStyles(styles)(ProductVariantSchemaMapper);
