import React from "react";
import { IconButton, Hidden, withStyles } from "@material-ui/core";
import { Person, ExitToApp } from "@material-ui/icons";
import { Redirect } from "react-router-dom";

import headerLinksStyle from "../assets/jss/dashboard/headerLinksStyle";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    redirect: false,
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleLogout = () => {
    localStorage.removeItem('accessToken');
    this.setState({ redirect: true });
  };

  render() {
    const { classes } = this.props;
    const { open, redirect } = this.state;

    if (redirect) {
      return <Redirect to='/login' />;
    }

    return (
      <div>
        <IconButton
          color="inherit"
          aria-label="Logout"
          className={classes.buttonLink}
          onClick={this.handleLogout}
        >
        <ExitToApp className={classes.links} />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="Person"
          className={classes.buttonLink}
        >
          <Person className={classes.links} />
          <Hidden mdUp>
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </IconButton>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
