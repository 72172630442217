/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { AppSettingsConsumer } from '../../providers/AppSettingsProvider'
import Notifier from '../Notifier';
import Save from '@material-ui/icons/Save';

import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/json';
import 'brace/theme/github';

import SubNavigation from '../SubNavigation';
import { CancleButton, SaveButton } from '../SubNavigationActionButtons';

import { withStyles, Button, Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    //    display: 'flex',
    //flex: 1,
  },
  content: {
    padding: '20px 20px 0 20px',
  }
});

//  const formattedValue = mode === 'json' && isJsonString(value) ? JSON.stringify(JSON.parse(value), null, 2) : value;

class ApplicationSettings extends React.Component {
  constructor(props) {
    super(props);
  }

  // onChange={(newValue) => { console.log("New vlue:", newValue)} }

  render () {
    const { classes, appActions, notification  } = this.props;
    return (
      <div className={classes.root}>
       <Notifier notification={notification} />
        <SubNavigation title="Application Setting Details" backAction={() => this.props.history.goBack()}>
          <CancleButton onClick={() => {this.props.history.push("/")}}/>
          <SaveButton onClick={ appActions.onSave }/>
        </SubNavigation>
        <Grid className={classes.content} container spacing={2}>
          {/*
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button color="primary" onClick={appActions.onSave} variant="contained" size="small" >
              <Save className={""} />
              Save
            </Button>
          </Grid>
          */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AceEditor
              mode="json"
              theme="github"
              value={this.props.appSettings}
              onChange={appActions.handleChange }
              editorProps={{$blockScrolling: true}}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
              }}
              width="100%"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
// value={JSON.stringify(this.props.appSettings, null, 2)}

ApplicationSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

const withAppSettingsContext = (Component) => {
  return (props) => (
    <AppSettingsConsumer>    
      {({appSettingsString, appActions, appSettingsNotification}) => {
        return <Component {...props} 
          appActions={appActions} 
          appSettings={appSettingsString} 
          notification={appSettingsNotification}
          />
      }}
    </AppSettingsConsumer>
  )
};

//export default withContext(ApplicationSettings); 
export default withStyles(styles)(withAppSettingsContext(ApplicationSettings));
//export default withStyles(styles)(ApplicationSettings);
