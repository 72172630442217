import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Delete, Visibility } from "@material-ui/icons";
import Api from '../../Api';
import to from '../../to';
import TablePagination from '../TablePagination';

import RiskBearerSearch from './RiskBearerSearch';

import { AddButton } from '../SubNavigationActionButtons';
import SubNavigation from '../SubNavigation';


import { blueGrey, red } from '@material-ui/core/colors';


import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from '@material-ui/core';


const styles = theme => ({
  root: {
  },
  content: {
    padding: '30px',
  },
});

class RiskBearerList extends React.Component {

  state = {
    riskBearers: [],
    isLoading: false,
    paginator: {
      perPage: 5,
      total: 18,
    },
  };

  fetchRiskBearers = async (page = {page:1, per_page: 10}) => {
    this.setState({ isLoading: true });

    const params = {
      ...page
    };

    const queryParamsString = Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key]))
      .join('&');

    const [err, data] = await to(Api.paginated_fetch('get', `/risk_bearers/?${queryParamsString}`));

    if (!err) {
      this.setState({
        riskBearers: data.risk_bearers,
        paginator: data.paginator,
        isLoading: false,
      });
    }
  };

  handleChangePage = (event, page) => {
    console.log("action=handleChangePage page_param=", page);
    this.fetchRiskBearers({page: page.page, per_page: page.per_page});
  };


  componentDidMount() {
    this.fetchRiskBearers();
  }

  fetchRiskBearersWithQuery  = async (query) => {
    this.setState({ isLoading: true });
    const bodyParameter = encodeURIComponent(JSON.stringify(query));
    const [err, data] = await to(Api.paginated_fetch('get', `/risk_bearers/?_q=${bodyParameter}`));
    if (!err) {
      //console.log("Data:", data);
      this.setState({
        riskBearers: data.risk_bearers,
        paginator: data.paginator,
        isLoading: false,
      });
    } else {
      this.setState({
        riskBearers: [],
        isLoading: false,
      });
    }
  };

  handleClick = riskBearerId => event => {
    return true;
  };

  render() {
    const { classes } = this.props;

    if (this.state.isLoading) { return null }

    return (
    <div>
      <SubNavigation title="" backAction={() => this.props.history.goBack()} >
        <AddButton onClick={() => {this.props.history.push("/risk_bearers/new")}}/>
      </SubNavigation>
      <div className={classes.content}>
        <RiskBearerSearch onRequestSearch={this.fetchRiskBearersWithQuery} />
        <Paper className={classes.root} style={{padding: '10px'}}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              { this.state.riskBearers.length > 0 &&
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell size="small">Name</TableCell>
                    <TableCell>Angelegt am</TableCell>
                    <TableCell align="right"> {this.state.isLoading && <CircularProgress size={18} />}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.riskBearers && this.state.riskBearers.map(s => {
                    let createdAt = new Date(Date.parse(s.created_at));
                    return (
                      <TableRow key={s.id}>
                        <TableCell>{s.name}</TableCell>
                        <TableCell>{createdAt.toLocaleDateString('de-DE')}, {createdAt.toLocaleTimeString('de-DE')}</TableCell>
                        <TableCell>
                          <Link to={`/risk_bearers/${s.id}`}>
                            <Button color="primary">
                              <Visibility style={{ color: blueGrey[600],fontSize: 24 }} />
                            </Button>
                          </Link>
                          <Button onClick={this.handleClick(s.id)} color="primary">
                            <Delete style={{ color: red[600], fontSize: 24 }} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              }
              { this.state.riskBearers.length < 1 && <h3>Keine Daten</h3> }
              <TablePagination
                paginator={this.state.paginator}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />

            </Grid>
           </Grid>
        </Paper>
      </div>
    </div>
    );
  }
}

RiskBearerList.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RiskBearerList);
