import React from "react";
import PropTypes from 'prop-types';
import { IconButton, Typography, makeStyles } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex', 
    alignItems: 'center',
  },
  button: {
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    padding: 'unset',
  },
  icon: {
    fontSize: 20,
    opacity: 0.7,
  },
  title: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: "400",
  },
}));

function AddEntityButton(props) {
  const classes = useStyles();
  const { onClick, label } = props;
  return (
    <div className={classes.root}>
      <Typography className={classes.title} noWrap variant="subtitle2">
        {label}
      </Typography>
      <IconButton 
        className={classes.button} 
        aria-label={`Add ${label}`}
        onClick={onClick}>
        <AddCircleOutline className={classes.icon}/>
      </IconButton>
    </div>
  );
}

AddEntityButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};


export default AddEntityButton;
