import React from 'react';
import { CircularProgress, Button, Table, TableBody, TableCell, TableHead, TableSortLabel, TableRow, withStyles } from '@material-ui/core';
import { Visibility } from "@material-ui/icons";
import { blueGrey } from '@material-ui/core/colors';

const styles = theme => ({
  activ: {
    borderRight: '2px solid #81c784',
  },
  inactive: {
    borderRight: '2px solid #ffb74d',
  },
  deactivated: {
    borderRight: '2px solid #ff8a65',
  },
  new: {
    borderRight: '2px solid #2979ff',
  },
});
function EnhancedTableHead(props) {
  const {columns, isLoading, } = props;
  const orderBy = 'internal_name';
  return (
    <TableHead>
      <TableRow>
        {columns.map(col => (
          <TableCell 
            key={col.id}
            size="small"
            align={col.id === 'actions' ? 'right' : 'left'}
          >
            <TableSortLabel
              active={orderBy === col.id}
              onClick={col.customSort ? () => col.customSort(col) : () => alert("No CustomSort") }
            >
              {(col.id === 'actions' && isLoading) ? <CircularProgress size={18} /> : col.label }
            </TableSortLabel>
          </TableCell>
        ))}
          <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableCell = (props) => {
  const {breedList, cellStyle, col } = props;
  let columnContent;

  if (col.type === 'datetime') {
    const createdAt = new Date(Date.parse(breedList.created_at));
    columnContent = `${createdAt.toLocaleDateString('de-DE')}, ${createdAt.toLocaleTimeString('de-DE')}`;
  } else if (col.type === 'string' || col.type ==='integer') {
    columnContent = breedList[col.id];
  } else {
    columnContent = '-';
  }

  return (
    <TableCell 
      style={ cellStyle } 
    >
      {columnContent}
    </TableCell>
  );
}

const ActionsTableCell = (props) => {
  const {breedList, } = props;
  let detailsButton;

    detailsButton = (
      <Button onClick={props.onHandleClick(breedList.id)} color="primary">
        <Visibility style={{ color: blueGrey[600],fontSize: 24 }} />
      </Button>
    );

  return (
    <TableCell>
      {detailsButton}
    </TableCell>
  );
}

function BreedListTable(props){
  const {breedLists, classes, parentClasses, columns, isLoading } = props;

  return (
    <Table className={parentClasses.table}>
      <EnhancedTableHead isLoading={isLoading} columns={props.columns} />
      <TableBody>
        {breedLists.map(s => {
          return (
            <TableRow key={s.id}>
              {columns.map(col => (
                <EnhancedTableCell 
                  key= {col.id}
                  col={col}
                  breedList={s}
                />
              ))}
              <ActionsTableCell
                breedList={s}
                onHandleClick={props.onHandleClick}
              />
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
export default withStyles(styles)(BreedListTable);
