import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';


function TableHeader(props) {
  const { columnNames } = props;

  return (
    <TableHead>
      <TableRow>
      {columnNames.map( (col, idx) => (
        <TableCell key={idx}>{col}</TableCell>
      ))}
      </TableRow>
    </TableHead>
  );
}

export default function ProductVariantsTable(props) {
  const {variants, variantSchema } = props;
  const columnNames = Object.keys(variantSchema.attributes);

  return (
    <Table>
      <TableHeader columnNames={columnNames} />
      <TableBody>
        {variants.map( (row, idx) => (
          <TableRow key={idx}>
          {columnNames.map( (col, colIdx) => (
            <TableCell key={colIdx}>{row[col]}</TableCell>
          ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
