import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const ConfirmDialog = ({show, proceed, cancel, confirmation}) => {
  return (
  <Dialog
    open={show}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Bitte bestätigen</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {confirmation}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={cancel} color="primary">
        Ablehnen
      </Button>
      <Button onClick={proceed} color="primary" autoFocus>
        Bestätigen
      </Button>
    </DialogActions>
  </Dialog>
  )
}

ConfirmDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  confirmation: PropTypes.string,  // arguments of your confirm function
}

export default ConfirmDialog;
