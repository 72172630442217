import React from "react";
import DynamicRowForm from '../input-dynamic-row-form/index';
import { IconButton, Typography, makeStyles } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { AppSettingsContext } from '../../providers/AppSettingsProvider';
import { ProductContext } from '../../providers/ProductProvider';


function planSchemaBuilder(ui) {
  return [
    {
      attr: "position", 
      key: "position", 
      label: "Position", 
      type: "hidden",
      props: {min: 0, max: 50}, 
      size: 1, 
      value: '' 
    },
    {
      attr: "title", 
      key: "title", 
      label: "Title", 
      type: "dropdown",
      values: ui.plan_details_titles,
      size: 4, 
      value: '' 
    },
    {
      attr: "icon", 
      key: "icon", 
      label: "Icon", 
      type: "dropdown",
      values: ui.plan_details_icons,
      size: 3, 
      value: '' 
    },
    {
      attr: "content", 
      key: "valueItem", 
      label: "Content", 
      type: "rte",
      props: {required: true}, 
      size: 12, 
      value: '' 
    },
    {
      attr: "content_2", 
      key: "content_2", 
      label: "Content 2", 
      type: "rte",
      props: {required: true}, 
      size: 12, 
      value: '' 
    },
  ];
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  button: {
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    padding: 'unset',
  },
  icon: {
    fontSize: 20,
    opacity: 0.7,
  },
  title: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: "400",
  },
}));

function emptyPlanDetail(array, pos) {
  const emptyPlanDetail = 
    array.reduce((obj, item) => {
      obj[item.attr] = item.value;
      return obj
    }, {});

  emptyPlanDetail.position = pos;
  return emptyPlanDetail;
}


function ProductPlanDetails(props) {
  const classes = useStyles();
  const { appSettings } = React.useContext(AppSettingsContext);
  const { ctxProduct, actions } = React.useContext(ProductContext);

  let planDetailTitles = [];
  let planDetailIcons = [];

  try {
    const insuranceSectorUiSettings = appSettings.data.insurance_sector_ui[ctxProduct.insurance_sector];
    if (!insuranceSectorUiSettings) {
      throw new Error(`missing appSettings.insurance_sector_ui for product.insurance_sector=${ctxProduct.insurance_sector}`);
    }
    if (!insuranceSectorUiSettings.hasOwnProperty("plan_details_titles") ) {
      throw new Error(`missing appSettings.insurance_sector_ui.plan_details_titles for product.insurance_sector=${ctxProduct.insurance_sector}`);
    }
    if(!insuranceSectorUiSettings.hasOwnProperty("plan_details_icons") ){
      throw new Error(`missing appSettings.insurance_sector_ui.plan_details_icons for product.insurance_sector=${ctxProduct.insurance_sector}`);
    }

    planDetailTitles = insuranceSectorUiSettings.plan_details_titles;
    planDetailIcons = insuranceSectorUiSettings.plan_details_icons;
    //console.debug("planDetailTitles:", planDetailTitles);
    //console.debug("planDetailIcons:", planDetailIcons);
  }
  catch(error) {
    console.error(error);
  }

  const planSchema = planSchemaBuilder({
    "plan_details_titles": planDetailTitles,
    "plan_details_icons": planDetailIcons
  });

  const AddButton = (
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <Typography className={classes.title} noWrap variant="subtitle2">
            Plan Details
          </Typography>
          <IconButton 
            className={classes.button} 
            aria-label="Add plan detail" 
            onClick={actions.onPlanAddEmptyRow(emptyPlanDetail(planSchema, ctxProduct.plan_details.length + 1))}>
            <AddCircleOutline className={classes.icon}/>
          </IconButton>
        </div>
  );
  //console.debug("PlanDetails", ctxProduct.plan_details);

  return (
    <div className={classes.root}>
      { AddButton }
      <DynamicRowForm 
        schema={planSchema}
        rows={ctxProduct.plan_details}
        onRowChange={actions.onPlanChange}
        onRowRemove={actions.onPlanRowRemove}
        onRowReorder={actions.onPlanRowReorder}
      />
      { ctxProduct.plan_details.length > 0 && AddButton }
    </div>
  );
}

export default ProductPlanDetails;
