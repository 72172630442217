import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppSettingsContext } from '../../../providers/AppSettingsProvider';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
  Button,
  DialogTitle,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { grey, orange } from '@material-ui/core/colors';

import MemberList from './MemberList';
//import ShiftPanel from '../../ShiftPanel';

const useStyles = makeStyles(theme => ({
  root: {
  },
  drawerTitle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 !important",
  },
  drawerPaper: {
    marginTop: "72px", //111px
    padding: "5px 20px 20px 20px",
    width: "75%",
  },
  saveButton: {
    color: grey[800],
    '&:hover': { 
      backgroundColor: 'limegreen',
      color: grey[50],
    },
  },
  dirty: {
    backgroundColor: orange[500],
    color: grey[800],
  },
  }),
  { name: 'HookGlobalStyles', index: 2 }
);

/***
 * UI need some overhault. The drawer overlaps the whole page, but is 
 * intended to only overlap the containing container.
 * Solution might be: 
 * https://github.com/mui-org/material-ui/issues/11749#issuecomment-452987953
 *
 * Other possible solution: ShiftPanel - but this needs further investigation
 * to make it work.
 *
 */

function BreedListDetail(props) {
  const { breedList } = props;
  const { appSettings } = React.useContext(AppSettingsContext);
  const classes = useStyles();

  const handleChange = name => event => {
    props.onAttributeChange(name,event.target.value);
  };

  const onUploadClickHandler = event => {
    props.onCsvFileChange(event.target.files[0]);
   }

  const handleUploadButtonClick = event => {
      props.onClickCsvUpload(breedList.id);
  }

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="persistent"
      anchor="right"
      open={props.open}
    >
    <DialogTitle disableTypography classes={{root: classes.drawerTitle}}>
      {!breedList.id && <IconButton className={ classes.saveButton } onClick={props.handleSave}>
         <CheckIcon />
      </IconButton>}
      <IconButton color="secondary" onClick={props.handleCloseDrawer}>
        <ClearIcon />
      </IconButton>
    </DialogTitle>
    <div style={{height: "100px"}}>
      <Grid container item xs={12} spacing={1} style={{paddingTop: "20px"}}>
        <Grid item xs={12} zeroMinWidth>
          {breedList.id && <React.Fragment>
            <Typography paragraph variant="caption">CSV-Import-Id: {breedList.id} </Typography>
            <input 
              id="raised-button-file"
              style={{ display: 'none' }} 
              type="file" 
              name="file" 
              onChange={onUploadClickHandler}/>
            <label htmlFor="raised-button-file">
              <Button 
                startIcon={<CloudUploadIcon />}
                color="default"
                variant="contained" 
                component="span" 
                size="small"
                className={classes.button}>
                Datei wählen
              </Button>
            </label>
            { props.file && <Typography>{props.file.name}</Typography> }
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className={classes.button}
              onClick={handleUploadButtonClick}
              size="small"
            >
              Importieren
            </Button>
          </React.Fragment>}
        </Grid>
        <Grid item xs={3} zeroMinWidth>
          <TextField
            value={breedList.name || ''}
            fullWidth
            label="Bezeichnung"
            margin="normal"
            onChange={handleChange("name")}
          />
        </Grid>
        <Grid item xs={3} zeroMinWidth>
          <TextField
            value={breedList.category || ''}
            fullWidth
            label="Kategorie"
            margin="normal"
            onChange={handleChange("category")}
          />
        </Grid>
        <Grid item xs={3} zeroMinWidth>
          <FormControl fullWidth={true} margin="normal">
            <InputLabel htmlFor="insurance_sector">Sparte</InputLabel>
            <Select
              displayEmpty
              value={(breedList.insurance_sector) || ''}
              onChange={handleChange("insurance_sector")}
              inputProps={{
                id: 'insurance_sector',
              }}
            >
              { appSettings.data && appSettings.data.insurance_sector.map((sector, idx) => (
                <MenuItem key={idx} value={sector}>{sector}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1} style={{paddingTop: "20px"}}>
        <Grid item xs={9} zeroMinWidth>
          <MemberList members={ breedList.breedlist_memberships || []} />
        </Grid>
      </Grid>
    </div>
    </Drawer>
  );
}

BreedListDetail.propTypes = {
  //  classes: PropTypes.object.isRequired,
  breedList: PropTypes.object.isRequired,
};

export default BreedListDetail;
