//import LinkHeaderParser from './LinkHeaderParser';
import { LinkHeader } from './parse-link-header';

const auth_token = () => localStorage.getItem('accessToken');
//const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

/*
 * TODO: refactor api-calls to use this function
 */
function handleErrors(response) {
  if (!response.ok) {
    throw Error("API-called failed with status:" + response.statusText);
  }
  return response;
}

function userLogin(url,data,cb) {
  return fetch(url,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: "POST",
      body: JSON.stringify(data)
    }
  )
  .then(response => {
    /*
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    */
    return response.json();
    })
  .then( ({ auth_token }) => {
    if ( auth_token ) {
      localStorage.setItem('accessToken', auth_token);
      return {loggedIn: true};
    }
    else {
      return {loggedIn: false};
    }
  })
  .then( cb )
  .catch( error => console.log(error) );
}

function search(query, cb) {
  return fetch(query, {accept: 'application/json', headers: {'Authorization': auth_token()} })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(cb);
}

async function searchPaginatedAsync(query, cb) {
  const fetchResult = fetch(query, {accept: 'application/json', headers: {'Authorization': auth_token() } });
  const response = await fetchResult;
  const parsedLinks = LinkHeader(response.headers.get('link'));
  const jsonData = await response.json();
  const dataWithPaginator = {
    data: jsonData,
    paginator: {
      next: (parsedLinks && parsedLinks['next']) || undefined,
      prev: (parsedLinks && parsedLinks['prev']) || undefined,
      last: (parsedLinks && parsedLinks['last']) || undefined,
      first: (parsedLinks && parsedLinks['first']) || undefined,
      total: response.headers.get('total'),
      perPage: response.headers.get('per_page'),
    },
  }
  return await cb(dataWithPaginator);
}

function searchPaginated(query, cb) {
  const testPaginator = {
    // first: 'first',
    //prev: 'prev',
    //next: 'next',
    //last: 'last',
    perPage: 10,
    total: 1,
  };
  return fetch(query, {accept: 'application/json', headers: {'Authorization': auth_token() } })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Paginated data fetching failed. We are facing some API-Problems ...');
        }
      })
      .then(json => {
        //var r = LinkHeaderParser.parse(response.headers.get('link'));
        var mydata = {
          data: json,
          paginator: testPaginator,
/*
          paginator: {
            first: r['first'],
            prev: r['prev'],
            next: r['next'],
            last: r['last'],
            perPage: parseInt(response.headers.get('per-page')),
            total: parseInt(response.headers.get('total')),
          }
*/
        };
        return mydata;
      })
      .then(cb)
      .catch( error => {
        throw new Error('Paginated data fetching failed. We are facing some API-Problems ...');
      });
}

function save(url,data,cb) {
  return fetch(url,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': auth_token()
      },
      method: "POST",
      body: JSON.stringify(data)
    }
  )
  //  .then( response =>  console.log(response) )
  .then(response => response.json())
  .then( cb )
  .catch( error => console.log(error) );
}

function update(url,data,cb) {
  return fetch(url,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': auth_token()
      },
      method: "PATCH",
      body: JSON.stringify(data)
    }
  )
  //  .then( response =>  console.log(response) )
  .then(response => response.json())
  .then( cb )
  .catch( error => console.log(error) );
}

const API_URL = process.env.REACT_APP_API_URL || 'https://tvbe-api.intertech.de/api/v1';

async function xfetch(method, endpoint, body) {
  try {
    const response = await fetch(`${API_URL}${endpoint}`, {
      method,
      body: body && JSON.stringify(body),
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Authorization': auth_token(),
      },
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}
class ValidationError extends Error {
    constructor(props) {
      const { status, data,message  } = props;
          super(message); // (1)
      this.status = status;
      this.data = data;
      this.nessage = message;
        }
}

async function yfetch(method, endpoint, body) {
    const response = await fetch(`${API_URL}${endpoint}`, {
      method,
      body: body && JSON.stringify(body),
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Authorization': auth_token(),
      },
    });
   if (!response.ok)
    if(response.status === 422) {
      const data = await response.json();
      throw new ValidationError({status: 422, data: data, message: response.statusText});
    } else {
      throw new Error(response.statusText);
    }
  if ( response.status === 204 ) return null;
  return await response.json();
}

async function rawFetch(method, endpoint, body) {
    const response = await fetch(`${API_URL}${endpoint}`, {
      method,
      body: body,
      headers: {
        'Authorization': auth_token(),
      },
    });
   if (!response.ok)
    if(response.status === 422) {
      const data = await response.json();
      throw new ValidationError({status: 422, data: data, message: response.statusText});
    } else {
      throw new Error(response.statusText);
    }
  if ( response.status === 204 ) return null;
  return await response.json();
}

async function paginated_fetch(method, endpoint, body) {
    const response = await fetch(`${API_URL}${endpoint}`, {
      method,
      body: body && JSON.stringify(body),
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'Authorization': auth_token(),
      },
    });
   if (!response.ok)
    if(response.status === 422) {
      const data = await response.json();
      throw new ValidationError({status: 422, data: data, message: response.statusText});
    } else {
      throw new Error(response.statusText);
    }
  if ( response.status === 204 ) return null;
  const parsedLinks = LinkHeader(response.headers.get('link'));
  const jsonData = await response.json();
  const dataWithPaginator = {
    ...jsonData,
    paginator: {
      next: (parsedLinks && parsedLinks['next']) || undefined,
      prev: (parsedLinks && parsedLinks['prev']) || undefined,
      last: (parsedLinks && parsedLinks['last']) || undefined,
      first: (parsedLinks && parsedLinks['first']) || undefined,
      total: response.headers.get('Total'),
      perPage: response.headers.get('Per-Page'),
      page: response.headers.get('Page'),
    },
  }
  return await dataWithPaginator;
}
const Api = { update, userLogin, search, searchPaginated, searchPaginatedAsync, xfetch, yfetch, rawFetch, paginated_fetch};
export default Api;
