const isEmpty = function (input) {
  if (typeof input === 'array') {
    return input.length === 0;
  }

  return !input || Object.keys(input).length === 0;
};

const humanFileSize = function (bytes, si) {
      var thresh = si ? 1000 : 1024;
      if(Math.abs(bytes) < thresh) {
                return bytes + ' B';
            }
      var units = si
          ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
          : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
      var u = -1;
      do {
                bytes /= thresh;
                ++u;
            } while(Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1)+' '+units[u];
};

const Utils = { humanFileSize, isEmpty };
export default Utils;
