import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import {
  withStyles,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";

import sidebarStyle from "../assets/jss/dashboard/sidebarStyle2.jsx";

function Sidebar(props) {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
    //return true;
  }
  const { classes, color, logo, logoText, routes } = props;
  var links = (
    <List component="nav">
      {routes.map((prop, key) => {
        if (prop.redirect) return null;
        const whiteFontClasses = cx({
          [" " + classes.whiteFont]: activeRoute(prop.path)
        });
        return (
          <NavLink
            to={prop.path}
            className={classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem 
              button
              disableRipple 
              disableTouchRipple
              //TouchRippleProps={{ classes: { root: props.classes.buttonRipple } }}
              className={classes.itemLink}
              selected={activeRoute(prop.path)}
            >
              <ListItemIcon 
                className={classes.itemText + whiteFontClasses}
                style={{minWidth: "40px"}}
              >
                <prop.icon style={{fontSize: "1.25rem"}}/>
              </ListItemIcon>
              <ListItemText
                className={classes.itemText + whiteFontClasses}
                primary={prop.sidebarName}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a href="#" className={classes.logoLink}>
        <div className={classes.logoImage}>
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden smUp implementation="js">
        <Drawer
          anchor="left"
          variant="temporary"
          open={props.open}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          <div id="footer">
            {process.env.REACT_APP_BUILD_TAG &&
            <Typography variant="subtitle1" style={{color: '#424242', paddingLeft: '20px'}}>
              Version: {process.env.REACT_APP_BUILD_TAG}
            </Typography>}
          </div>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          <div id="footer">
            {process.env.REACT_APP_BUILD_TAG &&
            <Typography variant="subtitle1" style={{color: '#424242', paddingLeft: '20px'}}>
              Version: {process.env.REACT_APP_BUILD_TAG}
            </Typography>}
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
};


Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
