import React, { useState, useEffect } from 'react';
import Send from '@material-ui/icons/Send';

//import { bodybuilder } from 'bodybuilder/src';

import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/json';
import 'brace/mode/javascript';
import 'brace/theme/github';
import 'brace/theme/monokai';

import { withSnackbar } from 'notistack';

import SubNavigation from '../SubNavigation';
import { CancleButton, SaveButton } from '../SubNavigationActionButtons';
import ProductFilterPreview from './ProductFilterPreview';
import Api from '../../Api';
import to from '../../to';

import { withStyles, Button, Grid, TextField } from '@material-ui/core';

const styles = theme => ({
  root: {
    //    display: 'flex',
    //flex: 1,
  },
  content: {
    padding: '20px 20px 0 20px',
  },
  iconButton: {
    maxWidth: '30px', 
    minWidth: '30px', 
    maxHeight: '30px', 
    minHeight: '30px', 
    padding: '2px'
  },
});

function ProductFilterEditor(props) {
  var bodybuilder = require('bodybuilder');
  const { classes, match: { params } } = props;
  const [isDirty, setDirty] = useState(false);

  const [productFilter, setValues] = useState({
    definition_js: `bodybuilder()
    .query('nested', 'path', 'labels', (q) => {
      q.query('match', 'labels.keyItem', 'Sparte')
      q.query('match', 'labels.valueItem', 'Hundehaftpflicht')
      return q
    })
    .andFilter('bool', f => {
      f.filter('match','id', '80ebbaff-41bb-4509-9460-eb4c28ec9eab')
      f.filter('match','title.keyword', 'TestProduct2 (cloned)')
      return f
    })
    .build();`,
    definition_json: '',
    name: '',
    id: 'new'
  });

  useEffect(() => {
    const fetchData = async () => {
      const [err, data] = await to(Api.yfetch('get', `/product_filters/${params.productFilterId}`));
      setValues(data.product_filter);
    };
    if (params.productFilterId === 'new') {
    } else {
      fetchData();
    }
  }, []);

  const [products, setProducts] = useState(null);
  const onInputChange = (newInput) => {
    try {
      var newOutput = eval(newInput);
      setValues({
        ...productFilter,
        definition_json: JSON.stringify(newOutput, null, 2),
        definition_js: newInput
      });
      setDirty(true);
    } catch (err) {
      setValues({
        ...productFilter,
        definition_json: err.toString(),
        definition_js: newInput,
      });
      //console.error('invalid input', err);
    }
  };

  const fetchDataWithFilter = async () => {
    const [err, data] = await to(Api.yfetch('get', `/products?filter_id=${productFilter.id}`));
    setProducts(data.products);
  };

  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({
      ...productFilter,
      [name]: value
    });
  };

  const save = async () => {
    if (productFilter.id === 'new') { 
      const [err, result] = await to(
        Api.yfetch(
          'post', 
          '/product_filters', 
          productFilter
        )
      );
      if (err) {
        props.enqueueSnackbar('Ein Fehler ist aufgetreten', {variant: "error" });
      } else {
        props.enqueueSnackbar('Gespeichert', {variant: "success" });
        setDirty(false);
      }
    } else {
      const [err, data] = await to(
        Api.yfetch('put', 
          `/product_filters/${productFilter.id}`,
          productFilter
        )
      );
      if(err) {
        props.enqueueSnackbar('Ein Fehler ist aufgetreten', {variant: "error" });
      } else {
        props.enqueueSnackbar('Gespeichert', {variant: "success" });
        setDirty(false);
      }
    }
  };

  return (
    <div className={classes.root}>
      <SubNavigation title="Product Filter Details" backAction={() => props.history.goBack()}>
        <CancleButton onClick={() => {props.history.push("/product_filters")}}/>
        <SaveButton onClick={ save } isDirty={isDirty}/>
      </SubNavigation>
      <Grid className={classes.content} container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            id="name"
            name="name"
            label="Filtername"
            value={productFilter.name || ''}
            onChange={handleInputChange}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <AceEditor
            mode="javascript"
            theme="github"
            value={productFilter.definition_js || ''}
            onChange={ onInputChange }
            editorProps={{$blockScrolling: true}}
            setOptions={{
              showLineNumbers: true,
              tabSize: 2,
            }}
            width="100%"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <AceEditor
            mode="json"
            theme="github"
            value={productFilter.definition_json || ''}
            editorProps={{$blockScrolling: true}}
            setOptions={{
              showLineNumbers: true,
              tabSize: 2,
            }}
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button 
            color="primary"
            disabled={(isDirty || productFilter.id === 'new')} 
            onClick={ fetchDataWithFilter } 
            variant="contained" 
            size="small" >
            <Send className={""} />
            Test Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ProductFilterPreview products={products} />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(withSnackbar(ProductFilterEditor));
