import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";
import LockIcon from '@material-ui/icons/Lock';
import Api from '../Api.js';
import to from '../to';
import { Link } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';

import {
  Card,
  Card as CardActions,
  Avatar,
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: grey[800],
    //background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  cardActions: {
    margin: '1em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary[500],
    width: '50px',
    height: '50px',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    width: '100%',
  },
});

class RecoverPassword extends React.Component {
  state = {
    email: '',
    display: "recover",
    message: null,
  }

  handleRecoverPassword = async () => {
    const reqData = { email: this.state.email };
    const [err, data] = await to(Api.yfetch('post', '/password/recover', reqData));
    if (!err) {
      this.setState(prevState => ({
        message: "Anfrage gesendet, bitte prüfen Sie ihren Posteingang"
      }));
    } else {
      this.setState(prevState => ({
        message: "Fehlgeschlagen - wenden Sie sich an ihren Administrator"
      }));
    }
  };

  handleChange = name => event => {
    this.setState({
        [name]: event.target.value
    });
  };

  catchReturn = (event) =>  {
    if (event.key === 'Enter') {
      this.handleRecoverPassword();
      event.preventDefault();
    }
  };

  render() {
    const { classes } = this.props;
    const { redirectToReferrer } = this.state;
    const { from } = this.props.location.state || { from: { pathname: "/" } };

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon color='secondary' style={{ fontSize: 36 }} />
            </Avatar>
          </div>
         <div className={classes.form}>
           { this.state.message && <Typography variant="body2" gutterBottom>
             {this.state.message}
           </Typography> }
           <div className={classes.input}>
             <TextField data-testid="email" name="email" label="E-Mail" fullWidth onChange={this.handleChange('email')}/>
           </div>
         </div>
         {this.state.message && <div className={classes.form}>
           <Link to={'/login'}>
             <Typography variant="body2" color="textSecondary">
               Zum Login
             </Typography>
           </Link>
         </div>}
         <CardActions className={classes.cardActions}>
           <Button
             disabled={this.state.message}
             variant="contained"
             type="submit"
             color="primary"
             className={classes.button}
             onClick={this.handleRecoverPassword}
           >
             Absenden
           </Button>
         </CardActions>
        </Card>
      </div>
    );
  }
}

RecoverPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecoverPassword);
