import React from "react";

import {PRODUCT_URL} from '../../urls';
import Api from '../../Api';
import to from '../../to';
import ProductDetails from './ProductDetails';
import ProductUtils from './utils';
import { ProductProvider } from '../../providers/ProductProvider';
import Utils from '../../Utils';
import { extractAttributesAsCsvHeader } from '../../utils/schemaUtils.js';

import ProductAPI from '../../api/ProductAPI';
import { withSnackbar } from 'notistack';


/*
const plan_details_schema = [
                  {attr: "position", key: "position", label: "Position", type: "number", props: {min: 0, max: 50}, size: 1, value: '' },
                  {attr: "title", key: "keyItem", label: "Schlüssel, z.B. Deckungssumme", props: {required: true}, size: 3, value: '' },
                  {attr: "content", key: "valueItem", label: "Wert, z.B. 10000000", props: {required: true}, size: 6, value: '' },
               ];
*/

class ProductContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleAttributeChange = this.handleAttributeChange.bind(this);
    this.handleBreedListChange = this.handleBreedListChange.bind(this);
    this.handleLabelsChange = this.handleLabelsChange.bind(this);
    this.handleLabelsRowRemove = this.handleLabelsRowRemove.bind(this);
    this.handlePlanAddEmtpyRow = this.handlePlanAddEmtpyRow.bind(this);
    this.handleDynamicFormAddEmtpyRow = this.handleDynamicFormAddEmtpyRow.bind(this);
    this.handlePlanChange = this.handlePlanChange.bind(this);
    this.handleDynamicFormAttrChange = this.handleDynamicFormAttrChange.bind(this);
    this.handlePlanRowRemove = this.handlePlanRowRemove.bind(this);
    this.handleDynamicFormRowRemove = this.handleDynamicFormRowRemove.bind(this);
    this.handlePlanRowReorder = this.handlePlanRowReorder.bind(this);
    this.handleDynamicFormRowReorder = this.handleDynamicFormRowReorder.bind(this);
    this.handleRiskBearerChange = this.handleRiskBearerChange.bind(this);
    this.handleAssetSelected = this.handleAssetSelected.bind(this);
    this.handleVariantsSave = this.handleVariantsSave.bind(this);
    this.handleVariantsSetDelete = this.handleVariantsSetDelete.bind(this);
    this.handleSetVarianSetActive = this.handleSetVarianSetActive.bind(this);
  }

  state = {
    breedLists: [],
    isLoading: false,
    isSaving: false,
    page: 0,
    tabValue: 0,
    saved: false,
    product: {},
    productDefinitionString: "",
    notification: {
      handleClose: () => { 
        this.handleNotificationClose(); 
      },
      isError: false,
      message: "Gespeichert",
      open: false,
    },
  };

  handleNotificationClose()  {
    this.setState({
      notification: {
        ...this.state.notification,
        open: false
      }
    });
  }

  handleAssetSelected(asset, assetVariant) {
    this.setState({
      product: {
        ...this.state.product,
        [assetVariant]: asset,
        [`${assetVariant}_id`]: asset.id
      }
    }); 
  }

  handleLabelsRowRemove(idx) {
    let newRows = this.state.product.labels.filter((row, i) => i !== idx);

    if ( newRows.filter(row => (row.keyItem === "")).length < 1 ) {
      newRows.push({keyItem: '',valueItem: ''});
    }

    this.setState({
      product: {
        ...this.state.product,
        labels: newRows
      }
    }); 
  }

  handleLabelsChange(attr, value, idx) {
    let newRows = this.state.product.labels.map((row, i) => {
      if (idx !== i) {
        return row;
      }
      return {
        ...row,
        [attr]: value
      };
    });
    if ( newRows.filter(row => (row.keyItem === "")).length < 1 ) {
      newRows.push({keyItem: '', valueItem: ''});
    }
    this.setState({
      product: {
        ...this.state.product,
        labels: newRows
      }
    });
  }

  handleAttributeChange(attr, value){
    this.setState({
      product: {
        ...this.state.product,
        [attr]: value
      }
    });
  };

  handleRiskBearerChange(riskBearer) {
    console.debug("Received riskBearer: ", riskBearer);
    this.setState({
      breedLists: riskBearer.breed_lists || [],
      product: {
        ...this.state.product,
        risk_bearer_id: riskBearer.id,
        risk_bearer: riskBearer
      }
    });
  };

  handleBreedListChange(breedListId) {
    console.debug("Breedlist changed to: ", breedListId);
    this.setState({
      product: {
        ...this.state.product,
        breed_list_id: breedListId,
        breed_list: {id: breedListId }
      }
    });
  };

  handleSetVarianSetActive = async(variantSet) => {
    variantSet.activated_at =  new Date().toUTCString(); //ruby DateTime conversion seconds since the epoch
    const [err, data] = await to(ProductAPI.updateProductVariantSet(variantSet));
    if (err) {
      this.props.enqueueSnackbar('Ein Fehler ist aufgetreten', {variant: "error" });
    } else {
      this.setState(prevState => ({
        product: {
          ...this.state.product,
          active_variant_set: variantSet
        }
      }));
      this.props.enqueueSnackbar('Aktiviert', {variant: "success", autoHideDuration: 1300, });
      const [err, data] = await to(Api.yfetch('get', `/products/${this.state.product.id}`));
      this.setState(prevState => ({
        product: data.product,
      }));
    }
  };

  handleVariantsSetDelete = async(variantSetId) => {
    const [err, data] = await to(Api.yfetch('delete', `/product_variant_sets/${variantSetId}`));
    if (err) {
      this.props.enqueueSnackbar('Ein Fehler ist aufgetreten', {variant: "error" });
    } else {
      this.props.enqueueSnackbar('Gelöscht', {variant: "success" });
    }
      const [err2, data2] = await to(Api.yfetch('get', `/products/${this.state.product.id}`));
      this.setState(prevState => ({
        product: data2.product,
      }));
  };

  handleVariantsSave = async (csvData, schema) => {
    const PapaParse = require('papaparse/papaparse.min.js');
    /*
     * 1. extract Header as array from schema
     * 2. replace csvData[0] with new header
     * 3. make PapaParse convert csv to json results = Papa.parse(csv, { header: true });
     */
    const csvHeader = extractAttributesAsCsvHeader(schema);
    let _csvData = csvData.slice(1);
     _csvData.unshift(csvHeader);
    const results = PapaParse.parse(PapaParse.unparse(_csvData), { header: true });
    const variants = results.data;

    const variantSet = {
      product_id: this.state.product.id,
      variant_schema: schema,
      variants: variants,
      active: false,
    };
    //console.log("VariantSet:", JSON.stringify(variantSet, null, 2));
    const [err, data] = await to(ProductAPI.saveProductVariantSet(variantSet));
    if (err) {
      this.props.enqueueSnackbar('Ein Fehler ist aufgetreten', {variant: "error" });
    } else {
      this.props.enqueueSnackbar('Gespeichert', {variant: "success" });
      const [err, data] = await to(Api.yfetch('get', `/products/${this.state.product.id}`));
      this.setState(prevState => ({
        product: data.product,
      }));
    }
  }

  handlePlanAddEmtpyRow = (emptyPlanDetail) => (event) => {
    let currentRows = [];
    if ( !Utils.isEmpty(this.state.product.plan_details) ) {
      currentRows = this.state.product.plan_details;
    }

    this.setState({
      product: {
        ...this.state.product,
        plan_details: [ ...currentRows, ...[emptyPlanDetail]]
      }
    });
  }

  handleDynamicFormAddEmtpyRow = (emptyRow, attr) => (event) => {
    let currentRows = [];
    if ( !Utils.isEmpty(this.state.product[attr]) ) {
      currentRows = this.state.product[attr];
    }

    this.setState({
      product: {
        ...this.state.product,
        [attr]: [ ...currentRows, ...[emptyRow]]
      }
    });
  }

  handlePlanChange(attr, value, idx) {
    const currentRows = this.state.product.plan_details;

    let newRows = currentRows.map((row, i) => {
      if (idx !== i) {
        return row;
      }
      return {
        ...row,
        [attr]: value
      };
    });

    this.setState({
      product: {
        ...this.state.product,
        plan_details: newRows
      }
    });
  }

  handleDynamicFormAttrChange(parentAttr, attr, value, idx) {
    //console.debug(`handleDynamicFormAttrChange called: parentAttr=${parentAttr}, attr=${attr}, value=${value}, idx=${idx}`);
    const currentRows = this.state.product[parentAttr];

    let newRows = currentRows.map((row, i) => {
      if (idx !== i) {
        return row;
      }
      return {
        ...row,
        [attr]: value
      };
    });

    this.setState({
      product: {
        ...this.state.product,
        [parentAttr]: newRows
      }
    });
  }

  handlePlanRowRemove(idx) {
    let newRows = this.state.product.plan_details.filter((row, i) => i !== idx);

    this.setState({
      product: {
        ...this.state.product,
        plan_details: newRows
      }
    }); 
  }

  handleDynamicFormRowRemove(parentAttr, idx) {
    let newRows = this.state.product[parentAttr].filter((row, i) => i !== idx);

    this.setState({
      product: {
        ...this.state.product,
        [parentAttr]: newRows
      }
    }); 
  }

  handlePlanRowReorder(src,dst) {
    const reorderedList = ProductUtils.reorder(this.state.product.plan_details, src, dst)
    this.setState({
      product: {
        ...this.state.product,
        plan_details: reorderedList
      }
    });
  };

  handleDynamicFormRowReorder(parentAttr, src,dst) {
    const reorderedList = ProductUtils.reorder(this.state.product[parentAttr], src, dst)
    this.setState({
      product: {
        ...this.state.product,
        [parentAttr]: reorderedList
      }
    });
  };

  saveProduct = async () => {
    this.setState(prevState => ({ isSaving: true }));
    if (this.state.product.id) { 
      const [err, data] = await to(Api.yfetch('put', `/products/${this.state.product.id}`, this.state.product));
      if(err) {
        this.setState(prevState => ({
          tabValue: 0,
          isLoading: false,
          isSaving: false,
          notification: {...this.state.notification, isError: true, message: "Ein Fehler ist aufgetreten", open: true},
        }));
      } else {
        this.setState(prevState => ({
          tabValue: 0,
          product: data.product,
          isLoading: false,
          isSaving: false,
          saved: true,
          notification: {...this.state.notification, isError: false, open: true},
        }));
      }
    } else {
      const [err, data] = await to(Api.yfetch('post', '/products', this.state.product));
      if(err) {
        console.log("Error data:", err.data);
        this.setState(prevState => ({
          tabValue: 0,
          isLoading: false,
          isSaving: false,
          notification: {...this.state.notification, isError: true, message: "Ein Fehler ist aufgetreten", open: true},
        }));
      } else {
        this.setState(prevState => ({
          tabValue: 0,
          product: data.product,
          isLoading: false,
          isSaving: false,
          saved: true,
          notification: {...this.state.notification, open: true},
        }));
      }
    }
  }; 

  fetchBreedLists = async() => {
    //console.debug("Fetching breed lists of product");
    if (this.state.product && this.state.product.risk_bearer ) {
      const [err, data] = await to(Api.yfetch('get', 
        `/risk_bearers/${this.state.product.risk_bearer.id}`));
      if (err) {
        console.error("Failed to fetch breed list of product", this.state.product);
        this.setState({
          breedLists: []
        });
      } else {
        this.setState({
          breedLists: data.risk_bearer.breed_lists || []
        });
      }
    } else {
        this.setState({
          breedLists: []
        });
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const { match: { params } } = this.props;
    const qparams = new URLSearchParams(this.props.location.search);

    if (params.productId === 'new') {
      this.setState({
        isLoading: false,
        tabValue: 0,
        product: {
          checkout_form: {},
          comparatives: [],
          description: '', //RTE
          description_insurer_notification: '', //RTE
          highlights: [],
          labels: [{keyItem: '', valueItem:''}],
          plan_details: [],
          recommendation_criteria: '', //RTE
        },
      });
    } else {
      Api.search(`${PRODUCT_URL}/${params.productId}`, (data) => {
        this.setState({
          tabValue: 0,
          product: data.product,
          isLoading: false,
        });
        this.fetchBreedLists();
      });
    }
  }

  render() {
    return (
      <ProductProvider value={{
        isLoading: this.state.isLoading,
        isSaving: this.state.isSaving,
        notification: this.state.notification,
        ctxProduct: this.state.product,
        actions: {
          onAttributeChange: this.handleAttributeChange, 
          onBreedListChange: this.handleBreedListChange,
          onImageSelect: this.handleAssetSelected,
          onLabelsChange: this.handleLabelsChange, 
          onLabelsRowRemove: this.handleLabelsRowRemove,
          onPlanAddEmptyRow: this.handlePlanAddEmtpyRow, 
          onDynamicFormAddEmptyRow: this.handleDynamicFormAddEmtpyRow, 
          onPlanChange: this.handlePlanChange, 
          onDynamicFormAttrChange: this.handleDynamicFormAttrChange, 
          onPlanRowRemove: this.handlePlanRowRemove,
          onDynamicFormRowRemove: this.handleDynamicFormRowRemove,
          onPlanRowReorder: this.handlePlanRowReorder,
          onDynamicFormRowReorder: this.handleDynamicFormRowReorder,
          onProductSave: this.saveProduct,
          onRiskBearerChange: this.handleRiskBearerChange,
          onVariantsSave: this.handleVariantsSave,
          onVariantsSetDelete: this.handleVariantsSetDelete,
          onVarianSetActivate: this.handleSetVarianSetActive,
        },
      }}>
        <ProductDetails 
          breedLists={this.state.breedLists}
          isLoading={this.state.isLoading}
          isSaving={this.state.isSaving}
          onAttributeChange={this.handleAttributeChange} 
          onProductSave={this.saveProduct}
          product={this.state.product}
          notification={this.state.notification}
          {...this.props}
        />
      </ProductProvider>
    );
  }
}

ProductContainer.propTypes = {
};
export default withSnackbar(ProductContainer);
