import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { red } from '@material-ui/core/colors';

import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Avatar,
  IconButton,
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    actions: {
      display: 'flex',
    },
    avatar: {
      backgroundColor: red[500],
    },
    card: {
      maxWidth: 400,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    heading: {
      fontSize: '14px',
      fontWeight: '600',
    },
    info: {
      display: 'inline-block',
      width: '70%',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    metadata: {
      margin: 0,
      listStyle: 'none',
      borderTop: '1px solid rgba(215,225,237,.6)',
      padding: '15px 0 15px 0',
      '& li': {
        fontSize: '.8125em',
        padding: '3px 0 4px',
        minHeight: '25px',
      }
    },
    plan_detail: {
      '& ul': {
        paddingInlineStart: '20px',
        marginBlockStart: '10px',
      },
    },
    title: {
     width: '50%',
     display: 'inline-block',
     fontWeight: '600',
    }
  }),
  { name: 'HookGlobalStyles', index: 2 }
);

const  ProductCard = (props) => {
  const { product, } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="Product" className={classes.avatar}>
            P
          </Avatar>
        }
        action={
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        }
        title={product.title || ''}
        subheader={product.updated_at || ''}
      />
      {product.image && <CardMedia
        className={classes.media}
        image={product.image.file.preview_url || ''}
        title="Paella dish"
      />}
      <CardContent>
        <Typography 
          component="p" 
          dangerouslySetInnerHTML={{__html: product.description}} />
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        <IconButton aria-label="Add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="Share">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={classnames(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Labels:</Typography>
          <div>
            <ul className={classes.metadata}>
              {product.labels.map((label,idx) => (
                <li key={idx}><span className={classes.title}>{label.keyItem}</span><span className={classes.info}>{label.valueItem}</span></li>
              ))}
            </ul>
          </div>
          <Typography paragraph>Plan Details</Typography>
          <div>
              {Array.isArray(product.plan_details) && product.plan_details.map((plan_detail,idx) => (
                <ExpansionPanel key={idx}>
                  <ExpansionPanelSummary expandIcon={!plan_detail.isSectionHeader && <ExpandMoreIcon />}>
                    <Typography className={classes.heading}>{plan_detail.title}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography 
                      className={classes.plan_detail}
                      dangerouslySetInnerHTML={{__html: plan_detail.content}} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
}


function ProductFilterPreview(props) {
  const { products } = props;

  return (
    <Grid container spacing={3}>
      { (products && products.length > 0) && products.map((product, idx) => (
        <Grid item  key={idx} md={3}>
          <ProductCard product={product} />
        </Grid>
        ))
      }
      { (products && products.length < 1) &&  <Grid item md={3}>
          <Typography paragraph>
            Der angewendete Filter hat keine Treffer!
          </Typography>
        </Grid>}
    </Grid>
  )
}

export default ProductFilterPreview;
