import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import Api from '../../Api';
import to from '../../to';
import { ProductConsumer } from '../../providers/ProductProvider';

import { withStyles, TextField, Paper, MenuItem } from '@material-ui/core';

const suggestions = [
  { label: 'Barmenia Allgemeine Versicherung AG' },
  { label: 'Gothaer Allgemeine Versicherung AG' },
  { label: 'Ideal Versicherung AG' },
];

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = ((selectedItem && selectedItem.name) || '').indexOf(suggestion.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.name}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});

class ProductRiskBearerSuggestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      suggestions: []
    }
    this.fetchRiskBearers = this.fetchRiskBearers.bind(this)
    //this.inputOnChange = this.inputOnChange.bind(this)
  }
  async fetchRiskBearers(value) {
    const [err, data] = await to(Api.yfetch('get', '/risk_bearers'));
    if (err) console.log("scope=RiskBearer action=fetching result=", err);
    if (!err) {
      this.setState({
        suggestions: data.risk_bearers,
      });
    }
  }

  inputOnChange = (value) => {
    if (!value) {
      return
    } 
    this.fetchRiskBearers(value)
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <ProductConsumer>
        {({ctxProduct, actions}) => {
          return (
        <Downshift 
          id="downshift-riskBearer"
          initialSelectedItem={ ctxProduct.risk_bearer }
          itemToString={item => (item ? item.name : '')}
          onChange={ actions.onRiskBearerChange } 
          onInputValueChange={ this.inputOnChange}>
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
          }) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  placeholder: 'Search a riskBearer (start with b)',
                }),
                label: 'Risikoträger',
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {this.state.suggestions.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ key: suggestion.id, index, item: suggestion }),
                        highlightedIndex,
                        selectedItem,
                      }),
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
          )
        }}
      </ProductConsumer>
      </div>
    );
  }
}

ProductRiskBearerSuggestion.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductRiskBearerSuggestion);

