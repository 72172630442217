import React from "react";
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';

import { ProductConsumer } from '../../providers/ProductProvider';
import { ProductContext } from '../../providers/ProductProvider';
import ProductRiskBearerSuggestion  from './ProductRiskBearerSuggestion';
import AssetSelector  from '../MediaManager/AssetSelector';
import { AppSettingsConsumer } from '../../providers/AppSettingsProvider'

import 'react-quill/dist/quill.snow.css';
import '../../quill-tv.css';
import ReactQuill from 'react-quill';

import {
  Button,
  Box,
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

const editorModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false]}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
      {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formLabel: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: "400",
  },
  formRow: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  image: {
    height: '200px',
  },
  imageDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    minHeight: '300px',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey['300'],
  },
  imageWidth: {
    width: '100%',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

function ImageBox(props) {
  const { classes, asset } = props;
  return (
    <div className={classes.imageDiv}>
      <Grid 
        container 
        direction="column"
        justify="flex-end"
        alignItems="center"
        spacing={0}>
        <Grid>
        {asset &&  
          <img src={asset.file.preview_url} 
            className={classes.imageWidth}
            alt="Produktbild" 
            onClick={props.openDialog} />
        }
        </Grid>
        <Grid>
          {!asset && <div style={{padding: '10px'}}>
            <Button variant="outlined" onClick={props.openDialog} >
              Datei wählen
            </Button>
          </div>}
        </Grid>
      </Grid>
    </div>
  );
}

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      assetSelectorOpen: false, 
      assetVariant: '',
    };
  }

  handleChange = name => event => {
    this.props.onAttributeChange(name,event.target.value);
  };

  handleBreedListChange = event => {
    console.debug("handleBreedListChange: ", event.target);
    this.context.actions.onBreedListChange(event.target.value);
  };

  handleDateChange = (name) => value => {
    if (value) {
      this.props.onAttributeChange(name,value.toISOString());
    } else {
      this.props.onAttributeChange(name,value);
    }
  };

  handleRtfChange = attr => value => {
    //console.log("Value: ", value);
    //console.log("AttributeKey: ", attr);
    /*
     * Possible mitigation for https://projects.tierversicherung.biz/projects/tierversicherung-biz/work_packages/236
     * Source: https://github.com/quilljs/quill/issues/163#issuecomment-427551866
     * Root-cause: https://github.com/quilljs/quill/issues/1235#issuecomment-273044116
     */

    /*
     * const valueContent = value.trim();
     * const re = /^<p>(<br>|<br\/>|<br\s\/>|\s+|)<\/p>$/gm;
     * if (re.test(valueContent) ) {
     *   value = "";
     * }
     */
    this.props.onAttributeChange(attr,value);
  };

  handleAssetSelect = callback => value => {
    //this.setState({assetSelectorOpen: false});
   callback(value, this.state.assetVariant);
   this.setState({assetSelectorOpen: false, assetVariant: ''});
   console.log("Asset selected: ", value); 
  }

  handleOpenAssetSelector = (assetVariant) => {
   this.setState({assetSelectorOpen: true, assetVariant: assetVariant});
  }

  handleCloseAssetSelector = () => {
   this.setState({assetSelectorOpen: false, assetVariant: ''});
  }

  render() {
    console.debug("Received props: ", this.props);
    const { classes, product, breedLists } = this.props;
    return (
      <ProductConsumer>
        { ({ctxProduct, actions}) => (
          <Grid container spacing={3}>
            <Grid item xs={10} sm={10} md={10} lg={10}>

              <TextField
                value={product.internal_name || ''}
                fullWidth
                id="product['internal_name']"
                label="Name"
                margin="normal"
                onChange={this.handleChange("internal_name")}
              />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <AppSettingsConsumer> 
                { ({appSettings, appActions}) => (

                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="insurance_sector">Sparte</InputLabel>
                    <Select
                      displayEmpty
                      value={(product.insurance_sector) || ''}
                      onChange={this.handleChange("insurance_sector")}
                      inputProps={{
                        id: 'insurance_sector',
                      }}
                    >
                      { appSettings.data && appSettings.data.insurance_sector.map((sector, idx) => (
                        <MenuItem key={idx} value={sector}>{sector}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </AppSettingsConsumer> 
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>

                 <div className={classes.formRow}>
                   <Grid container item xs={12} spacing={1}>
                     <Grid item xs={6}>
                       <ProductRiskBearerSuggestion />
                     </Grid>
                     <Grid item xs={6}>
                       <FormControl fullWidth={true}>
                       <InputLabel htmlFor="agent-no">Vermittlernummer</InputLabel>
                       <Select
                         displayEmpty
                         inputProps={{
                           id: 'agent-no',
                         }}
                         value={(product.agent_no) || ''}
                         onChange={this.handleChange("agent_no")}
                       >
                         { product.risk_bearer && product.risk_bearer.agent_info.map((agent, idx) => (
                             <MenuItem key={idx} value={agent.agent_no}>{agent.agent_no} {agent.email}</MenuItem>
                             )
                           )
                         }
                       </Select>
                       </FormControl>
                     </Grid>
                   </Grid>
                 </div>

                 <div className={classes.formRow}>
                   <Grid container item xs={12} spacing={1}>
                     <Grid item xs={12}>
                       <FormControl fullWidth={true}>
                       <InputLabel htmlFor="agent-no">Rasseliste</InputLabel>
                       <Select
                         inputProps={{
                           id: 'breed_list',
                         }}
                         value={product.breed_list && product.breed_list.id || ''}
                         onChange={this.handleBreedListChange}
                       >
                           <MenuItem key={0} value=""><em>Keine</em></MenuItem>
                           { product.risk_bearer && breedLists.map((breedList, idx) => (
                             <MenuItem key={idx+1} value={breedList.id}>{breedList.name}</MenuItem>
                             )
                           )
                         }
                       </Select>
                       </FormControl>
                     </Grid>
                   </Grid>
                 </div>

                 <div className={classes.formRow}>
                   <Grid container item xs={12} spacing={1}>
                     <Grid item xs={6}>
                        <TextField
                          value={product.ams_linecode || ''}
                          fullWidth
                          id="product['ams_linecode']"
                          label="AMS Spartenkürzel"
                          margin="normal"
                          onChange={this.handleChange("ams_linecode")}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <TextField
                          value={product.ams_productcode || ''}
                          fullWidth
                          id="product['ams_productcode']"
                          label="AMS Produktbezeichnung"
                          margin="normal"
                          onChange={this.handleChange("ams_productcode")}
                        />
                     </Grid>
                   </Grid>
                 </div>

                 <div className={classes.formRow}>
                 <Grid container item xs={12} spacing={1}>
                   <Grid item xs={6}>
                     <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Gültig ab</Typography>
                     <FormControl fullWidth={true}>
                     <DatePicker
                       autoOk
                       clearable
                       disableToolbar
                       format="dd/MM/yyyy"
                       variant="dialog"
                       name="product['valid_from']"
                       value={product.valid_from && new Date(product.valid_from) || null}
                       onChange={this.handleDateChange("valid_from")}
                     />
                     </FormControl>
                   </Grid>
                   <Grid item xs={6}>
                     <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Gültig bis</Typography>
                     <FormControl fullWidth={true}>
                     <DatePicker
                       autoOk
                       clearable
                       disableToolbar
                       format="dd/MM/yyyy"
                       variant="dialog"
                       name="product['valid_to']"
                       value={ product.valid_to && new Date(product.valid_to) || null}
                       onChange={this.handleDateChange("valid_to")}
                     />
                     </FormControl>
                   </Grid>
                 </Grid>
                 </div>

                 <div className={classes.formRow}>
                   <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Tarifbeschreibung für Risikoträger</Typography>
                   <ReactQuill 
                     id="description_insurer_notification" 
                     value={product.description_insurer_notification}
                     onChange={this.handleRtfChange("description_insurer_notification")}
                     modules={editorModules}
                   />
                 </div>

                 <div className={classes.formRow}>
                   <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Tarifbegründung</Typography>
                   <ReactQuill 
                     id="recommendation_criteria" 
                     value={product.recommendation_criteria}
                     onChange={this.handleRtfChange("recommendation_criteria")}
                     modules={editorModules}
                   />
                 </div>

                 <div className={classes.formRow}>
                   <TextField
                     value={product.title || ''}
                     fullWidth
                     id="product['title']"
                     label="Verkaufsbezeichnung"
                     margin="normal"
                     onChange={this.handleChange("title")}
                   />
                 </div>

                 <div className={classes.formRow}>
                   <TextField
                     value={product.position || '' }
                     fullWidth
                     id="product['position']"
                     label="Position (relativ)"
                     margin="normal"
                     type="number"
                     onChange={this.handleChange("position")}
                   />
                 </div>

                 <div className={classes.formRow}>
                   <Grid container item xs={12} spacing={1}>
                     <Grid item xs={6}>
                       <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Bild</Typography>
                       <ImageBox classes={classes} asset={product.image} openDialog={() => { this.handleOpenAssetSelector('image')}} />
                       {product.image && product.image.title && <Typography component="div">
                         <Box fontFamily="Monospace" fontSize="h6.fontSize" m={1}>
                           {product.image.title}
                         </Box>
                       </Typography>}
                     </Grid>
                   </Grid>
                 </div>

                 <div className={classes.formRow}>
                   <Grid container item xs={12} spacing={1}>
                     <Grid item xs={6}>
                       <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Logo</Typography>
                       <ImageBox classes={classes} asset={product.logo} openDialog={() => { this.handleOpenAssetSelector('logo')}} />
                       {product.logo && product.logo.title && <Typography component="div">
                         <Box fontFamily="Monospace" fontSize="h6.fontSize" m={1}>
                           {product.logo.title}
                         </Box>
                       </Typography>}
                     </Grid>
                   </Grid>
                 </div>

                 <div className={classes.formRow}>
                   <Grid container item xs={12} spacing={1}>
                     <Grid item xs={6}>
                       <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Antrags-PDF</Typography>
                       <ImageBox classes={classes} asset={product.application_pdf} openDialog={() => { this.handleOpenAssetSelector('application_pdf')}} />
                       {product.application_pdf && product.application_pdf.title && <Typography component="div">
                         <Box fontFamily="Monospace" fontSize="h6.fontSize" m={1}>
                           {product.application_pdf.title}
                         </Box>
                       </Typography>}
                     </Grid>
                   </Grid>
                 </div>

                 <div className={classes.formRow}>
                   <Grid container item xs={12} spacing={1}>
                     <Grid item xs={6}>
                       <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Bedingungen(PDF)</Typography>
                       <ImageBox classes={classes} asset={product.terms_pdf} openDialog={() => { this.handleOpenAssetSelector('terms_pdf')} } />
                       {product.terms_pdf && product.terms_pdf.title && <Typography component="div">
                         <Box fontFamily="Monospace" fontSize="h6.fontSize" m={1}>
                           {product.terms_pdf.title}
                         </Box>
                       </Typography>}
                     </Grid>
                   </Grid>
                 </div>

                 <div className={classes.formRow}>
                   <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Description</Typography>
                   <ReactQuill 
                     id="description" 
                     value={product.description}
                     onChange={this.handleRtfChange("description")}
                     modules={editorModules}
                   />
                 </div>

                 <div className={classes.formRow}>
                   <AssetSelector 
                     open={this.state.assetSelectorOpen}
                     onClose={this.handleCloseAssetSelector} 
                     onSelectAsset={this.handleAssetSelect(actions.onImageSelect)}
                   />
                 </div>

            </Grid>
          </Grid>
        )}
      </ProductConsumer>
    );
  }
}

ProductDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

ProductDetail.contextType = ProductContext;

export default withStyles(styles)(ProductDetail);
