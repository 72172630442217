import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AssetSearch from './AssetSearch';
import _AssetGridList from './_AssetGridList';
import Api from '../../Api';
import to from '../../to';

import {
  withStyles,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

function AssetSelector(props) {
  const selectAsset = asset => { console.log("Asset selected: ", asset); }
  const handleClose = () => { console.log("Dialog closed"); }
  const { classes,onSelectAsset, ...other } = props;
  const [isLoading, setIsLoading] = useState(false);

  const initialSate = {
    assets: [],
    lastPage: false,
    pageNo: 1,
    paginator: {},
    searchTerm: "*",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setAssets':
        return {
          ...state,
          assets: [...state.assets, ...action.payload.digital_assets],
          paginator: action.payload.paginator,
          lastPage: (action.payload.paginator && ((action.payload.paginator.page * action.payload.paginator.perPage) >= action.payload.paginator.total)) ? true : false,
        };
      case 'clearState':
        return {
          ...state,
          ...initialSate
        };
      case 'setSearchTerm':
        return {
          ...state,
          searchTerm: action.payload,
          pageNo: 1,
          assets: []
        };
      case 'setNextPage':
        return {
          ...state,
          pageNo: state.pageNo + 1
        };
      default:
        throw new Error(`AssetSelectorReducer: Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = React.useReducer(reducer, initialSate);

  React.useEffect(() => {
    console.debug("AssetSelector: Props in useEffect: ", props);
    console.debug("AssetSelector: Current state in useEffect: ", state);
    if (props.open) 
      fetchAssets();
  },
  [state.pageNo,state.searchTerm, props.open]);


  const handleSetSearchTerm = (value) => {
    console.debug("AssetSelector: set searchTerm: ", value);
    dispatch({type: 'setSearchTerm', payload: value});
  };

  const handleFetchNextPage = () => {
    dispatch({type: 'setNextPage'});
  }

  const fetchAssets = async() => {
    const params = {
      page: state.pageNo,
      per_page: 12,
      q: state.searchTerm,
    };
    console.debug("AssetSelector: executed fetchAssets with params ", params);

    const queryParamsString = Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key]))
      .join('&');

    setIsLoading(true);
    const [err, data] = await to(Api.paginated_fetch('get', `/digital_asset_searches/?${queryParamsString}`));
    if(err) {
      console.warn("Error retriving assets with query:", params.q)
      setIsLoading(false);
    } else {
      dispatch({type: 'setAssets', payload: data});
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    console.debug("AssetSelector: Closing the dialog and clearing state");
    dispatch({type: 'clearState'});
    props.onClose();
  };

  return (
    <Dialog 
      onClose={handleCloseDialog} 
      onExited={handleCloseDialog} 
      maxWidth='lg' 
      fullWidth={true} 
      {...other}>
      <DialogTitle 
        id="simple-dialog-title" 
        disableTypography={true}
      >
        <Grid container 
          className={classes.demo} 
          direction="row" 
          justify="center" 
          spacing={0}
        >
          <Grid item xs>
            <Typography variant="h6">Asset auswählen</Typography>
          </Grid>
          <Grid item >
            {isLoading &&<CircularProgress size={18} style={{float: "righgt"}}/>}
          </Grid>
        </Grid>
      </DialogTitle>
      <div>
        <AssetSearch onSearch={handleSetSearchTerm} />
        <_AssetGridList
          onSelectAsset={onSelectAsset} 
          handleFetchNextPage={handleFetchNextPage}
          lastPage={state.lastPage}
          assets={state.assets}
        />
      </div>
       <DialogActions>
         <Button onClick={handleCloseDialog} color="primary">
           Cancel
         </Button>
       </DialogActions>
    </Dialog>
  );
}

AssetSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssetSelector);
