import React from 'react';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LinkIcon from '@material-ui/icons/Link';
import Delete from '@material-ui/icons/Delete';
import Save from '@material-ui/icons/Save';

import AssetData from './AssetData';
import AssetTags from './AssetTags';
import LicenseDetail from './LicenseDetails';

import {
  withStyles,
  AppBar,
  ButtonBase,
  Button,
  IconButton,
  Grid,
  Slide,
  Tabs,
  Tab,
  Toolbar,
  Typography,
} from '@material-ui/core';

/*
const asset = {
  file_url: "s3://whatever",
  content_type: "binary/img",
  metadata: {
    link_description: "Die passende Hundehaftpflicht für ...",
    alt: "Typischer Schadensfall der Hundeha....",
  }
};
*/

const styles = theme => ({
  root: {
    backgroundColor: '#cfd8dc',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexGrow: '4',
    height: 'calc(100% - 11px)',
    padding: '8px',
  },
  button: {},
  buttonGrid: {
    borderTop: '1px solid #cdcdcd',
    padding: '10px 0 10px 0',
  },
  detailContent: {
    paddingLeft: '15px',
  },
  detailHead: {
    paddingLeft: '15px',
  },
  grow: {
    flexGrow: 1,
  },
  h3: {
    marginTop: 0,
  },
  metadata: {
    margin: 0,
    listStyle: 'none',
    borderTop: '1px solid rgba(215,225,237,.6)',
    borderBottom: '1px solid rgba(215,225,237,.6)',
    padding: '15px 0 15px 0',
    '& li': {
      fontSize: '.8125em',
      padding: '3px 0 4px',
      minHeight: '25px',
    }
  },  
  title: {
    fontWeight: '600',
    '&:after': {
      content: '":"',
    },
  },
  inputSmall: {
    fontSize: '12px',
    padding: '1px 0 1px',
  },
  dimmed: {
    color: '#607d8b',
  },
  appBar: {
    boxShadow: '0px 3px 4px -3px rgba(0,0,0,0.42)',
  },
  toolBar: {
    minHeight: '40px',
  },
  icon: {
    verticalAlign: 'bottom',
  },
  info: {
  },
  imgGrid: {
    backgroundColor: '#000',
  },
  imgPreview: {
    margin: 'auto',
    display: 'block',
  },
  imgFull: {
    maxWidth: '100%',
  },
  centered: {
    textAlign: 'center',
  },
  sectionDesktop: {
    paddingRight: '24px',
  },
  moveIn: {
    transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
    transform: 'translate(12em,0)',
  }
});

class AssetDetailExpanded extends React.Component {
  state = {
    description_long: this.props.asset.description_long || '',
    activeTab: 0,
  };

  handleDateChange = (name) => (date) => {
    //console.debug(`Called with Name ${name} and date: ${date.toISOString()}`);
    if (date) {
      this.props.onAttributeChange(name, date.toISOString());
    } else {
      this.props.onAttributeChange(name,date);
    }
  };

  handleLicenseDateChange = (name) => (date) => {
    //console.debug(`Called with Name ${name} and date: ${date.toISOString()}`);
    if (date) {
      this.props.onLicenseChange(name, date.toISOString());
    } else {
      this.props.onLicenseChange(name, date);
    }
  };

  handleDescriptionChange = (html) => {
    this.setState({description_long: html});
  };

  handleSave = () => {
    this.props.onSave(this.state.description_long)
  };

  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
  };

  render() {
    const { classes, asset, } = this.props;

    return (
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <div 
        //className={classes.moveIn} 
        style={{height: '100%'}}>

        <AppBar className={classes.appBar} position="relative" color="default">
          <Toolbar className={classes.toolBar} disableGutters={true} >
            <ButtonBase onClick={this.props.onResize}  size="small" style={{ borderRight: '0.05em solid #9e9e9e', padding: '0.5em' }} >
              <ChevronLeftIcon style={{color: '#9e9e9e', padding: '0 7px 0 7px'}}/>
            </ButtonBase>
            <Typography variant="subtitle1" style={{color: '#424242', paddingLeft: '20px'}}>
              {asset.title}
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton color="inherit" href={asset.file.url} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', padding: '2px'}}>
                <LinkIcon style={{height: '0.8em', transform: 'rotate(-45deg)'}}/>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <div className={classes.root}>
          <Grid container spacing={1} style={{backgroundColor: '#f4f4f4', height: '100%'}}>
            <Grid item xs={5} style={{padding: "0"}}>
              <div style={{backgroundColor: '#f4f4f4', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                <div>
                  <Tabs
                    value={this.state.activeTab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="Details" style={{minHeight: '30px'}}/>
                    <Tab label="Tags" style={{minHeight: '30px'}}/>
                    <Tab label="License" style={{minHeight: '30px'}}/>
                  </Tabs>
                  {this.state.activeTab === 0 && <AssetData 
                      asset={asset} 
                      classes={classes}
                      onChange={this.props.onChange}
                      onDescriptionChange={this.handleDescriptionChange}
                      localDescriptionLong={this.state.description_long}
                      onDateChange={ this.handleDateChange }
                    />}
                  
                  {this.state.activeTab === 1 && <AssetTags 
                    asset={asset} 
                    classes={classes} 
                    onTagDelete={this.props.onTagDelete}
                    onTagAdd={this.props.onTagAdd} />}

                  {this.state.activeTab === 2 && <LicenseDetail 
                    classes={classes} 
                    license={asset.license} 
                    onDateChange={this.handleLicenseDateChange}
                    onChange={this.props.onChange}/>}

                </div>

                <div>
                  <Grid
                    alignItems="center"
                    className={classes.buttonGrid}
                    container
                    spacing={0} justify="center"
                  >
                    <Grid item xs={9}>
                      <div className={classes.centered}>
                        <Button color="primary" onClick={this.handleSave}  size="small" >
                          <Save className={classes.leftIcon} />
                          Save
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className={classes.centered}>
                        <Button color="primary" onClick={this.handleSave}  size="small" >
                          <Delete className={classes.leftIcon} />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid className={classes.imgGrid} item xs={7}>
                  <img className={classes.imgFull} src={asset.file.url} alt={asset.title} />
                {/*
              <Grid container spacing={1}>
                <Grid className={classes.imgGrid} item xs={12}>
                  <img className={classes.imgFull} src={asset.file.url} alt={asset.title} />
                </Grid>
                <Grid item xs={4} style={{verticalAlign: 'middle'}}>
                  <Typography
                    align='left'
                    className={classes.info}
                    variant="subtitle2"
                    gutterBottom
                  >
                    <Photo className={classes.icon} /> {asset.file.content_type.split('/')[1].toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    align='center'
                    className={classes.dimmed}
                    variant="subtitle2"
                    gutterBottom
                  >
                    {Utils.humanFileSize(asset.file.byte_size, false)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    align='right'
                    className={classes.dimmed}
                    variant="body2"
                    gutterBottom
                  >
                    {asset.file.metadata.width}x{asset.file.metadata.height}
                  </Typography>
                </Grid>
              </Grid>
                */}
            </Grid>
          </Grid>
          </div>
        </div>
      </Slide>
    );
  }
}

AssetDetailExpanded.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssetDetailExpanded);
