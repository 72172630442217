import React from 'react';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { IconButton, Typography, withStyles } from '@material-ui/core';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

const styles = theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    display: 'flex',
    fontSize: theme.typography.pxToRem(12),
    minHeight: '56px',
    height: '56px',
    // Increase the specificity to override TableCell.
    '&:last-child': {
      padding: 0,
    },
  },
  spacer: {
    flex: '1 1 100%',
  },
  caption: {
    flexShrink: 0,
  },
  actions: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});
class TablePagination extends React.Component {

  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, this.props.paginator.first);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.paginator.prev);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.paginator.next);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(event, this.props.paginator.last);
  };

  render() {
    const { classes, paginator, theme, ...other } = this.props;
    const page = 10;

    return (
      <div className={classes.root}>
        <div className={classes.spacer} />
        <Typography variant="caption" className={classes.caption}>
          Total: {paginator.total}, &nbsp; 
          Seite {paginator.page} von {Math.ceil(paginator.total / paginator.perPage)}&nbsp;  
        </Typography>
        <div className={classes.actions}>
          <IconButton
            onClick={this.handleFirstPageButtonClick}
            disabled={(typeof paginator.first === 'undefined')}
            aria-label="First Page"
          >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={this.handleBackButtonClick}
            disabled={(typeof paginator.prev === 'undefined')}
            aria-label="Previous Page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </IconButton>
          <IconButton
            onClick={this.handleNextButtonClick}
            disabled={(typeof paginator.next === 'undefined')}
            aria-label="Next Page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
          <IconButton
            onClick={this.handleLastPageButtonClick}
            disabled={(typeof paginator.last === 'undefined')}
            aria-label="Last Page"
          >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      </div>
    );
  }
}

TablePagination.propTypes = {
  classes: PropTypes.object.isRequired,
  paginator: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

TablePagination.defaultProps = {
};

export default withStyles(styles, { withTheme: true })(TablePagination);
