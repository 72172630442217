import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table, 
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  table: {
  },
  tablecontainer: {
    maxHeight: 350,
    width: '100%',
    overflowX: 'auto',
  },
  }),
  { name: 'HookGlobalStyles', index: 2 }
);

function MemberList(props) {
  const classes = useStyles();
  const { members } = props;

  if (members) {
    return (
      <div className={classes.tablecontainer}>
      <Table className={classes.table} size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Tarifklasse</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member, idx) => {
            return (
              <TableRow key={idx}>
                <TableCell>{member.breed_name}</TableCell>
                <TableCell>{member.tariff_class}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
    );
  } else {
    return (
      null
    );
  }

}

MemberList.propTypes = {
  members: PropTypes.array.isRequired,
};

export default MemberList;
