import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from "@material-ui/icons/Person";
import Typography from '@material-ui/core/Typography';
import Api from '../Api';
import to from '../to';

import { format, } from 'date-fns';
import { de, } from 'date-fns/esm/locale'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));


export default function ActivityList() {
  const [activities, setActivities] = React.useState([]);
  const classes = useStyles();

  const LLL = 'dd.MM.yyyy HH:mm:ss'

  React.useEffect(() => {
    const fetchData = async () => {
      const [err, data] = await to(Api.yfetch('get', '/activities'));
      setActivities(data["public_activity/activities"]);
    };
    fetchData();
  }, []);

  function  activity_url(model,id) {
    return `/${model.toLowerCase()}s/${id}`;
  }

  return (
    <List className={classes.root}
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Aktivitäten
        </ListSubheader>
      }
    >
      { (activities.length > 0) && activities.map( (s, index) => (
      <React.Fragment key={s.id}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar><PersonIcon/></Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={s.key}
            secondary={
              <React.Fragment>
                durch&nbsp; 
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {s.actor.username}
                </Typography>
                <br />
                {format(new Date(Date.parse(s.created_at)), LLL, de)}
                <br />
                <Link to={activity_url(s.trackable_type, s.trackable_id)}>{s.trackable_type}-Link</Link>
              </React.Fragment>
            }
          />
        </ListItem>
        { activities[index+1] && <Divider variant="inset" component="li" /> }
      </React.Fragment>
      ))}
    </List>
  );
}
