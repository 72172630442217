import React from 'react';
import { Button, } from '@material-ui/core';

export default function LoadingTile(props) {
  return (
    <li style={{border: '1px solid #cdcdcd', backgroundColor: '#fefefe', textAlign: 'center', width: '100%', height: '40px'}}>
      <Button color="primary" onClick={props.onClick}>Mehr laden...</Button>
    </li>
  );
}
