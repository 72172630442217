import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { red } from '@material-ui/core/colors';

import {
  Button,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';

const styles = theme => ({
  table: {
    width: '750px',
  },
});

class Row extends React.Component {

  render() {
    const { classes, labelNameValues, row, idx } = this.props;
    return (
      <React.Fragment key={idx}>
        <Grid item xs={4}>
          { (labelNameValues.length > 0) &&
          <FormControl fullWidth={true}>
            <Select
              displayEmpty
              value={ row.keyItem }
              onChange={this.props.handleItemChange('keyItem', idx)}
            >
              <MenuItem value="" disabled>
                Bitte auswählen
              </MenuItem>
              { this.props.labelNameValues.map((label_name, idx) => (
                  <MenuItem key={idx} value={label_name}>{label_name}</MenuItem>
                  )
                )
              }
            </Select>
          </FormControl>
          }
          { (labelNameValues.length < 1) &&
          <FormControl fullWidth={true}>
            <Input
              className={classes.input}
              fullWidth={true}
              inputProps={{
                'aria-label': 'Description',
              }} 
              id={`key-${idx}`}
              key={`key-${idx}`}
              name={`key-${idx}`}
              onChange={this.props.handleItemChange('keyItem', idx)}
              placeholder={ this.props.keyInputPlaceholder }
              type="text"
              value={ row.keyItem }
            />
          </FormControl>
          }
        </Grid>
        <Grid item xs={6}>
          <Input
            className={classes.input}
            fullWidth={true}
            inputProps={{
              'aria-label': 'Description2',
            }} 
            id={`value-${idx}`}
            key={`value-${idx}`}
            name={`value-${idx}`}
            onChange={this.props.handleItemChange('valueItem', idx)}
            placeholder={ this.props.valueInputPlaceholder }
            type="text"
            value={ row.valueItem }
          />
        </Grid>
        <Grid item xs={2}>
          { row.keyItem !== "" &&
          <Button color="primary" aria-label="Delete" size="small"
            onClick={ () => this.props.handleRemove(idx) }
          >
            <DeleteIcon style={{ color: red[600], fontSize: 24 }} />
          </Button>}
        </Grid>
      </React.Fragment>
    );
  }

}

Row.propTypes = {
  classes: PropTypes.object.isRequired,
  keyInputPlaceholder: PropTypes.string,
  valueInputPlaceholder: PropTypes.string,
};

Row.defaultProps = {
  keyInputPlaceholder: 'Key',
  valueInputPlaceholder: 'Value',
}

export default withStyles(styles)(Row);
