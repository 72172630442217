import Api from '../Api';
import to from '../to';

export async function getAsset(id) {
}

export async function  saveAsset(blob) {
    return await to(Api.yfetch('post', `/digital_assets/`, {digital_asset: {title: blob.filename, file: blob}}));
}

export async function updateAsset(asset, description_long) {
  if (description_long) {
    asset.description_long = description_long;
  }
  return to(Api.yfetch('put', `/digital_assets/${asset.id}`,asset ));
}
