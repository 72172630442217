const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((row,idx) => {
      result[idx].position = idx + 1; 
    });
    return result;
};

const ProductUtils = { reorder };
export default ProductUtils;
