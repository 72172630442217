export function buildSchema(csvColumns) {
  let schema = {
      "$schema": "http://json-schema.org/draft-07/schema#",
      "type": "object",
      "attributes" : {},
  };

  csvColumns.map( (col, idx) => {
    return schema.attributes[col.split(/(?=[A-Z])/).join('_').toLowerCase()] = {
      type: "string",
      title: col.split(/(?=[A-Z])/)
                .join(' ')
                .toLowerCase()
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' '),
      enum: [],
      importColIndex: idx,
    }
  });
  return schema;
}
function extractColumn(arr, column) {
    return arr.map(x => x[column])
}

export function extractDistinctValues(arr, columnIndex) {
  const result = [];
  const map = new Map();
  const column = extractColumn(arr, columnIndex);
  for (const item of column) {
    if (!map.has(item)) {
      map.set(item, true);    // set any value to Map
      result.push(item);
    }
  }
  return result;
}

export function extractAttributesAsCsvHeader(schema) {
  let header = [];
    Object.keys(schema.attributes).map( key => {
      const attribute = schema.attributes[key];
      header[attribute.importColIndex] = key;
        });
  return header;
}
