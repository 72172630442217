import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { grey } from '@material-ui/core/colors';

import { withStyles, AppBar, ButtonBase, Toolbar, Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
  },
  appBar: {
    boxShadow: '0px 3px 4px -3px rgba(0,0,0,0.42)',
  },
  button: {
    borderRight: '0.05em solid #9e9e9e', 
    color: grey[900],
    padding: '0.68em',
    '&:hover': { 
      backgroundColor: grey[300],
    }
  },
  grow: {
    flexGrow: 1,
  },
  icon: {
    color: '#9e9e9e', 
    padding: '0 7px 0 7px',
  },
  sectionDesktop: {
    paddingRight: '24px',
  },
  toolBar: {
    minHeight: '40px',
  },
});

function SubNavigation(props) {
  const { classes, title, backAction } = props;
  return (
    <AppBar className={classes.appBar} position="relative" color="default">
      <Toolbar className={classes.toolBar} disableGutters={true} >
        <ButtonBase onClick={backAction}  size="small" className={classes.button} >
          <ChevronLeftIcon />
        </ButtonBase>
        <Typography variant="subtitle1" style={{color: '#424242', paddingLeft: '20px'}}>
          {title}
        </Typography>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          { props.children }
        </div>
      </Toolbar>
    </AppBar>
  );
}


export default withStyles(styles)(SubNavigation);
