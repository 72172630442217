import DashboardPage from "../views/Dashboard";
import Products from "../components/Product/Products";
import RiskBearers from "../components/RiskBearer/RiskBearers";
import AssetManager  from "../components/MediaManager";
import ApplicationSettings from "../components/ApplicationSettings";
import ProductFilter from "../components/ProductFilter";

import {
  Casino,
  Dashboard,
  LibraryBooks,
  Photo,
  Settings,
} from "@material-ui/icons";

import FilterIcon from "../icons/FilterIcon";

const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: DashboardPage
  },
  {
    path: "/products",
    sidebarName: "Produkte",
    navbarName: "Produkte",
    icon: LibraryBooks,
    component: Products
  },
  {
    path: "/risk_bearers",
    sidebarName: "Risikoträger",
    navbarName: "Risikoträger",
    icon: Casino,
    component: RiskBearers
  },
  {
    path: "/media-manager",
    sidebarName: "Media Manager",
    navbarName: "Media Manager",
    icon: Photo,
    component: AssetManager
  },
  {
    path: "/product_filters",
    sidebarName: "Produkt-Filter",
    navbarName: "Produkt-Filter",
    icon: FilterIcon,
    component: ProductFilter
  },
  {
    path: "/application-setting",
    sidebarName: "Einstellungen",
    navbarName: "Einstellungen",
    icon: Settings,
    component: ApplicationSettings
  },
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" },
];

export default dashboardRoutes;
