import React from "react";
import PropTypes from "prop-types";
import { LibraryBooks } from '@material-ui/icons';

import { green, red } from '@material-ui/core/colors';

import { Avatar, Card, Card as CardContent, Grid, Typography, withStyles } from '@material-ui/core';

import ActivityList from '../components/ActivityList';

const styles = {
  avatar: {
    backgroundColor: red[500],
    margin: '10px 15px 10px 10px',
  },
  root: {
    flexGrow: 1,
  },
  card: {
    minWidth: 275,
  },
  cardContent: {
    padding: '24px',
  },
  greenAvatar: {
    margin: '10px 15px 10px 10px',
    color: '#fff',
    backgroundColor: green[500],
  },
  title: {
    marginTop: 4,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  row: {
    display: 'flex',
    justifyContent: 'left',
  },
};

class Dashboard extends React.Component {
  render() {
    const { classes } = this.props;
    return (
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <div className={classes.row}>
                  <Avatar className={classes.greenAvatar}>
                    <LibraryBooks />
                  </Avatar>
                  <div>
                    <Typography className={classes.title} color="textSecondary">
                      N° Products
                    </Typography>
                    <Typography variant="h5" component="h2">
                      4
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <div className={classes.row}>
                  <Avatar aria-label="Recipe" className={classes.avatar}>
                    P
                  </Avatar>
                  <div>
                    <Typography className={classes.title} color="textSecondary">
                      N° Products
                    </Typography>
                    <Typography variant="h5" component="h2">
                      14398
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <ActivityList />
          </Grid>
        </Grid>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
