import React from 'react';

const PapaParse = require('papaparse/papaparse.min.js');

const ChooseFile = ({ 
  classes,
  onFileLoaded, 
  onError,
  parserOptions = {}
}) => {

  const handleChangeFile = e => {
    let reader = new FileReader();
    const filename = e.target.files[0].name;

    reader.onload = event => {
      const csvData = PapaParse.parse(
        event.target.result,
        Object.assign(parserOptions, {
          error: onError
        })
      );
      onFileLoaded(csvData.data, filename);
    };

    reader.readAsText(e.target.files[0]);
  };

  return (
    <div>
      <input 
        accept=".csv, text/csv"
        id="raised-button-file" 
        multiple 
        type="file" 
        onChange={e => handleChangeFile(e)}
      /> 
      {/*<label htmlFor="raised-button-file"> 
        <Button raised component="span"> 
          Upload 
        </Button> 
      </label>*/} 
    </div>
  );
};

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export default function ProductVariantCsvLoader(props) {
  const { setCsvData } = props;
  const parserOptions = {
    preview: 10000000,
    skipEmptyLines: 'greedy',
    header: false,
  };

  const onError = (error_msg) => {
    console.log("Errors: ", error_msg);
  };

  const handleFileParsed = (data,filename) => {
    setCsvData(data);
  };


  return (
    <div>
      <ChooseFile onFileLoaded={handleFileParsed} onError={onError} parserOptions={parserOptions}/>
    </div>
  );
};

