import React from 'react';
import PropTypes from 'prop-types';

import ChipInput from 'material-ui-chip-input'
import { withStyles, Grid } from '@material-ui/core';

const StyledChipInput = withStyles({
  inputRoot: {
    flex: 'none',
  },
})(ChipInput);

export default function AssetTags(props) {
  return (
    <Grid container spacing={1} style={{paddingTop: "20px"}}>
      <Grid item xs={12} style={{paddingRight: '8px'}}>
      <StyledChipInput
        value={props.asset.tag_list || []}
        onAdd={(chip) => props.onTagAdd(chip)}
        onDelete={(chip, index) => props.onTagDelete(chip, index)}
        fullWidthInput
        fullWidth
      />
    </Grid>
    </Grid>
  );
}

AssetTags.propTypes = {
  classes: PropTypes.object.isRequired,
};
