import React from "react";
import {
  Button, 
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid, 
  Popover, 
  Radio,
  RadioGroup,
  Toolbar, 
  Tooltip, 
  Typography,
  withStyles } from '@material-ui/core';
import FilterIcon from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from '@material-ui/core/styles';

import { AppSettingsContext } from '../../providers/AppSettingsProvider';
import RiskBearerSelect from './RiskBearerSelect';

const styles = {
  root: {},
  popoverRoot: {
    padding: '24px 24px 36px 24px',
  },
  actions: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  iconButton: {
    top: "50%",
    display: "inline-block",
    position: "relative",
    transform: "translateY(-25%)"
  },
  deleteIcon: {
  },
  colorAction: {
    color: "#f57c00",
  },
  header: {
    flex: '0 0 auto',
    marginBottom: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  reset: {
    alignSelf: 'left',
  },
  resetLink: {
    marginLeft: '16px',
    fontSize: '12px',
    cursor: 'pointer',
  },
  title: {
    display: 'inline-block',
    marginLeft: '7px',
//    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
  },
};

const useFilterStyles = makeStyles({
  root: {
    padding: '12px 24px 12px 24px',
  },
});

function Filter(props) {
  const classes = useFilterStyles();
  const { appSettings } = React.useContext(AppSettingsContext);
  const {filters, handleChange} = props;

  const handleRadioChange = attr => event => {
    handleChange({ [`${attr}.keyword`]: event.target.value });
  };

  const handleCheckboxChange = attr => event => {
    handleChange({ [attr]: event.target.checked });
  };

  const handleRiskBearerChange = attr => value => {
    handleChange({ [`${attr}`]: value });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.is_discarded}
                  onChange={handleCheckboxChange('is_discarded')}
                  value="discarded"
                  color="primary"
                />
              }
              label="gelöscht"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth={true}>
            <FormLabel component="legend">Risikoträger</FormLabel>
            <RiskBearerSelect
              currentRiskBearerId={filters["risk_bearer.id"] || ''}
              onRiskBearerChange={handleRiskBearerChange('risk_bearer.id')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Sparte</FormLabel>
            <RadioGroup 
              aria-label="insuranceSector" 
              name="insurance_sector" 
              value={filters["insurance_sector.keyword"] || ''} 
              onChange={handleRadioChange('insurance_sector')}
            >

            { appSettings.data && appSettings.data.insurance_sector.map((sector, idx) => (
              <FormControlLabel 
                key={idx} 
                control={<Radio color="primary" />} 
                label={sector} 
                value={sector}
              />
            ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

function TableToolbar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filters, setFilters] = React.useState({
    is_discarded: false,
  });

  React.useEffect(() => {
    setFilters(props.filters)
    },
    [props.filters]
  );

  const handleChange = filter => {
    setFilters({ ...filters, ...filter });
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.onFilterExit(filters);
  };

  const handleAbort = () => {
    setAnchorEl(null);
  };

  const onFilterReset = () => {
    setFilters( {is_discarded: false, } );
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { classes } = props;

  return (
    <React.Fragment>
    <Toolbar className={classes.root} role={'toolbar'} aria-label={'Table Toolbar'}>
      <div className={classes.actions}>
            <Tooltip title={"Filter"}>
              <IconButton className={classes.iconButton} onClick={handleClick}>
                <FilterIcon classes={{root: classes.deleteIcon, colorAction: classes.colorAction}} color={props.isFilterActive ? "action" : "inherit" } />
              </IconButton>
            </Tooltip>
        </div>
    </Toolbar>
    <Popover 
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleAbort}
      elevation={2}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={classes.popoverRoot}>
        <div className={classes.header}>
          <div className={classes.reset}>
              <Typography
                variant="body2"
                className={classes.title}
              >
                Filter
              </Typography>
              <Button
                color="secondary"
                className={classes.resetLink}
                tabIndex={0}
                aria-label="reset"
                onClick={onFilterReset}>
                Zurücksetzen
              </Button>
              <Button
                color="primary"
                className={classes.resetLink}
                tabIndex={1}
                aria-label="execute"
                onClick={handleClose}>
                Anwenden
              </Button>
            </div>
        </div>
        <Filter filters={filters} handleChange={handleChange}/>
      </div>
    </Popover>
    </React.Fragment>
  );
}

export default withStyles(styles)(TableToolbar);
