import React from "react";
import { Switch, Route } from 'react-router-dom';
import RiskBearerList from './RiskBearerList.jsx';
import RiskBearerContainer from './RiskBearerContainer.jsx';
import { SnackbarProvider } from 'notistack';

const RiskBearers = () => (
  <SnackbarProvider>
    <Switch>
      <Route exact path='/risk_bearers' component={RiskBearerList}/>
      <Route path='/risk_bearers/:riskBearerId' component={RiskBearerContainer}/>
    </Switch>
  </SnackbarProvider>
);

export default RiskBearers;
