import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Paper, withStyles } from '@material-ui/core';
import ProductTable from '../Product/ProductTable';
import ProductSearch from './ProductSearch';
import Api from '../../Api';
import to from '../../to';
import TablePagination from '../TablePagination';
import TableToolbar from './TableToolbar';

import { AddButton } from '../SubNavigationActionButtons';
import ConfirmDialog from '../ConfirmDialog';
import SubNavigation from '../SubNavigation';

import { ProductsStateContext } from "../../providers/ProductsStateProvider";


const styles = theme => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  content: {
    padding: '30px',
  },
  table: {
    backgroundColor: '#fefefe',
    minWidth: '700px',
    tableLayout: 'fixed',
  },
  tableCell: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  menu: {
    width: 220,
  },
});

  function buildElasticQuery(params) {
    const bodyBuilder = require('bodybuilder');
    const {
      sort,
      filters,
      query
    } = params;

    var esQuery = bodyBuilder();
    // sort
    // TODO: https://www.elastic.co/guide/en/elasticsearch/plugins/current/analysis-icu-collation-keyword-field.html
    esQuery = esQuery.sort(
      sort.fieldType === 'string' ? `${sort.field}.keyword` : sort.field,
      sort.direction
    );

    //filter
    Object.keys(filters).forEach(filterKey => {
      esQuery = esQuery.filter('term', filterKey, filters[filterKey])
    });

    // multi_match
    if (query.multi_match.searchTerm) {
      const searchTerm = query.multi_match.searchTerm;
      if (searchTerm !== '' && searchTerm !== '*' ) {
        esQuery = esQuery.query('multi_match', {
          query: searchTerm,
          fields: query.multi_match.fields
        });
      } else {
        esQuery = esQuery.query('match_all');
      }
    }

    return JSON.stringify(esQuery.build());
  }

class BaseProductList extends React.Component {

  state = {
    confirmDelete: { 
      id: null,
      showDialog: false,
    },
    products: [],
    isLoading: false,
    paginator: {
      perPage: 5,
      total: 18,
    },
  };


  componentDidMount() {
    this.setState({ isLoading: true });
    this.fetchProducts();
  }

  componentDidUpdate(prevProps,prevState) {
    console.debug("ComponentDidUpdate called");
    console.debug("The context:", this.props.context);
    if ( (prevProps.context[0].productSearch !== this.props.context[0].productSearch) ||
      (prevProps.context[0].page !== this.props.context[0].page) ) {
    this.fetchProducts();
    }
  };

  fetchProducts = async (page = {page:1, per_page: 10}) => {
    this.setState({ isLoading: true });

    const params = {
      _q: buildElasticQuery(this.props.context[0].productSearch), 
      ...this.props.context[0].page
    };
    console.debug("Elastic-Query:", params._q);

    const queryParamsString = Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key]))
      .join('&');

    const [err, data] = await to(Api.paginated_fetch('get', `/products/?${queryParamsString}`));

    if (!err) {
      this.setState({
        products: data.products,
        paginator: data.paginator,
        isLoading: false,
      });
    }
  };

  handleChangePage = (event, page) => {
    const [ctxState, dispatch] = this.props.context;
    console.debug("action=handleChangePage page_param=", page);
    this.setState({ isLoading: true });
    dispatch({type: "setPage", payload: {page: page.page, per_page: page.per_page}});
  };

  handleChangeRowsPerPage = event => {
    console.log("Not implemented yet");
    //this.setState({ rowsPerPage: event.target.value });
  };

  handleElasticQueryClear = () => {
    console.log("Not implemented yet");
  };


  handleProductClone = productId => async () => {
    const [err, data] = await to(Api.yfetch('post', `/products/${productId}/clone`));
    if(err) {
      console.log("Product clone failed. productId=",productId); 
    } else {
      //console.debug("Cloned ProductId=", productId);
      //console.debug("Pushing to new:", data.product.links.self);
      this.props.history.push(data.product.links.self.replace(/\/api\/v1/, ''));
    }
  };

  handleDelete = async () => {
    const [err, data] = await to(Api.yfetch('delete', `/products/${this.state.confirmDelete.id}`));
    if(err) {
      console.log("Product delete failed: ",this.state.confirmDelete.id); 
    } else {
      this.setState({
        confirmDelete: {
          id: null,
          showDialog: false,
        },
      });
      this.fetchProducts();
    }
  };

  handleDialogClose = () => {
    this.setState({
      confirmDelete: {
        id: null,
        showDialog: false,
      }
    });
  };

  handleClick = product_id => event =>  {
    this.setState({
      confirmDelete: {
        id: product_id,
        showDialog: true,
      }
    });
  };

  handleFilterExit = (filters) => {
    const [ctxState, dispatch] = this.props.context;
    dispatch({type: "setFilter", payload: filters});
  };

  handleChangeSort = (col) => {
    const [ctxState, dispatch] = this.props.context;
    dispatch({type: "setSort", payload: col});
  };

  render() {
    const { classes, context,  } = this.props;
    const productTableCols = [
      { id: 'internal_name', label: 'Name', type: 'string', customSort: this.handleChangeSort, width: '35%', },
      { id: 'title', label: 'Verkaufsbezeichnung', type: 'string', customSort: this.handleChangeSort, width: '25%'},
      { id: 'created_at', label: 'Angelegt am', type: 'datetime', customSort: this.handleChangeSort,width: '15%' },
      { id: 'position', label: 'Pos.', type: 'integer', customSort: this.handleChangeSort,width: '5%' },
    ];
    const [productsState, dispatch] = context;
    const isFilterActive = Object.keys(productsState.productSearch.filters).length > 1;
    
    return (
        <div>
          <SubNavigation title="" backAction={() => this.props.history.goBack()} >
            { this.state.isLoading && (
            <div style={{display: 'inline-block', verticalAlign: 'middle'}}> 
              <CircularProgress size={20} /> 
            </div>
            )}
            <AddButton onClick={() => {this.props.history.push("/products/new")}}/>
          </SubNavigation>
          <div className={classes.content}>
            <ProductSearch 
              searchTerm={productsState.productSearch.query.multi_match.searchTerm}
              onRequestSearch={(searchTerm) => dispatch({type: "setProductSearchTerm", payload: searchTerm })} 
            />
            <Paper style={{padding: '10px'}}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TableToolbar 
                    filters={productsState.productSearch.filters} 
                    onFilterExit={this.handleFilterExit} 
                    isFilterActive={isFilterActive}
                  />
                  <ProductTable 
                    products={this.state.products} 
                    columns={productTableCols}
                    parentClasses={classes} 
                    showClone={true} 
                    showDelete={true} 
                    onHandleClick={this.handleClick}
                    onHandleClone={this.handleProductClone}
                  />

                  <TablePagination
                    paginator={this.state.paginator}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Grid>
               </Grid>
                <ConfirmDialog 
                  show={this.state.confirmDelete.showDialog} 
                  proceed={this.handleDelete} 
                  cancel={this.handleDialogClose}
                  confirmation="Produkt wirklich löschen?"/>
            </Paper>
          </div>
        </div>
    );
  }
}


const ProductList = (props) => (
  <ProductsStateContext.Consumer>
    {context =>
      <BaseProductList context={context} {...props} />
    }
  </ProductsStateContext.Consumer>
);

ProductList.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductList);
