import React from 'react';
import ProductVariantImportStepper from './Variants/ProductVariantImportStepper';
import ProductVariantSetsTable from './Variants/ProductVariantSetsTable';
import ProductVariantsTable from './Variants/ProductVariantsTable';
import { Button } from '@material-ui/core';


export default function ProductVariantPresenter(props) {
  const [display, setDisplay] = React.useState('variantSetsTable');
  const [variants, setVariants] = React.useState();
  const [variantSchema, setVariantSchema] = React.useState();
  const { product } = props;

  function handleStatusChange(status) {
    setDisplay(status);
  }

  function handleShowVariants(setId) {
    //setVariantSetId(setId);
    setVariants(product.product_variant_sets.find(x => x.id === setId).variants)
    setVariantSchema(product.product_variant_sets.find(x => x.id === setId).variant_schema)
    setDisplay('variantTable');
  }

  const showSetsTable = () => {
    handleStatusChange('variantSetsTable')
  };

  const ShowImportButton = () => {
    return (
         <Button 
           color="primary"
           disabled={false}
           onClick={ () => handleStatusChange('import') } 
           variant="contained" 
           size="small" >
           Varianten-Set hinzufügen
         </Button>
    );
  };

  const ShowSetsButton = () => {
    return (
         <Button 
           color="primary"
           disabled={false}
           onClick={ () => handleStatusChange('variantSetsTable') } 
           variant="contained" 
           size="small" >
           Varianten-Sets
         </Button>
    );
  };

  return (
    <React.Fragment>
      {display === 'variantSetsTable' && product.product_variant_sets && <React.Fragment>
        <ShowImportButton />
        <ProductVariantSetsTable variantSets={product.product_variant_sets} onShowSet={handleShowVariants} />
      </React.Fragment>}

     {display === 'import' && <ProductVariantImportStepper showSetsTable={showSetsTable}/>}

     {display === 'variantTable' && <React.Fragment>
       <ShowSetsButton />
       <ProductVariantsTable variants={variants} variantSchema={variantSchema} />
      </React.Fragment>}
    </React.Fragment>
  );
}
