import React from "react";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core//TextField';

import 'react-quill/dist/quill.snow.css';
import '../../quill-tv.css';
import ReactQuill from 'react-quill';

import { Grid, Typography, withStyles } from '@material-ui/core';

const editorModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false]}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
      {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
};

const styles = theme => ({
  root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formLabel: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: "400",
  },
  formRow: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class RiskBearerDetail extends React.Component {
  handleChange = name => event => {
    this.props.onAttributeChange(name,event.target.value);
  };

  handleDateChange = (name) => value => {
    this.props.onAttributeChange(name,value.toISOString());
  };

  handleRtfChange = attr => value => {
    this.props.onAttributeChange(attr,value);
    //  this.props.onAttributeChange("consultation_protocol_remarks",value);
  };

  render() {
    const { classes, riskBearer } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form autoComplete="off">
             <TextField
               value={riskBearer.name || ''}
               fullWidth
               id="riskBearer['name']"
               label="Name"
               margin="normal"
               onChange={this.handleChange("name")}
             />

             <TextField
               value={riskBearer.ams_company_code || ''}
               fullWidth
               id="riskBearer['name']"
               label="AMS Gesellschaftskürzel"
               margin="normal"
               onChange={this.handleChange("ams_company_code")}
             />

             <TextField
               value={riskBearer.creditor_identifier || ''}
               fullWidth
               id="riskBearer['creditor_identifier']"
               label="Gläubiger-ID"
               margin="normal"
               onChange={this.handleChange("creditor_identifier")}
             />

             <TextField
               value={riskBearer.addressline1 || ''}
               fullWidth
               id="riskBearer['addressline1']"
               label="Straße, Nr."
               margin="normal"
               onChange={this.handleChange("addressline1")}
             />

             <TextField
               value={riskBearer.addressline2 || ''}
               fullWidth
               id="riskBearer['addressline2']"
               label="Addressezeile 2"
               margin="normal"
               onChange={this.handleChange("addressline2")}
             />

             <Grid container item xs={12} spacing={1}>
               <Grid item xs={2}>
                 <TextField
                   value={riskBearer.zip || ''}
                   fullWidth
                   id="riskBearer['zip']"
                   label="PLZ"
                   margin="normal"
                   onChange={this.handleChange("zip")}
                 />
               </Grid>
               <Grid item xs={10}>
                 <TextField
                   value={riskBearer.city || ''}
                   fullWidth
                   id="riskBearer['city']"
                   label="Stadt"
                   margin="normal"
                   onChange={this.handleChange("city")}
                 />
               </Grid>
             </Grid>

             <TextField
               value={riskBearer.country || ''}
               fullWidth
               id="riskBearer['country']"
               label="Land"
               margin="normal"
               onChange={this.handleChange("country")}
             />

             <TextField
               value={riskBearer.account_holder || ''}
               fullWidth
               id="riskBearer['account_holdername']"
               label="Kontoinhaber"
               margin="normal"
               onChange={this.handleChange("account_holder")}
             />

             <TextField
               value={riskBearer.iban || ''}
               fullWidth
               id="riskBearer['iban']"
               label="IBAN"
               margin="normal"
               onChange={this.handleChange("iban")}
             />

             <TextField
               value={riskBearer.bic || ''}
               fullWidth
               id="riskBearer['bic']"
               label="BIC"
               margin="normal"
               onChange={this.handleChange("bic")}
             />

             <TextField
               value={riskBearer.sepa_prenotification || ''}
               fullWidth
               id="riskBearer['sepa_prenotification']"
               label="Pre-Notification"
               margin="normal"
               onChange={this.handleChange("sepa_prenotification")}
             />

             <div className={classes.formRow}>
               <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Beratungsprotokoll</Typography>
               <ReactQuill 
                 id="consultation_protocol_remarks" 
                 value={riskBearer.consultation_protocol_remarks || ''}
                 onChange={this.handleRtfChange("consultation_protocol_remarks")}
                 modules={editorModules}
               />
             </div>

             <div className={classes.formRow}>
               <Typography className={classes.formLabel} variant="subtitle2" gutterBottom>Weiterleitungsnotiz</Typography>
               <ReactQuill 
                 id="forwarding_note" 
                 value={riskBearer.forwarding_note || ''}
                 onChange={this.handleRtfChange("forwarding_note")}
                 modules={editorModules}
               />
             </div>


          </form>
        </Grid>
      </Grid>
    );
  }
}

RiskBearerDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RiskBearerDetail);
