import React from "react";
import {  IconButton, Input, Paper, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  iconButton: {
    opacity: 0.54,
    transform: 'scale(1, 1)',
    transition: 'transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)'
  },
  iconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0
    }
  },
  iconButtonDisabled: {
    opacity: 0.38
  },
  searchIconButton: {
    marginRight: -48,
  },
  icon: {
    opacity: 0.54,
    transition: 'opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1)'
  },
  input: {
    width: '100%'
  },
  searchContainer: {
    margin: 'auto 16px',
    width: 'calc(100% - 48px - 32px)' // 48px button + 32px margin
  }
}));

export default function RiskBearerSearch(props) {
  const bodyBuilder = require('bodybuilder');
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState(''); 
  const [filters, setFilters] = React.useState([]); 
  const [sort, setSort] = React.useState({
    sortField: 'name.keyword',
    sortDirection: 'asc'
  }); 

  const { className, style } = props;
  const searchIcon = <SearchIcon style={{ color: grey[500] }} />;
  const closeIcon = <ClearIcon style={{ color: grey[500] }} />;
  const placeholder = 'Suchbegriff, z.B. Barmenia';

  function buildQuery() {
    if (searchTerm != '' && searchTerm != '*' ) {
      return bodyBuilder()
        .orQuery('match', 'name', searchTerm)
        .orQuery('match', 'ams_company_code', searchTerm)
        .sort(sort.sortField, sort.sortDirection)
        .build();
    } else {
      return bodyBuilder().query('match_all');
    }
  }

  const handleCancel = () => {
    if (props.onCancelSearch) {
      props.onCancelSearch();
    }
  };

  const handleInput = (e) => {
    setSearchTerm(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value)
    }
  };

  const handleKeyUp = (e) => {
    if (e.charCode === 13 || e.key === 'Enter') {
      handleRequestSearch()
    } 
  }

  const handleRequestSearch = () => {
    if (props.onRequestSearch) {
      props.onRequestSearch(buildQuery());
    }
  };

  return (
    <Paper
      className={classNames(classes.root, className)}
      style={style}
    >
      <div className={classes.searchContainer}>
        <Input
          value={searchTerm}
          onChange={handleInput}
          onKeyUp={handleKeyUp}
          fullWidth
          className={classes.input}
          disableUnderline
          placeholder={placeholder}
        />
      </div>
      <IconButton
        onClick={ handleRequestSearch } 
        classes={{
          root: classNames(classes.iconButton, classes.searchIconButton, {
            [classes.iconButtonHidden]: searchTerm !== ''
          }),
          disabled: classes.iconButtonDisabled
        }}
        >
          {React.cloneElement(searchIcon, {
            classes: { root: classes.icon }
          })}
        </IconButton>
        <IconButton
          onClick={handleCancel}
          classes={{
            root: classNames(classes.iconButton, {
              [classes.iconButtonHidden]: searchTerm === ''
            }),
            disabled: classes.iconButtonDisabled
          }}
        >
          {React.cloneElement(closeIcon, {
            classes: { root: classes.icon }
          })}
        </IconButton>
  </Paper>
  );
}
