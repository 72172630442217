import React from 'react';
import PropTypes from 'prop-types';
import { isWidthUp } from '@material-ui/core/withWidth';
import InfoIcon from '@material-ui/icons/Info';

import AssetSearch from './AssetSearch';
import AssetDropzone from './AssetDropzone';
import LoadingTile from './LoadingTile';

import { withStyles, withWidth, GridList, GridListTile, GridListTileBar, IconButton } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexGrow: 3,
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    marginRight: '20px',
  },
  appBar: {
    boxShadow: '0px 3px 4px -3px rgba(0,0,0,0.42)',
    minHeight: '40px',
  },
  gridList: {
    backgroundColor: '#cfd8dc',
    height: '418px',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  tile: {
    boxSizing: "border-box",
    border: "3px solid #149cb4",
  },
});

const useIntersect = ({ root=null, threshold = 1.0, rootMargin = "0px" }) => {
  const [entry, updateEntry] = React.useState({});
  const [node, setNode] = React.useState(null);
  
  const observer = React.useRef(null);

  React.useEffect(
    () => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        ([entry]) => updateEntry(entry),
        { root, rootMargin, threshold}
      );

      const { current: currentObserver } = observer;

      if (node) currentObserver.observe(node);

      return () => currentObserver.disconnect();
    },
    [node, root, rootMargin, threshold]
  );

return [setNode, entry];
};

function LoadingTileOrig(props) {
  const [ref, entry] = useIntersect({
    threshold: 0.01
  });

  if (entry && entry.boundingClientRect) {
    const y = entry.boundingClientRect.y
    props.onScroll(y);
  }

  return (
    <li ref={ref} style={{width: '100%', height: '20px'}}></li>
  );
}

function Tile(props) {
  const { 
    asset, 
    classes, 
    handelAssetSelect, 
    ...tile_props } = props;

  return (
    <GridListTile 
      {...tile_props}
      onClick={ handelAssetSelect(asset) }
    >
      <img src={asset.file.preview_url} alt={asset.title} />
      <GridListTileBar
        title={asset.title}
        subtitle={<span>{asset.description_short || '\u00A0'}</span>}
        actionIcon={
          <IconButton className={classes.icon}>
            <InfoIcon />
          </IconButton>
        }
      />
    </GridListTile>
  );
}

function AssetGridList(props) {

  const [prevY, setPrevY] = React.useState(0);
  const { classes, assets } = props;

  const handelAssetSelect = asset => e => {
    props.onSelectAsset(asset);
  };

  const handleScroll = (positionY) => {
    //console.log(`HandleScroll fired, prevY=${prevY} positionY=${positionY}`);
    //if (! assets.find(x => x.id === id) ) console.log("Fire Fetch");
    setPrevY(positionY);
    if (prevY > positionY) {
      props.handleFetchAssets();
    }
  };

  const getGridListCols = () => {
    if (isWidthUp('xl', props.width)) {
      return 5;
    }
    if (isWidthUp('lg', props.width)) {
      return 4;
    }
    if (isWidthUp('md', props.width)) {
      return 3;
    }
    return 2;
  }

  /*classes={{ tile: classes.tile} }*/
  return (
    <div className={classes.root}>
      <div style={{width: '100%'}}>
        <AssetDropzone onImageUploaded={props.onImageUploaded} />
        <AssetSearch onSearch={props.onSearch} />
      </div>
      <div style={{overflow: 'scroll', width: '100%', height: '100%'}}>
        <GridList cellHeight={120} className={classes.gridList} cols={getGridListCols()}>
          {assets.map(asset => (
            <Tile key={asset.id} asset={asset} classes={classes} handelAssetSelect={handelAssetSelect} />
          ))}
          {/*<LoadingTile onScroll={handleScroll}/>*/}
          {!props.lastPage && <LoadingTile onClick={props.handleFetchAssets}/>}
          }
        </GridList>
      </div>
    </div>
  );
}

AssetGridList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(AssetGridList));
