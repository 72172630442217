import React from 'react';
import PropTypes from 'prop-types';
import Build from "@material-ui/icons/Build";
import { DatePicker } from '@material-ui/pickers';
import Delete from '@material-ui/icons/Delete';
import Photo from "@material-ui/icons/Photo";
import Save from '@material-ui/icons/Save';
import 'react-quill/dist/quill.snow.css';

import Utils from '../../Utils';

import { withStyles, Input, Button, Divider, Grid, Typography } from '@material-ui/core';

/*
const asset = {
  file_url: "s3://whatever",
  content_type: "binary/img",
  metadata: {
    link_description: "Die passende Hundehaftpflicht für ...",
    alt: "Typischer Schadensfall der Hundeha....",
  }
};
*/

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 'calc(100vh - 115px)',
  },
  rootGrowed: {
    //extend: 'root',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexGrow: '4',
    paddingLeft: '15px',
  },
  button: {},
  buttonGrid: {
    borderTop: '1px solid #cdcdcd',
  },
  detailContent: {
    paddingLeft: '15px',
  },
  detailHead: {
    paddingLeft: '15px',
  },
  h3: {
    marginTop: 0,
  },
  metadata: {
    margin: 0,
    listStyle: 'none',
    borderTop: '1px solid rgba(215,225,237,.6)',
    borderBottom: '1px solid rgba(215,225,237,.6)',
    padding: '15px 0 15px 0',
    '& li': {
      fontSize: '.8125em',
      padding: '3px 0 4px',
      minHeight: '25px',
    }
  },  
  title: {
    fontWeight: '600',
  },
  inputSmall: {
    fontSize: '12px',
    padding: '1px 0 1px',
  },
  dimmed: {
    color: '#607d8b',
  },
  icon: {
    verticalAlign: 'bottom',
  },
  info: {
  },
  imgGrid: {
    backgroundColor: '#000',
  },
  imgPreview: {
    margin: 'auto',
    display: 'block',
  },
  centered: {
    textAlign: 'center',
  },
});

class AssetDetail extends React.Component {
  state = {description_long: this.props.asset.description_long || ''};

  handleDescriptionChange = (html) => { this.setState({description_long: html}) };
  handleSave = () => {this.props.onSave(this.state.description_long)};
  handleDateChange = (name) => (date) => {
    if (date) {
      this.props.onAttributeChange(name, date.toISOString());
    } else {
      this.props.onAttributeChange(name, date);
    }
  };


  render() {
    const { classes, asset, grow } = this.props;

    return (
      <div className={grow ? classes.rootGrowed : classes.root}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
        {asset &&
          <div className={classes.detailContent} style={{padding: '4px 0 0 4px'}}>
          <Grid container item xs={12} spacing={1}>
            <Grid className={classes.imgGrid} item xs={12}>
              <img className={classes.imgPreview} src={asset.file.preview_url} alt={asset.title} />
            </Grid>

            <Grid item xs={4} style={{verticalAlign: 'middle'}}>
              <Typography align='left' className={classes.info} variant="subtitle2" gutterBottom>
                <Photo className={classes.icon} /> {asset.file.content_type.split('/')[1].toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align='center' className={classes.dimmed} variant="subtitle2" gutterBottom>{Utils.humanFileSize(asset.file.byte_size, false)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align='right' className={classes.dimmed} variant="body2" gutterBottom>{asset.file.metadata.width}x{asset.file.metadata.height}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.title} variant="subtitle2" gutterBottom>Title:</Typography>
            </Grid>
            <Grid item xs={12} className={classes.info}>
              <Input
                classes={ {input: classes.inputSmall}}
                value={asset.title}
                fullWidth={true}
                inputProps={{
                  'aria-label': 'Titel',
                }}
                onChange={this.props.onChange('title')}
              />
            </Grid>

            <Grid item xs={12} zeroMinWidth>
              <Typography className={classes.title} variant="subtitle2" gutterBottom noWrap>Kurzbeschreibung:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                classes={ {input: classes.inputSmall}}
                value={asset.description_short || ''}
                fullWidth={true}
                inputProps={{
                  'aria-label': 'Short Description',
                }}
                onChange={this.props.onChange('description_short')}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.title} variant="subtitle2" gutterBottom>Gültig ab:</Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                autoOk
                clearable
                disableToolbar
                format="dd/MM/yyyy"
                variant="dialog"
                value={asset.publication_on && new Date(asset.publication_on)}
                onChange={ this.handleDateChange('publication_on') }
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.title} variant="subtitle2" gutterBottom>Gültig bis:</Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                autoOk
                clearable
                disableToolbar
                format="dd/MM/yyyy"
                variant="dialog"
                value={ asset.publication_off && new Date(asset.publication_off)}
                onChange={ this.handleDateChange('publication_off') }
              />
            </Grid>

          </Grid>
          </div>}
          { asset && 
          <div className={classes.detailContent} style={{padding: '4px'}}>
            <Grid alignItems="center" className={classes.buttonGrid} container item xs={12} spacing={1} justify="center">
              <Grid item xs={6}>
                <div className={classes.centered}>
                  <Button color="primary" onClick={this.handleSave}  size="small" >
                    <Save className={classes.leftIcon} />
                    Save
                  </Button>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.centered}>
                  <Button color="primary" onClick={this.props.onResize}  size="small" >
                    <Build className={classes.leftIcon} />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.centered}>
                  <Button color="primary" onClick={this.handleSave}  size="small" >
                    <Delete className={classes.leftIcon} />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        }
      </div>
      </div>
    );
  }
}

AssetDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssetDetail);
