import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import { Input, Typography, Grid } from '@material-ui/core';

function LicenseDetail(props) {
  const { classes, license } = props;
  return (
    <Grid container item xs={12} spacing={1} style={{paddingTop: "20px"}}>
      <Grid item xs={3} zeroMinWidth>
        <Typography
          align='right' 
          className={classes.title}
          variant="subtitle2"
          gutterBottom
          noWrap>
          Creator
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Input
          classes={ {input: classes.inputSmall}}
          value={license.creator || ''}
          fullWidth={true}
          inputProps={{
            'aria-label': 'Creator of asset',
          }}
          onChange={props.onChange('license.creator')}
        />
      </Grid>

      <Grid item xs={3} zeroMinWidth>
        <Typography
          align='right' 
          className={classes.title} variant="subtitle2" gutterBottom noWrap>Contributor</Typography>
      </Grid>
      <Grid item xs={9}>
        <Input
          classes={ {input: classes.inputSmall}}
          value={license.contributor || ''}
          fullWidth={true}
          inputProps={{
            'aria-label': 'Contributor of asset',
          }}
          onChange={props.onChange('license.contributor')}
        />
      </Grid>

      <Grid item xs={3} zeroMinWidth>
        <Typography
          align='right' 
          className={classes.title} variant="subtitle2" gutterBottom noWrap>Copyright</Typography>
      </Grid>
      <Grid item xs={9}>
        <Input
          classes={ {input: classes.inputSmall}}
          value={license.copyright || ''}
          fullWidth={true}
          inputProps={{
            'aria-label': 'Copyright of asset',
          }}
          onChange={props.onChange('license.copyright')}
        />
      </Grid>

      <Grid item xs={3} zeroMinWidth>
        <Typography
          align='right' 
          className={classes.title} variant="subtitle2" gutterBottom noWrap>Copyright-Owner</Typography>
      </Grid>
      <Grid item xs={9}>
        <Input
          classes={ {input: classes.inputSmall}}
          value={license.copyright_owner || ''}
          fullWidth={true}
          inputProps={{
            'aria-label': 'Copyright Owner of asset',
          }}
          onChange={props.onChange('license.copyright_owner')}
        />
      </Grid>

      <Grid item xs={3} zeroMinWidth>
        <Typography
          align='right' 
          className={classes.title} variant="subtitle2" gutterBottom noWrap>Usage-Terms</Typography>
      </Grid>
      <Grid item xs={9}>
        <Input
          classes={ {input: classes.inputSmall}}
          value={license.usage_terms || ''}
          fullWidth={true}
          inputProps={{
            'aria-label': 'Usage Terms of asset',
          }}
          onChange={props.onChange('license.usage_terms')}
        />
      </Grid>

      <Grid item xs={3} zeroMinWidth>
        <Typography
          align='right' 
          className={classes.title} variant="subtitle2" gutterBottom noWrap>Expires</Typography>
      </Grid>
      <Grid item xs={9}>
        <DatePicker
          autoOk
          clearable
          disableToolbar
          format="dd/MM/yyyy"
          variant="dialog"
          value={ (license.expires && new Date(license.expires)) || null}
          onChange={ props.onDateChange('license.expires') }
        />
      </Grid>
    </Grid>
  );
}

LicenseDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  license: PropTypes.object.isRequired,
};

/* TODO: Wrap component using `React.memo()`
export default React.memo(LicenseDetail);
 */
export default LicenseDetail;
