import Api from '../Api';
import React from 'react';

const fetchProductVariantSet = id => async () => {
  Api.yfetch('get', `/product_varant_sets/${id}`);
};

const saveProductVariantSet = data => {
  return Api.yfetch('post', '/product_variant_sets', data);
};

const updateProductVariantSet = data => {
  return Api.yfetch('put', `/product_variant_sets/${data.id}`, data);
};

const useFetch = (action) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);
  async function loadData() {
    try {
      setLoading(true);
      const actionData = await action();
      setData(actionData);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }
  React.useEffect(() => {
    loadData();
  }, [action]);

  return [data, loading, error];
};

const ProductAPI = { saveProductVariantSet, updateProductVariantSet, fetchProductVariantSet };
export default ProductAPI;
