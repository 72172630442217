import React from 'react';
import PropTypes from 'prop-types';

import { DIGITAL_ASSET_URL } from '../../urls.js';
import Api from '../../Api';
import to from '../../to';
import { updateAsset } from '../../api/AssetAPI';

import AssetGridList from './AssetGridList';
import AssetDetail from './AssetDetail';
import AssetDetailExpanded from './AssetDetailExpanded';
import SubNavigation from '../SubNavigation';

import { SnackbarProvider, withSnackbar } from 'notistack';

import { withStyles, CircularProgress, Slide } from '@material-ui/core';

const styles = theme => ({
  root: {
    backgroundColor: '#cfd8dc',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    //    padding: '0 4px 0 4px',
    paddingLeft: '4px',
  },
  appBar: {
    boxShadow: '0px 3px 4px -3px rgba(0,0,0,0.42)',
    minHeight: '40px',
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    paddingRight: '24px',
  },
  toolBar: {
    minHeight: '40px',
  },
});

class _AssetManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      assetSearch: {
        searchTerm: "*",
      },
      isLoading: false,
      ui: {
        detailGrowed: false,
        tilesPerRow: 4,
      },
      assets: [],
      selectedAsset: false,
      page: 1,
      lastPage: true,
    };
    this.handleUpdateAsset = this.handleUpdateAsset.bind(this);
  }

  componentDidMount() {
    this.fetchAssets();
  }

  handleFetchNextPage = () => {
    //if (this.state.paginator && this.state.paginator.next)
    if (this.state.paginator && ((this.state.paginator.page * this.state.paginator.perPage) < this.state.paginator.total) ) {
      this.fetchAssets(this.state.page + 1);
    } 
  };

  fetchAssets = async (pageNo = 1 ) => {
    this.setState({ isLoading: true, });

    const params = {
      page: pageNo,
      per_page: 12,
      q: this.state.assetSearch.searchTerm,
    };

    const queryParamsString = Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key]))
      .join('&');

    //const [err, data] = await to(Api.paginated_fetch('get', `/digital_assets?${queryParamsString}`, ));
    const [err, data] = await to(Api.paginated_fetch('get', `/digital_asset_searches/?${queryParamsString}`));
    if (!err) {
      
      this.setState(prevState => ({
        assets: [...prevState.assets, ...data.digital_assets],
        paginator: data.paginator,
        isLoading: false,
        page: pageNo, 
        lastPage: ((data.paginator.page * data.paginator.perPage) >= data.paginator.total) ? true : false,
      }));
    }
  };

  handleElasticQuery = (searchTerm) => {
    this.setState(
      {
        assetSearch: {
          ...this.state.assetSearch,
          searchTerm: searchTerm
        },
        page: 1,
        assets: [],
      },
      () => this.fetchAssets(this.state.page)
    );
  };


  /*
  searchAssets = async(query) => {
    this.setState({ isLoading: true,});
    const [err, data] = await to(Api.yfetch('get', `/digital_asset_searches/?q=${query}`));
    if(err) {
      console.log("Error retriving assets with query:", query)
      this.setState({ isLoading: false,});
    } else {
      this.setState(prevState => ({
        assets: data.digital_assets,
        isLoading: false,
      }));
    }
  };
  */

  saveAsset = async (blob) => {
    this.setState(prevState => ({ isSaving: true }));
    const [err, data] = await to(Api.yfetch('post', `/digital_assets/`, {digital_asset: {title: blob.filename, file: blob}}));
    if(err) {
      this.setState(prevState => ({
        tabValue: 0,
        isSaving: false,
      }));
      this.props.enqueueSnackbar('Ein Fehler ist aufgetreten', {variant: "error" });
    } else {
      this.setState(prevState => ({
        tabValue: 0,
        assets: [data.digital_asset, ...prevState.assets ],
        isSaving: false,
        saved: true,
      }));
      this.props.enqueueSnackbar(`Gespeichert: ${blob.filename}`, {variant: "success" });
    }
  };

  async handleUpdateAsset(description_long) {
    this.setState(prevState => ({ isSaving: true }));
    const [err, data] = await updateAsset(this.state.selectedAsset, description_long)
    if(err) {
      this.setState(prevState => ({
        tabValue: 0,
        isSaving: false,
      }));
      this.props.enqueueSnackbar('Ein Fehler ist aufgetreten', {variant: "error" });
    } else {
      var newAssets = this.state.assets;
      newAssets[this.state.selectedAssetIndex] = data.digital_asset;
      this.setState(prevState => ({
        tabValue: 0,
        assets: newAssets,
        isSaving: false,
        saved: true,
      }));
      this.props.enqueueSnackbar(`Gespeichert: ${this.state.selectedAsset.title}`, {variant: "success" });
    }
  }

  toggleDetailSize = () => {
    this.setState(prevState => ({
      ui: {
        ...prevState.ui, 
        ...{
           detailGrowed: !prevState.ui.detailGrowed,
           tilesPerRow: prevState.ui.detailGrowed ? 4 : 1 
        }
      }
    }));
  };
  
  selectAsset = asset => {
    this.setState({
      selectedAsset: asset,
      selectedAssetIndex: this.state.assets.indexOf(asset),
    });
  };

  handleAssetChangeOld = name => event => {
    this.setState({
      selectedAsset: {
        ...this.state.selectedAsset,
        ...{
          [name]: event.target.value,
        }
      }
    });
  };

  updateObjProp = (obj, value, propPath) => {
    const [head, ...rest] = propPath.split('.');

    !rest.length
      ? obj[head] = value
      : this.updateObjProp(obj[head], value, rest.join('.'));
  }

  handleAssetChange = name => event => {
    //console.debug("handleAssetChange called");
    //console.debug(`name: ${name}, event.target.value: ${event.target.value}`);
    let newSelectedAsset = this.state.selectedAsset;
    this.updateObjProp(newSelectedAsset, event.target.value, name);
    this.setState({
      selectedAsset: newSelectedAsset,
    });
  };

  /* Not used b/c performance. Assessment out of the gut */
  handleAssetDescriptionChange = html => {
    this.setState({
      selectedAsset: {
        ...this.state.selectedAsset,
        ...{
          description_long: html,
        }
      }
    });
  };

  handleAssetAttributeChange = (attr, value) => {
    //console.debug("handleAssetAttributeChange called");
    this.setState({
      selectedAsset: {
        ...this.state.selectedAsset,
        ...{
          [attr]: value,
        }
      }
    });

  }

  handleLicenseChange = (attr, value) => {
    //console.debug("handleLicenseChange called");
    //console.debug(`name: ${attr}, value: ${value}`);
    let newSelectedAsset = this.state.selectedAsset;
    this.updateObjProp(newSelectedAsset, value, attr);
    this.setState({
      selectedAsset: newSelectedAsset,
    });
  };

  handleTagAdd = (tag) => {
    let newTagList = this.state.selectedAsset.tag_list;
    newTagList.push(tag);
    this.setState({
      selectedAsset: {
        ...this.state.selectedAsset,
        ...{
          tag_list: newTagList,
        }
      }
    });
  };

  handleTagDelete = (tag, index) => {
    let newTagList = this.state.selectedAsset.tag_list;
    if (index > -1) {
       newTagList.splice(index, 1);
    }
    this.setState({
      selectedAsset: {
        ...this.state.selectedAsset,
        ...{
          tag_list: newTagList,
        }
      }
    });
  };

  render() {
    const { classes } = this.props;
    const {isLoading } = this.state;

    if (!this.state.ui.detailGrowed) {
      return (
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <div style={{height: '100%'}}>
          <SubNavigation title="" backAction={() => this.props.history.goBack()}>
            {isLoading && <CircularProgress size={18} />}
          </SubNavigation>  
          <div className={classes.root} style={{}}>
            <AssetGridList 
              assets={this.state.assets} 
              tilesPerRow={this.state.ui.tilesPerRow} 
              onImageUploaded={this.saveAsset} 
              onSelectAsset={this.selectAsset} 
              onSearch={this.handleElasticQuery}
              handleFetchAssets={this.handleFetchNextPage}
              lastPage={this.state.lastPage}
            />
            <AssetDetail
              asset={this.state.selectedAsset} 
              grow={this.state.ui.detailGrowed} 
              onChange={this.handleAssetChange}
              onAttributeChange={this.handleAssetAttributeChange}
              onResize={this.toggleDetailSize}
              onSave={this.handleUpdateAsset}
              key={this.state.selectedAsset.id} />
          </div>
        </div>
      </Slide>
      );
    } else {
      return (
        <AssetDetailExpanded
          asset={this.state.selectedAsset} 
          grow={this.state.ui.detailGrowed} 
          onTagAdd={this.handleTagAdd}
          onTagDelete={this.handleTagDelete}
          onChange={this.handleAssetChange}
          onAttributeChange={this.handleAssetAttributeChange}
          onLicenseChange={this.handleLicenseChange}
          onResize={this.toggleDetailSize}
          onSave={this.handleUpdateAsset}
          key={this.state.selectedAsset.id} />
      );
    }
  }
}

const AssetManagerNotistack = withStyles(styles)(withSnackbar(_AssetManager));

function AssetManager(props) {
  return (
    <SnackbarProvider maxSnack={3}>
      <AssetManagerNotistack {...props} />
    </SnackbarProvider>
  );
}

_AssetManager.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default withStyles(styles)(AssetManager);
export default AssetManager;
