import React from 'react';
import PropTypes from 'prop-types';
import EdiTable from '../input-editable/EdiTable';
import RiskBearerDetail from './RiskBearerDetail';
import BreedListContainer from './BreedList/index';
import Save from '@material-ui/icons/Save';
import Notifier from '../Notifier';

import 'font-awesome/css/font-awesome.min.css';

import SubNavigation from '../SubNavigation';
import { CancleButton, SaveButton } from '../SubNavigationActionButtons';

import { Button, CircularProgress, Grid, Paper, Tabs, Tab, withStyles, Typography } from '@material-ui/core';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingLeft: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

function TabFooter(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    //width: "100%",
    overflowX: "auto"
  },
  table: {
    backgroundColor: '#fefefe',
    minWidth: '700px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  content: {
    padding: '20px',
  },
  leftIcon: {
    fontSize: 18,
  },
  formLabel: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: "400",
  },
  formRow: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  menu: {
    width: 220,
  },
  progress: {
    margin: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
});

const headers = [
    {value: 'Vermittler-Nr.', type: 'TextField', width: 200},
    {value: 'E-Mail', type: 'TextField', width: 200},
    {value: 'Name', type: 'TextField', width: 200},
];

class RiskBearerTabs extends React.Component {

  state = {
    page: 0,
    tabValue: 0,
    saved: false,
    productDefinitionString: "",
  };

  handleChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  render() {
    const { classes, isLoading, notification  } = this.props;
    const { tabValue} = this.state;
    const convertedRows = this.props.riskBearer.agent_info.map((row) => {
      return { columns: [ {value: row.agent_no}, {value: row.email},{value: row.recipient_name} ] }
    });

    if (isLoading) { return null }

    return (
    <div className={classes.root}>
      <SubNavigation title={`Risk Bearer Details: ${this.props.riskBearer.name}`} backAction={() => this.props.history.goBack()}>
        { this.props.isSaving && (
          <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
            <CircularProgress size={20} />
          </div>
        )}
        <CancleButton onClick={() => {this.props.history.push("/risk_bearers")}}/>
        <SaveButton onClick={ this.props.onSave }/>
      </SubNavigation>
      <div className={classes.content}>
      <Paper className={classes.root} style={{padding: '10px'}}>
        <Notifier notification={notification} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tabs
              value={this.state.tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleChange}
            >
              <Tab label="Details" />
              <Tab label="Agent Info" />
              <Tab label="BreedLists" />
            </Tabs>
            {tabValue === 0 && <TabContainer>
              <RiskBearerDetail
                isLoading={this.props.isLoading}
                isSaving={this.props.isSaving}
                riskBearer={this.props.riskBearer} 
                onAttributeChange={this.props.onAttributeChange} 
                onSave={this.props.onSave}
                notification={this.props.notification}
              /> 
            </TabContainer>}
            {tabValue === 1 && <TabContainer>
              <div style={ { paddingTop: '15px' } }> 
              <EdiTable 
                onChange={(row) => {this.props.onAgentInfoRowChange(row)} }
                onDelete={(row) => {this.props.onAgentInfoRowDelete(row.rowId)} }
                rows={convertedRows}
                headerColumns={headers}
                enableDelete={true}
              />
            </div>
            </TabContainer>}
            {tabValue === 2 && <TabContainer>
              <BreedListContainer
                breedLists={this.props.riskBearer.breed_lists} 
                parentClasses={classes} 
                onBreedListAdd={this.props.onBreedListAdd}
              />
            </TabContainer>}
            <TabFooter>
              <div className={classes.wrapper}>
                <Button 
                  color="primary" 
                  onClick={this.props.onSave} 
                  variant="contained" 
                  size="small" 
                  disabled={this.props.isSaving} 
                >
                  <Save className={classes.leftIcon} />
                    Save
                    {this.props.isSaving && <CircularProgress className={classes.buttonProgress} size={24} />}
                </Button>
              </div>
            </TabFooter>
          </Grid>
         </Grid>
      </Paper>
    </div>
    </div>
    );
  }
}

RiskBearerTabs.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RiskBearerTabs);
