import React from "react";
import DynamicRowForm from '../input-dynamic-row-form/index';
import { IconButton, Typography, makeStyles } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { AppSettingsContext } from '../../providers/AppSettingsProvider';
import { ProductContext } from '../../providers/ProductProvider';


function comparativeSchemaBuilder(ui) {
  return [
    {
      attr: "position", 
      key: "position", 
      label: "Position", 
      type: "hidden",
      props: {min: 0, max: 50}, 
      size: 1, 
      value: '' 
    },
    {
      attr: "title", 
      key: "title", 
      label: "Title", 
      type: "dropdown",
      values: ui.comparatives_titles,
      size: 4, 
      value: '' 
    },
    {
      attr: "icon", 
      key: "icon", 
      label: "Icon", 
      type: "dropdown",
      values: ui.comparatives_icons,
      size: 3, 
      value: '' 
    },
    {
      attr: "display_target", 
      key: "display_target", 
      label: "Anzeigen auf", 
      type: "dropdown",
      values: ["website", "oktopussy", "alle" ],
      size: 3, 
      value: '' 
    },
    {
      attr: "content", 
      key: "valueItem", 
      label: "Content", 
      type: "rte",
      props: {required: true}, 
      size: 12, 
      value: '' 
    },
  ];
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  button: {
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    padding: 'unset',
  },
  icon: {
    fontSize: 20,
    opacity: 0.7,
  },
  title: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: "400",
  },
}));

function emptyComparative(array, pos) {
  const emptyComparative = 
    array.reduce((obj, item) => {
      obj[item.attr] = item.value;
      return obj
    }, {});

  emptyComparative.position = pos;
  return emptyComparative;
}


function ProductComparatives(props) {
  const classes = useStyles();
  const { appSettings } = React.useContext(AppSettingsContext);
  const { ctxProduct, actions } = React.useContext(ProductContext);

  let comparativeTitles = [];
  let comparativeIcons = [];

  try {
    const insuranceSectorUiSettings = appSettings.data.insurance_sector_ui[ctxProduct.insurance_sector];
    if (!insuranceSectorUiSettings) {
      throw new Error(`missing appSettings.insurance_sector_ui for product.insurance_sector=${ctxProduct.insurance_sector}`);
    }
    if (!insuranceSectorUiSettings.hasOwnProperty("comparatives_titles") ) {
      throw new Error(`missing appSettings.insurance_sector_ui.comparatives_titles for product.insurance_sector=${ctxProduct.insurance_sector}`);
    }
    if(!insuranceSectorUiSettings.hasOwnProperty("comparatives_icons") ){
      throw new Error(`missing appSettings.insurance_sector_ui.comparatives_icons for product.insurance_sector=${ctxProduct.insurance_sector}`);
    }

    comparativeTitles = insuranceSectorUiSettings.comparatives_titles;
    comparativeIcons = insuranceSectorUiSettings.comparatives_icons;
    //console.debug("comparativeTitles:", comparativeTitles);
    //console.debug("comparativeIcons:", comparativeIcons);
  }
  catch(error) {
    console.error(error);
  }

  const comparativeSchema = comparativeSchemaBuilder({
    "comparatives_titles": comparativeTitles,
    "comparatives_icons": comparativeIcons
  });

  const onRowChange = (attr, value, idx) => {
    //console.debug(`onRowChange called attr=${attr} value=${value} idx=${idx}`);
    actions.onDynamicFormAttrChange("comparatives", attr, value, idx);
  }

  const onRowReorder = (src,dst) => {
    //console.debug(`onRowReorder called src=${src} dst=${dst}`);
    actions.onDynamicFormRowReorder("comparatives", src, dst);
  }

  const onRowRemove = (idx) => {
    actions.onDynamicFormRowRemove("comparatives", idx);
  }

  const AddComparativesButton = () => (
    <div style={{ display: 'flex', alignItems: 'center', margin: '5px 0 5px 0'}}>
      <Typography className={classes.title} noWrap variant="subtitle2">
        Comparatives
      </Typography>
      <IconButton 
        className={classes.button} 
        aria-label="Add comparative" 
        onClick={actions.onDynamicFormAddEmptyRow(emptyComparative(comparativeSchema, ctxProduct.comparatives.length + 1), "comparatives")}>
        <AddCircleOutline className={classes.icon}/>
      </IconButton>
    </div>);

  return (
    <div className={classes.root}>
      <AddComparativesButton />
      <DynamicRowForm 
        schema={comparativeSchema}
        rows={ctxProduct.comparatives}
        onRowChange={onRowChange}
        onRowRemove={onRowRemove}
        onRowReorder={onRowReorder}
      />
      {ctxProduct.comparatives.length > 0 && <AddComparativesButton /> }
    </div>
  );
}

export default ProductComparatives;
