import React from 'react';
import { CircularProgress, Button, Table, TableBody, TableCell, TableHead, TableSortLabel, TableRow, withStyles } from '@material-ui/core';
import { ArrowDropDown, FileCopy, Delete, Replay, Visibility } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import { blueGrey, red } from '@material-ui/core/colors';

import { ProductStatus } from '../../utils/ProductUtils'; 
import { useStateValue } from "../../providers/ProductsStateProvider";

const styles = theme => ({
  activ: {
    borderRight: '2px solid #81c784',
  },
  inactive: {
    borderRight: '2px solid #ffb74d',
  },
  deactivated: {
    borderRight: '2px solid #ff8a65',
  },
  new: {
    borderRight: '2px solid #2979ff',
  },
});
/* https://github.com/mbrn/material-table/issues/871 
 * {...props} onOrderChange={this.handleSortOrderChange} orderBy={this.state.sortedColumnId} orderDirection={this.state.sortedColumnDirection}
 */
function EnhancedTableHead(props) {
  const {columns, isLoading, } = props;
  const [ctxState, dispatch] = useStateValue();
  const orderBy = ctxState.productSearch.sort.field;
  return (
    <TableHead>
      <TableRow>
        {columns.map(col => (
          <TableCell 
            key={col.id}
            size="small"
            align={col.id === 'actions' ? 'right' : 'left'}
            width={col.width}
          >
            <TableSortLabel
              active={orderBy === col.id}
              direction={ctxState.productSearch.sort.direction}
              IconComponent={ArrowDropDown}
              onClick={col.customSort ? () => col.customSort(col) : () => alert("No CustomSort") }
            >
              {(col.id === 'actions' && isLoading) ? <CircularProgress size={18} /> : col.label }
            </TableSortLabel>
          </TableCell>
        ))}
          <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableCell = (props) => {
  const {product, col, classes } = props;
  const discarded = (product.discarded_at != null);
  const cellStyle = discarded ? {textDecoration: 'line-through'} : {};
  let columnContent;

  if (col.type === 'datetime') {
    const createdAt = new Date(Date.parse(product.created_at));
    columnContent = `${createdAt.toLocaleDateString('de-DE')}, ${createdAt.toLocaleTimeString('de-DE')}`;
  } else if (col.type === 'string' || col.type ==='integer') {
    columnContent = product[col.id];
  } else {
    columnContent = '-';
  }

  return (
    <TableCell 
      classes={{root: classes.tableCell}}
      style={ cellStyle } 
    >
      {columnContent}
    </TableCell>
  );
}

const ActionsTableCell = (props) => {
  const {product, showClone, showDelete, } = props;
  const discarded = (product.discarded_at != null);
  const cellStyle = discarded ? {textDecoration: 'line-through'} : {};
  let detailsButton, cloneButton, deleteButton;

  if(!discarded) {
    detailsButton = (
      <Link to={`/products/${product.id}`}>
        <Button color="primary">
          <Visibility style={{ color: blueGrey[600],fontSize: 24 }} />
        </Button>
      </Link>
    );

    if(showClone) {
      cloneButton = (
        <Button
          aria-label="clone"
          color="primary"
          onClick={props.onHandleClone(product.id)}
        >
          <FileCopy style={{ color: blueGrey[600],fontSize: 24 }} />
        </Button> 
      );
    }

    if(showDelete) {
      deleteButton = ( 
        <Button onClick={props.onHandleClick(product.id)} color="primary">
          <Delete style={{ color: red[600], fontSize: 24 }} />
        </Button>
      );
    }
  }
  else {
    if(showDelete) {
      deleteButton = (
        <Button color="primary">
          <Replay style={{ color: blueGrey[600], fontSize: 24 }} />
        </Button>
      );
    }
  }

  return (
    <TableCell>
      {detailsButton}
      {cloneButton}
      {deleteButton}
    </TableCell>
  );
}
function ProductTable(props){
  const {products, classes, parentClasses, columns, isLoading } = props;

  return (
    <Table className={parentClasses.table} size="small">
      <EnhancedTableHead isLoading={isLoading} columns={props.columns} />
      <TableBody>
        {products.map(s => {
          return (
            <TableRow hover key={s.id}
              className={classes[ProductStatus(s.valid_from, s.valid_to)]}
            >
              {columns.map(col => (
                <EnhancedTableCell 
                  classes={parentClasses}
                  key= {col.id}
                  col={col}
                  product={s}
                />
              ))}
              <ActionsTableCell
                product={s}
                showClone={props.showClone}
                showDelete={props.showDelete}
                onHandleClick={props.onHandleClick}
                onHandleClone={props.onHandleClone}
              />
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
export default withStyles(styles)(ProductTable);
