import React from "react";
import { ProductConsumer } from '../../providers/ProductProvider';
import { ProductContext } from '../../providers/ProductProvider';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/json';
import 'brace/theme/github';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 20px 0 20px',
  },
}));

function ProductCheckout(props) {
  const classes = useStyles();
  const { ctxProduct, actions } = React.useContext(ProductContext);
  const [ checkoutFormString, setCheckoutFormString] = React.useState(
    JSON.stringify(ctxProduct.checkout_form, null, 2));


  const onChange = (value) => {
    setCheckoutFormString(value);
  };

  const onValidate = (annotations) => {
    if (annotations.length < 1) {
      const jsonStringAsObj = JSON.parse(checkoutFormString);
      actions.onAttributeChange("checkout_form", jsonStringAsObj);
    }
  };

  return (
      <div className={classes.root}>
        <Grid className={classes.content} container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AceEditor
              mode="json"
              theme="github"
              value={checkoutFormString}
              onChange={ onChange }
              onValidate={onValidate}
              editorProps={{$blockScrolling: true}}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
              }}
              width="100%"
            />
          </Grid>
        </Grid>
      </div>
  );
}

export default ProductCheckout;
