import React from "react";
import KeyValue from '../input-key-value/KeyValue';
import { ProductConsumer } from '../../providers/ProductProvider';
import { AppSettingsConsumer } from '../../providers/AppSettingsProvider'
import { Typography } from '@material-ui/core';

export const ProductLabels = (props) => (
  <div className={props.classes.formRow}>
    <Typography className={props.classes.formLabel} variant="subtitle2" gutterBottom>Labels</Typography>
      <ProductConsumer>
        { ({ctxProduct, actions}) => (
          <AppSettingsConsumer> 
            { ({appSettings, appActions}) => (
              <KeyValue 
                rows={ctxProduct.labels} 
                keyInputPlaceholder="Schlüssel, z.B. Sparte" 
                onRowChange={actions.onLabelsChange}
                onRowRemove={actions.onLabelsRowRemove}
                valueInputPlaceholder="Wert, z.B. Hundehaftplicht" 
                labelNameValues={appSettings.data.label_names}
              />
            )}
          </AppSettingsConsumer> 
        )}
      </ProductConsumer>
  </div>
);
