import React from 'react';

import ProductVariantCsvLoader from './ProductVariantCsvLoader';
import ProductVariantSchemaMapper from './ProductVariantSchemaMapper';
import { buildSchema, } from "../../../utils/schemaUtils";
import { ProductContext } from '../../../providers/ProductProvider';

import { withStyles, Stepper, Step, StepLabel, Button, Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepnavigator: {
    marginTop: '20px',
  }
});

function getSteps() {
  return ['Datei wählen', 'Schema definieren', 'Abschließen'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Datei auswählen';
    case 1:
      return 'Schema definieren';
    case 2:
      return 'Abschließen';
    default:
      return 'Unknown step';
  }
}

function ProductVariantImportStepper(props) {
  const { classes, showSetsTable } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [csvData, setCsvData] = React.useState([]);
  const [schema, setSchema] = React.useState([]);
  const {actions, ctxProduct } = React.useContext(ProductContext);
  const steps = getSteps();

  function isStepSkipped(step) {
    return skipped.has(step);
  }

  function handleNext() {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleReset() {
    setActiveStep(0);
  }

  const handleCsvSave = (data) => {
    setCsvData(data);
    setSchema(buildSchema(data[0]));
  }
  const handleFinish = () => {
    actions.onVariantsSave(csvData, schema);
    showSetsTable();
  }

  const StepNavigator = () => (
    <div className={classes.stepnavigator}>
      <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
        Back
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
        className={classes.button}
      >
        {activeStep === steps.length - 1 ? 'Fertig' : 'Weiter'}
      </Button>
    </div>
  );

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length && (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        )} 
        { activeStep === 0 && (
          <div>
            <ProductVariantCsvLoader setCsvData={handleCsvSave}/>
            <StepNavigator />
          </div>
        )}
        { activeStep === 1 && (
          <div>
            <ProductVariantSchemaMapper csvData={csvData} schema={schema} setSchema={setSchema}/>
            <StepNavigator />
          </div>
        )}
        { activeStep === 2 && (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFinish}
              className={classes.button}
            >
              {csvData.length} Varianten importieren
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(ProductVariantImportStepper);
