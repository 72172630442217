import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone'
import { DirectUpload } from 'activestorage';
import { withStyles, LinearProgress } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import classNames from 'classnames';

import {DIGITAL_ASSET_DIRECT_UPLOAD_URL} from '../../urls';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: '15px',
  },
  uploadIconSize: {
    width: 51,
    height: 51,
    color: '#909090' 
  },
  dropZone: {
    position: 'relative',
    width: '100%',
    minHeight: '250px',
    backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: '#C8C8C8',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  dropzoneParagraph:{
    fontSize: 24
  },
  dropzoneTextStyle:{
    textAlign: 'center'
  },
  linearProgress: {
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
  },
  stripes: {
    border: 'solid',
    backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
    animation: 'progress 2s linear infinite !important',
    backgroundSize: '150% 100%',
  },
});

class AssetDropzone extends React.Component {
  constructor(props){
    super(props);
    this.dropzoneRef = React.createRef();
    this.state = {
      completed: 0,
      files: [],
      isSaving: false,
      dropzoneKey: 1,
    };
  }

  handleDropFiles(files){
    if (files.length < 1) return;
    const auth_token = () => localStorage.getItem('accessToken');
    const directUploadUrl = DIGITAL_ASSET_DIRECT_UPLOAD_URL;
    this.setState({ completed: 0 });
    files.map( (file,idx) => {
      var upload = new DirectUpload(file, directUploadUrl,{
        directUploadWillCreateBlobWithXHR: (xhr) => {
          // This decorates the requests with the access token header preventing a 401
          xhr.setRequestHeader("Authorization", `Bearer ${auth_token()}`);
        },
        directUploadWillStoreFileWithXHR: (xhr) => {
          xhr.upload.addEventListener("progress", this.handleProgress, false);
        }
      });
      upload.create((error, blob) => {
        if (error) {
          // Handle the error
          console.log(error)
        } else {
          this.props.onImageUploaded(blob);
        }
      });
    });
    this.setState(prevState => ({ 
      dropzoneKey: prevState.dropzoneKey + 1,
      completed: 0
    }));
  }

  handleProgress = (event) => {
    if (event.lengthComputable) {
      //var percentComplete = Math.round(event.loaded * 100 / event.total);
      var percentComplete = (event.loaded * 100 / event.total);
      if (percentComplete >= 100) {
        this.setState({ completed: 100 });
      } else {
        this.setState({ completed: percentComplete });
      }
    }
  }

  render(){
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.linearProgress}>
          <LinearProgress variant="determinate" value={this.state.completed} />
        </div>
        <Dropzone 
          maxSize={300000000}
          multiple={true}
          onDrop={this.handleDropFiles.bind(this)}>
          {({getRootProps, getInputProps, isDragActive}) => (
            <section>
              <div 
                className={classNames(
                  classes.dropzoneTextStyle, 
                  classes.dropZone,
                  {[classes.stripes]: isDragActive } 
                )}
                {...getRootProps(
                )}
              >
                <input {...getInputProps()} />
                <p className={classes.dropzoneParagraph}>
                  Hochladen durch Drag & Drop oder Klick für Dateiauswahl-Dialog
                </p>
                <CloudUploadIcon className={classes.uploadIconSize}/>
              </div>
            </section>
          )}
        </Dropzone>
      </React.Fragment>
    )  
  }
}

AssetDropzone.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssetDropzone);
