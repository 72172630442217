import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = ({ title, children, open, setOpen, onConfirm, onDecline }) => {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth = {'sm'}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => onDecline()}
          color="default"
          variant="contained"
        >
          Abbrechen
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="secondary"
        >
          Ja, Löschen!
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
