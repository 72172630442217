import React, { Component } from 'react';
import { Redirect, BrowserRouter, Route, Switch } from "react-router-dom";
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { darken } from '@material-ui/core/styles/colorManipulator';
//import Dashboard from "./layout/Dashboard.jsx";
import Dashboard from "./layout/PuntoFront.jsx";
import decode from 'jwt-decode';
import Login from "./components/Login.jsx";
import RecoverPassword from "./components/RecoverPassword";
import ResetPassword from "./components/ResetPassword";
import withAuthentication from './components/withAuthentication';
import DateFnsUtils from '@date-io/date-fns';

import './fonts.css';

import { MuiThemeProvider } from '@material-ui/core';

import { blue, pink } from '@material-ui/core/colors';

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    else
      return false;
  }
  catch (err) {
    return false;
  }
};

const isAuthenticated = () => {
  const token = localStorage.getItem('accessToken');
  return !!token && !isTokenExpired(token);
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isAuthenticated()
      ? <Component {...props} />
      : <Redirect to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
  )} />
)

const theme = createMuiTheme({
    palette: {
      primary: blue,
      secondary: {
        // Darken so we reach the AA contrast ratio level.
        main: darken(pink.A400, 0.08),
        },
    },
});

class App extends Component {

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <BrowserRouter>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/password/recover" component={RecoverPassword} />
              <Route path="/password/reset" component={ResetPassword} />
              <ProtectedRoute path="/" component={Dashboard} />
            </Switch>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

export default withAuthentication(App);
