import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles, Grid, TextField } from '@material-ui/core';

const styles = theme => ({
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
});

function AssetSearch(props) {
  const { classes, onSearch } = props;
  const [searchTerm, setSearchTerm] = useState('');
  /*
  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }
  */
  const handleInputChange = e => {
    const { value } = e.target
    setSearchTerm(value)
  };

  const handleSearchTermSubmit = e => {
    if(e.keyCode === 13){
      onSearch(e.target.value);
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <SearchIcon className={classes.block} color="inherit" />
      </Grid>
      <Grid item xs>
        <TextField
          fullWidth
          placeholder="Suche nach Dateinamen, Typ(jpeg, pdf) oder Tags. z.B: Don* AND Sweets -  Suchen mit Enter"
          InputProps={{
            disableUnderline: true,
            className: classes.searchInput,
          }}
          onKeyDown={handleSearchTermSubmit}
          onChange={handleInputChange}
          value={searchTerm}
        />
      </Grid>
    </Grid>
  );
}

AssetSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssetSearch);
