import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { green } from '@material-ui/core/colors';

import { IconButton, Snackbar, SnackbarContent, withStyles } from '@material-ui/core';

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
    minWidth: 350,
  },
  success: {
    backgroundColor: green[600],
    minWidth: 350,
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

class Notifier extends React.Component {
  render() {
    const { classes, notification } = this.props;

    return (
      <div>
        <Snackbar
          autoHideDuration={2500}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={notification.open}
          onClose={notification.handleClose}
        >
          <SnackbarContent
            className={notification.isError ? classes.error : classes.success}
            message={
              <span id="message-id" className={classes.message}>
                {notification.isError ?
                <ErrorIcon className={classes.icon} />
                  :
                <CheckCircleIcon className={classes.icon} />
                }
                {notification.message}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={notification.handleClose}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </div>
    );
  }
}
Notifier.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notifier);
