import React from "react";
import PropTypes from 'prop-types';
import Api from '../../../Api';
import to from '../../../to';
import { withSnackbar } from 'notistack';

import BreedListTable from './BreedListTable';
import BreedListDetail from './BreedListDetail';
import AddEntityButton from '../../AddEntityButton';

const breedListTableCols = [
  { id: 'name', label: 'Bezeichnung', type: 'string' },
  { id: 'insurance_sector', label: 'Sparte', type: 'string' },
  { id: 'created_at', label: 'Angelegt am', type: 'datetime' },
];

const emptyBreedList = {
  name: '',
  category: '',
  insurance_sector: null,
};

function BreedListContainer(props) {
  const [displayDetails, setDisplayDetails] = React.useState(false); 
  const [displayTable, setDisplayTable] = React.useState(true); 
  const [breedList, setBreedList] = React.useState(emptyBreedList); 
  const [csvFile, setCsvFile] = React.useState(); 

  const fetchBreedList = async (id) => {
    const [err, data] = await to(Api.yfetch('get', `/breed_lists/${id}`));
    if (err) {
      console.error(`Failed to load breedList id=${id}`);
      setBreedList({});
    } else {
      console.debug("Fetched breedList: ", data);
      setBreedList(data.breed_list);
    }
  };

  const handleClickCsvUpload = async(id) => {
    const reqData = new FormData();
    reqData.append('file', csvFile)
    const [err, data] = await to(Api.rawFetch('post', `/breed_lists/${id}/import_members`, reqData));
    if (err) {
      console.error(`Failed to load upload csv file to import memebers into breedList id=${id}`);
      props.enqueueSnackbar('Ein Fehler ist beim importieren aufgetreten', {variant: "error" });
    } else {
      console.debug(`Imported members to breedList id=${id}`);
      props.enqueueSnackbar('Rassen importiert', {variant: "success", autoHideDuration: 1300, });
      setBreedList(data.breed_list);
      setDisplayDetails(false);
      setDisplayTable(true);
      setCsvFile(null);
    }
  };

  const openDetails = (id) => () => {
    fetchBreedList(id);
    setDisplayDetails(true);
  };

  const closeDetails = () => {
    setDisplayDetails(false);
    setBreedList({});
  };

  const showTable = () => {
    setDisplayTable(!displayTable);
  };

  const showNewBreedList = () => {
    setBreedList(emptyBreedList);
    setDisplayDetails(true);
  };

  const handleAttributeChange = (attr, value) => {
    setBreedList({
      ...breedList,
      [attr]: value
    });
  };

  const addBreedList = () => {
    props.onBreedListAdd(breedList);
    setDisplayDetails(false);
    setDisplayTable(true);
  };


  return (
    <React.Fragment>
      { displayTable && <div>
        <AddEntityButton
          label="Rasseliste"
          onClick={showNewBreedList}
        />
          <BreedListTable 
            breedLists={props.breedLists} 
            parentClasses={props.parentClasses} 
            columns={breedListTableCols}
            onHandleClick={openDetails}
          />
      </div>}
      { displayDetails && <BreedListDetail
        breedList={breedList}
        file={csvFile}
        open={displayDetails}
        handleCloseDrawer={closeDetails}
        onAttributeChange={handleAttributeChange}
        handleSave={addBreedList}
        onCsvFileChange={setCsvFile}
        onClickCsvUpload={handleClickCsvUpload}
      />}
    </React.Fragment>
  );
}

BreedListContainer.propTypes = {
  breedLists: PropTypes.array.isRequired,
};

export default withSnackbar(BreedListContainer);
