import React from 'react';
import PropTypes from 'prop-types';
import { isWidthUp } from '@material-ui/core/withWidth';
import InfoIcon from '@material-ui/icons/Info';

import { withStyles, withWidth, GridList, GridListTile, GridListTileBar, IconButton } from '@material-ui/core';

import LoadingTile from './LoadingTile';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexGrow: 3,
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    marginRight: '20px',
  },
  gridList: {
    backgroundColor: '#cfd8dc',
    height: '418px',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
});


function _AssetGridList(props) {

  const { classes, assets } = props;
  const handelAssetSelect = asset => e => {
    props.onSelectAsset(asset);
  };
  const getGridListCols = () => {
    if (isWidthUp('xl', props.width)) {
      return 5;
    }
    if (isWidthUp('lg', props.width)) {
      return 4;
    }
    if (isWidthUp('md', props.width)) {
      return 3;
    }
    return 2;
  }
  return (
    <GridList cellHeight={120} className={classes.gridList} cols={getGridListCols()}>
      {assets.map(asset => (
        <GridListTile 
          key={asset.id}
          onClick={ handelAssetSelect(asset) }
        >
          <img src={asset.file.preview_url} alt={asset.title} />
          <GridListTileBar
            title={asset.title}
            subtitle={<span>by: {asset.id}</span>}
            actionIcon={
              <IconButton className={classes.icon}>
                <InfoIcon />
              </IconButton>
            }
          />
        </GridListTile>
      ))}
      {!props.lastPage && <LoadingTile onClick={props.handleFetchNextPage}/>}
    </GridList>
  );
}

_AssetGridList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(_AssetGridList));
