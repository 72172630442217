import React, { useState, useEffect, } from 'react';
import { Delete, FileCopy, Visibility } from "@material-ui/icons";

import { Link } from 'react-router-dom';
//import AddButton from '../AddButton';
import { AddButton } from '../SubNavigationActionButtons';

import ConfirmDialog from '../ConfirmDialog';
import SubNavigation from '../SubNavigation';
import Api from '../../Api';
import to from '../../to';

import { blueGrey, red } from '@material-ui/core/colors';

import {
  withStyles,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: '10px 15px 10px 15px',
  },
  content: {
    padding: '30px',
  },
});

function ProductFilterTable(props) {
  const { classes, isLoading } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [productFilters, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [err, data] = await to(Api.yfetch('get', '/product_filters'));
      setData(data.product_filters);
    };
    fetchData();
  }, []);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };


  //const onHandleClick = useCallback(() => handleClick(myValue), [myValue]);
  const handleDeleteClick = (id) => {
    setDialogOpen(!dialogOpen);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    const [err, data] = await to(
      Api.yfetch(
        'delete', 
        `/product_filters/${deleteId}`)
    );
    setData(productFilters.filter((row, i) => row.id !== deleteId));
    setDialogOpen(!dialogOpen);
  };

  const handleClone = productFilterId => async () => {
    const [err, data] = await to(
      Api.yfetch(
        'post', 
        `/product_filters/${productFilterId}/clone`)
    );
    if(err) {
      console.log("ProductFilter clone failed. productId=",productFilterId); 
    } else {
      props.history.push(data.product_filter.links.self.replace(/\/api\/v1/, ''));
    }
  };


  return (
    <div>
      <SubNavigation title="" backAction={() => props.history.goBack()} >
        <AddButton onClick={() => {props.history.push("/product_filters/new")}}/>
      </SubNavigation>
      <div className={classes.content}>
        <Paper className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>

              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell size="small">Name</TableCell>
                    <TableCell>Angelegt am</TableCell>
                    <TableCell align="right"> {isLoading && <CircularProgress size={18} />}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(productFilters.length > 0) && productFilters.map(s => {
                  let createdAt = new Date(Date.parse(s.created_at));
                  return (
                    <TableRow key={s.id}>
                      <TableCell>{s.name}</TableCell>
                      <TableCell>{createdAt.toLocaleDateString('de-DE')}, {createdAt.toLocaleTimeString('de-DE')}</TableCell>
                      <TableCell>
                        <Link to={`/product_filters/${s.id}`}>
                          <Button color="primary">
                            <Visibility style={{ color: blueGrey[600],fontSize: 24 }} />
                          </Button>
                        </Link>
                        <Button
                          aria-label="clone"
                          color="primary"
                          onClick={handleClone(s.id)}
                        >
                          <FileCopy style={{ color: blueGrey[600],fontSize: 24 }} />
                        </Button> 
                        <Button onClick={() => handleDeleteClick(s.id)} color="primary">
                          <Delete style={{ color: red[600], fontSize: 24 }} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
                </TableBody>
              </Table>

          {/*
              <TablePagination
                paginator={this.state.paginator}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
          */}
            </Grid>
          </Grid>
        </Paper>
      </div>
      <ConfirmDialog
        show={dialogOpen}
        proceed={() => handleDelete()}
        cancel={() => toggleDialog()}
        confirmation="Filter wirklich löschen?"/>
    </div>
  );
}

export default withStyles(styles)(ProductFilterTable);
