import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { SnackbarProvider } from 'notistack';
import ProductFilterEditor from './ProductFilterEditor';


import ProductFilterTable from './ProductFilterTable';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
  },
});

const ProductFilter = () => (
  <SnackbarProvider maxSnack={3}>
  <Switch>
    <Route 
      exact 
      path='/product_filters' 
      render={(props) => <ProductFilterTable {...props} productFilters={[]} />} 
    />
    <Route path='/product_filters/:productFilterId' component={ProductFilterEditor}/>
  </Switch>
  </SnackbarProvider>

);

export default withStyles(styles)(ProductFilter);
