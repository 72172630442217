import React from 'react';
import PropTypes from 'prop-types';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Api from '../Api.js';
import to from '../to';
import { Link } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';

import {
  Avatar,
  Button,
  Card,
  Card as CardActions,
  FormControl,
  Input,
  IconButton,
  InputLabel,
  InputAdornment,
  Typography,
  withStyles,
} from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: grey[800],
    //background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  cardActions: {
    margin: '1em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary[500],
    width: '50px',
    height: '50px',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    width: '100%',
  },
});

class ResetPassword extends React.Component {
  state = {
    email: '',
    message: null,
    password: '',
    showPassword: false,
  }

  handleResetPassword = async () => {
    const reqData = { 
      email: this.state.email ,
      token: this.state.token,
      password: this.state.password,
    };

    const [err, data] = await to(Api.yfetch('post', '/password/reset', reqData));
    if (!err) {
      this.setState(prevState => ({
        message: "Kennwort wurde gesetzt, bitte melden sie sich an"
      }));
    } else {
      this.setState(prevState => ({
        message: "Fehlgeschlagen - wenden Sie sich an ihren Administrator"
      }));
    }
  };

  handleChange = name => event => {
    this.setState({
        [name]: event.target.value
    });
  };

  handleClickShowPassword = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  catchReturn = (event) =>  {
    if (event.key === 'Enter') {
      this.handleRecoverPassword();
      event.preventDefault();
    }
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    this.setState({ 
      email: urlParams.get('email'),
      token: urlParams.get('token'),
    });
  }

  render() {
    const { classes } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } };

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon color='secondary' style={{ fontSize: 36 }} />
            </Avatar>
          </div>
         <div className={classes.form}>
           { this.state.message && <Typography variant="body2" gutterBottom>
             {this.state.message}
           </Typography> }
           <div className={classes.input}>
            <FormControl>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="adornment-password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                onChange={this.handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
           </div>
         </div>
         {this.state.message && <div className={classes.form}>
           <Link to={'/login'}>
             <Typography variant="body2" color="textSecondary">
               Zum Login
             </Typography>
           </Link>
         </div>}
         <CardActions className={classes.cardActions}>
           <Button
             disabled={this.state.message}
             variant="contained"
             type="submit"
             color="primary"
             className={classes.button}
             onClick={this.handleResetPassword}
           >
             Kennwort setzen
           </Button>
         </CardActions>
        </Card>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResetPassword);
