import React from 'react';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { grey, orange } from '@material-ui/core/colors';

import { withStyles, ButtonBase } from '@material-ui/core';

const styles = theme => ({
  deleteButton: {
    color: grey[800],
    padding: '0.71em',
    '&:hover': { 
      backgroundColor: 'red',
      color: grey[50],
    },
  },
  saveButton: {
    color: grey[800],
    padding: '0.71em',
    '&:hover': { 
      backgroundColor: 'limegreen',
      color: grey[50],
    },
  },
  dirty: {
    backgroundColor: orange[500],
    color: grey[800],
  },
  addButton: {
    color: grey[800],
    padding: '0.71em',
    '&:hover': { 
      backgroundColor: 'limegreen',
      color: grey[50],
    },
  },
});

function _CancleButton(props) {
  const { classes, onClick } = props;
  return (
     <ButtonBase className={ classes.deleteButton } onClick={onClick} title="Abbrechen">
       <ClearIcon />
     </ButtonBase>
  );
}

function _SaveButton(props) {
  const { classes, onClick } = props;
  const className = clsx(
    classes.saveButton,
    { 
      [classes.dirty]: props.isDirty && 'isDirty',
    }
  );
  return (
     <ButtonBase className={ className } onClick={onClick} title="Speichern">
       <CheckIcon />
     </ButtonBase>
  );
}

function _AddButton(props) {
  const { classes, onClick } = props;
  return (
     <ButtonBase className={ classes.addButton } onClick={onClick} >
       <AddIcon />
     </ButtonBase>
  );
}

const AddButton = withStyles(styles)(_AddButton);
const SaveButton = withStyles(styles)(_SaveButton);
const CancleButton =  withStyles(styles)(_CancleButton);
export {AddButton};
export {SaveButton};
export {CancleButton};
