import React, { createContext, useContext, useReducer } from "react"; 
export const ProductsStateContext = createContext();


export const ProductsStateProvider = ({ reducer, initialState, children }) => (
    <ProductsStateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </ProductsStateContext.Provider>
);

export const useStateValue = () => useContext(ProductsStateContext);
