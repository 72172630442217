import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
//import { Header, Footer, Sidebar } from "components";
import Sidebar from "../components/Sidebar2.jsx";

import Header from "../components/Header.jsx";
import dashboardRoutes from "../routes/dashboard.jsx";

import appStyle from "../assets/jss/dashboard/appStyle.jsx";

import logo from "../assets/img/reactlogo.png";

import Api from '../Api';

import to from '../to';
import { AppSettingsProvider } from '../providers/AppSettingsProvider';

import { withStyles } from '@material-ui/core';

const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

const ContentPadded = (classes) => (
  <div className={classes.content}>
    <div className={classes.container}>{switchRoutes}</div>
  </div>
);
const ContentRaw = (classes) => (
  <div className={classes.contentRaw}>
    {switchRoutes}
  </div>
);

class Dashboard extends React.Component {

  state = {
    mobileOpen: false,
    ApplicationSettings: {},
    ApplicationSettingsString: "",
    ApplicationSettingsNotification: {
      handleClose: () => {
        this.handleNotificationClose();
      },
      message: "Gespeichert",
    }
  };

   handleNotificationClose()  {
     this.setState({
       ApplicationSettingsNotification: {
         ...this.state.ApplicationSettingsNotification,
         open: false
       }
     });
   }

  async fetchApplicationSettings() {
    const [err, data] = await to(Api.yfetch('get', '/settings/tierversicherung.biz'));
    this.setState({
      ApplicationSettings: data.setting,
      ApplicationSettingsString: JSON.stringify(data.setting.data,null,2)
    });
  }

  handleAppSettinsDataSave = async () => {
    const newAppSettingData = {
      setting: { data: JSON.parse(this.state.ApplicationSettingsString), },
    };

    const [err, data] = await to(Api.yfetch('put', 
                          `/settings/${this.state.ApplicationSettings.tenant}`, 
                          newAppSettingData));
    if(!err) {
      this.setState(prevState => ({
        ApplicationSettings: data.setting,
        ApplicationSettingsString: JSON.stringify(data.setting.data,null,2),
        ApplicationSettingsNotification: {...this.state.ApplicationSettingsNotification, isError: false, open: true},
      }));
    }
    else {
      console.log("Error saving Application Settings");
      this.setState(prevState => ({
        ApplicationSettingsNotification: {...this.state.ApplicationSettingsNotification, isError: true, open: true, message: "Ein Fehler ist aufgetreten"},
      }));
    }
  }

  handleAppSettingsDataChange = (newValue) => {
    this.setState({ApplicationSettingsString: newValue});
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  showContetRaw() {
    //return this.props.location.pathname.startsWith("/media-manager");
    if (this.props.location.pathname.startsWith("/media-manager")) return true;
    if (this.props.location.pathname.startsWith("/product_filters")) return true;
    if (this.props.location.pathname.startsWith("/products")) return true;
    if (this.props.location.pathname.startsWith("/application-setting")) return true;
    if (this.props.location.pathname.startsWith("/risk_bearers")) return true;
    return false;
  }

  componentDidMount() {
    this.fetchApplicationSettings();
  }

  componentDidUpdate() {
    this.refs.mainPanel.scrollTop = 0;
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
        <AppSettingsProvider value={{ 
          appSettings: this.state.ApplicationSettings, 
          appSettingsString: this.state.ApplicationSettingsString, 
          appSettingsNotification: this.state.ApplicationSettingsNotification,
          appActions: {
            onSave: this.handleAppSettinsDataSave,
            handleChange: this.handleAppSettingsDataChange
          },
        }}>
          <Sidebar
            routes={dashboardRoutes}
            logoText={"Punto Front"}
            logo={logo}
            image={null}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="blue"
            {...rest}
          />
          <div className={classes.mainPanel} id="main-panel" style={{ overflow: 'none!important' }} ref="mainPanel">
            <Header
              routes={dashboardRoutes}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            { this.showContetRaw() ? ContentRaw(classes) : ContentPadded(classes) 
            }
          </div>
      </AppSettingsProvider>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(Dashboard);
