import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Paper, Tabs, Tab, withStyles, Typography } from '@material-ui/core';
import ProductDetail from './ProductDetail';
import ProductPlanDetails from './ProductPlanDetails';
import ProductComparatives from './ProductComparatives';
import ProductCheckout from './ProductCheckout';
import ProductHighlights from './ProductHighlights';
import { ProductLabels } from './ProductLabels';
import Notifier from '../Notifier';

import 'font-awesome/css/font-awesome.min.css';
import dog_breeds from '../../dog_breeds.json';

import SubNavigation from '../SubNavigation';
import { CancleButton, SaveButton } from '../SubNavigationActionButtons';
import ProductVariantPresenter from './ProductVariantPresenter';

import { green } from '@material-ui/core/colors';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingLeft: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    fontSize: 18,
    marginTop: -12,
    marginLeft: -12,
    top: '50%',
  },
  root: {
    width: "100%",
    overflowX: "auto"
  },
  content: {
    padding: '20px',
  },
  table: {
    backgroundColor: '#fefefe',
    minWidth: '700px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  leftIcon: {
    fontSize: 18,
  },
  formLabel: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: "400",
  },
  formRow: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  menu: {
    width: 220,
  },
  progress: {
    margin: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
});

class ProductDetails extends React.Component {

  state = {
    page: 0,
    tabValue: 0,
    saved: false,
    productDefinitionString: "",
  };

  handleChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  render() {
    const { classes, isLoading, isSaving, product, notification  } = this.props;
    const { tabValue} = this.state;
    const options = dog_breeds;

    if (isLoading) { return null }

    return (
    <div className={classes.root}>
      <SubNavigation title={`Product Details: ${product.internal_name}`} backAction={() => this.props.history.goBack()}>
        {isSaving && <div style={{display: 'inline-block', verticalAlign: 'middle'}}> <CircularProgress size={20} /> </div>}
        <CancleButton onClick={() => {this.props.history.push("/products")}}/>
        <SaveButton onClick={ this.props.onProductSave }/>
      </SubNavigation>
      <div className={classes.content}>
      <Paper style={{padding: '10px'}}>
        <Notifier notification={notification} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tabs
              value={this.state.tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleChange}
            >
              <Tab label="General" />
              <Tab label="Plan Details" />
              <Tab label="Labels" />
              <Tab label="Comparatives" />
              <Tab label="Highlights" />
              <Tab label="Variants/Pricing" />
              <Tab label="Checkout Form" />
            </Tabs>
            {tabValue === 0 && <TabContainer>
              <ProductDetail
                breedLists={this.props.breedLists}
                onAttributeChange={this.props.onAttributeChange}
                onLabelsChange={this.props.onLabelsChange}
                onLabelsRowRemove={this.props.onLabelsRowRemove}
                product={product}
              /> 
            </TabContainer>}
            {tabValue === 1 && <TabContainer>
               <ProductPlanDetails classes={classes} />
            </TabContainer>}
            {tabValue === 2 && <TabContainer>
               <ProductLabels classes={classes} />
            </TabContainer>}
            {tabValue === 3 && <TabContainer>
               <ProductComparatives classes={classes} />
            </TabContainer>}
            {tabValue === 4 && <TabContainer>
               <ProductHighlights classes={classes} />
            </TabContainer>}
            {tabValue === 5 && <TabContainer>
              <ProductVariantPresenter product={product} />
            </TabContainer>}
            {tabValue === 6 && <TabContainer>
              <ProductCheckout />
            </TabContainer>}
          </Grid>
         </Grid>
      </Paper>
    </div>
    </div>
    );
  }
}

ProductDetails.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductDetails);
