import React from "react";
import { withSnackbar } from 'notistack';

import {RISK_BEARER_URL} from '../../urls';
import Api from '../../Api';
import to from '../../to';
import RiskBearerTabs from './RiskBearerTabs';

class RiskBearerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleAttributeChange = this.handleAttributeChange.bind(this);
    this.handleAgentInfoChange = this.handleAgentInfoChange.bind(this);
    this.handleAgentInfoRowRemove = this.handleAgentInfoRowRemove.bind(this);

    this.state = {
      isLoading: true,
      isSaving: false,
      page: 0,
      tabValue: 0,
      saved: false,
      riskBearer: {},
      notification: {
        handleClose: () => { 
          this.handleNotificationClose(); 
        },
        isError: false,
        message: "Gespeichert",
        open: false,
      },
    };
  }

  handleNotificationClose()  {
    this.setState({
      notification: {
        ...this.state.notification,
        open: false
      }
    });
  }

  handleAttributeChange(attr, value){
    this.setState({
      riskBearer: {
        ...this.state.riskBearer,
        [attr]: value
      }
    });
  };

  handleAgentInfoRowRemove(idx) {
    let newRows = this.state.riskBearer.agent_info.filter((row, i) => i !== idx);

    this.setState({
      riskBearer: {
        ...this.state.riskBearer,
        agent_info: newRows
      }
    }); 
  }

  handleAgentInfoChange(row) {
    let newRows = this.state.riskBearer.agent_info;
    if (newRows[row['id']] ) {
      newRows[row['id']].agent_no = row.columns[0].value;
      newRows[row['id']].email = row.columns[1].value;
      newRows[row['id']].recipient_name = row.columns[2].value;
    } else {
      newRows.push({
        agent_no: row.columns[0].value,
        email:  row.columns[1].value,
        recipient_name: row.columns[2].value,
      });
    }
    this.setState({
      riskBearer: {
        ...this.state.riskBearer,
        agent_info: newRows
      }
    }); 
  }

  saveRiskBearer = async () => {
    this.setState(prevState => ({ isSaving: true }));
    if (this.state.riskBearer.id) { 
      const [err, data] = await to(Api.yfetch('put', `/risk_bearers/${this.state.riskBearer.id}`, this.state.riskBearer));
      if(err) {
        this.setState(prevState => ({
          tabValue: 0,
          isLoading: false,
          isSaving: false,
          notification: {...this.state.notification, isError: true, open: true},
        }));
      } else {
        this.setState(prevState => ({
          tabValue: 0,
          riskBearer: data.risk_bearer,
          isLoading: false,
          isSaving: false,
          saved: true,
          notification: {...this.state.notification, isError: false, open: true},
        }));
      }
    } else {
      const [err, data] = await to(Api.yfetch('post', '/risk_bearers', this.state.riskBearer));
      this.setState(prevState => ({
        tabValue: 0,
        riskBearer: data.risk_bearer,
        isLoading: false,
        isSaving: false,
        saved: true,
        notification: {...this.state.notification, open: true},
      }));
    }
  }; 

  addBreedList = async (breedList) => {
    if (!breedList.id) {
      breedList.risk_bearer_id = this.state.riskBearer.id;
      const [err, data] = await to(Api.yfetch('post', '/breed_lists/', breedList));
      if (!err) {
        const [err, data] = await to(Api.yfetch('get', `/risk_bearers/${this.state.riskBearer.id}`));
        if (data) {
          this.setState(prevState => ({
            riskBearer: data.risk_bearer,
          }));
        }
        this.props.enqueueSnackbar('Rasseliste gespeichert', {variant: "success", autoHideDuration: 1300, });
      } else {
        console.error(`Saving breedList failed error=${err}: `, breedList);
        this.props.enqueueSnackbar('Ein Fehler ist beim speichern aufgetreten', {variant: "error" });
      }
      /*
      this.setState(prevState => ({
        riskBearer: {
          ...prevState.riskBearer,
          breed_lists: [[ ...prevState.riskBearer.breed_lists, ...[breedList]]]
        }
      }), this.saveRiskBearer )
      */
    } else {
      console.warn("PUT to /breed_list/{id} not implemented yet");
    }
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    const { match: { params } } = this.props;
    /* TODO: This need urgent refactoring b/c it is fragile. 
     * The API should be the owner of an empty risk_bearer
     */
    if (params.riskBearerId === 'new') {
      this.setState({
        isLoading: false,
        tabValue: 0,
        riskBearer: {
          consultation_protocol_remarks: '',
          agent_info: [],
          products: [],
        },
      });
    } else {
      Api.search(`${RISK_BEARER_URL}/${params.riskBearerId}`, (data) => {
        this.setState({
          isLoading: false,
          tabValue: 0,
          riskBearer: data.risk_bearer,
        });
      });
    }
  }
  
  render() {
    if (this.state.isLoading) { 
      return null;
    }

    return (
      <div>
      {true && <RiskBearerTabs
        isLoading={this.state.isLoading}
        isSaving={this.state.isSaving}
        riskBearer={this.state.riskBearer} 
        onAttributeChange={this.handleAttributeChange} 
        onSave={this.saveRiskBearer}
        notification={this.state.notification}
        onAgentInfoRowDelete={this.handleAgentInfoRowRemove}
        onAgentInfoRowChange={this.handleAgentInfoChange}
        onBreedListAdd={this.addBreedList}
        {...this.props}
      />}
    </div>
    );
  }
}

RiskBearerContainer.propTypes = {
};
export default withSnackbar(RiskBearerContainer);
