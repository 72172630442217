import { drawerWidth, transition, container } from "../dashboard.jsx";

const appStyle = theme => ({
  wrapper: {
    backgroundColor: "#fafafa",
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "none",
    position: "relative",
    float: "right",
    ...transition,
    height: "100vh",
    width: "100%",
    overflowScrolling: 'touch'
  },
  content: {
    backgroundColor: "#fafafa",
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100% - 123px)"
  },
  contentRaw: {
    backgroundColor: "#fafafa",
    marginTop: "70px",
    padding: "0px 0px",
    //    height: "calc(100vh - 115px)"
  },
  container,
  map: {
    marginTop: "70px"
  }
});

export default appStyle;
