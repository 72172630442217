import React from 'react';
import { 
  FormControl, 
  Grid, 
  Input, 
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles, 
} from '@material-ui/core';


import 'react-quill/dist/quill.snow.css';
import '../../quill-tv.css';
import ReactQuill from 'react-quill';

const useStyles = makeStyles(theme => ({
  formLabel: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: "400",
  },
}));

export default function Row (props) {
  const classes = useStyles();
  const { model, onChange, idx } = props;
  const classesProp = props.classes;
  const editorModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false]}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
        {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  };

  const row = model.map((m) => {
    //const key = m.key;
    //const type = m.type || 'text';
    //const props = m.props || {};
    //const value = m.value || '';
    //const gridWidth = m.size

    if (m.type === 'text') {
      return (
        <React.Fragment key={`f-${m.attr}-${idx}`}>
          <Grid item xs={m.size || 1}>
            <FormControl fullWidth={true}>
              <Input
                className={classesProp.input}
                fullWidth={true}
                inputProps={{
                  'aria-label': 'Description',
                }} 
                key={`l-${m.key}`}
                onChange={onChange(m.attr, idx)}
                placeholder={m.label}
                type={m.type}
                value={ m.value || '' }
              />
            </FormControl>
          </Grid>
        </React.Fragment>
      );
    }

    if (m.type === 'hidden') {
      //console.debug(`hidden_attr=${m.attr} value=${m.value}`);
      return (
        <React.Fragment key={`f-${m.attr}-${idx}`}>
          <Input
            key={`l-${m.key}`}
            onChange={onChange(m.attr, idx)}
            type={m.type}
            value={ m.value || '' }
          />
        </React.Fragment>
      );
    }

    if (m.type === 'dropdown') {
      return (
        <React.Fragment key={`f-${m.attr}-${idx}`}>
          <Grid item xs={m.size}>
            <FormControl fullWidth={true}>
              <InputLabel id={`${m.attr}-${idx}`}>
                {m.label}
              </InputLabel>
              <Select
                labelId={`${m.attr}-${idx}`}
                displayEmpty
                value={ m.value || '' }
                onChange={ onChange(m.attr, idx) } 
              >
                <MenuItem value="" disabled>
                  {m.label} wählen...
                </MenuItem>
                { m.values.map((label_name, idx) => (
                    <MenuItem key={idx} value={label_name}>{label_name}</MenuItem>
                    )
                  )
                }
              </Select>
            </FormControl>
          </Grid>
        </React.Fragment>
      );
    }

    if (m.type === 'rte') {
      return (
        <React.Fragment key={`f-${m.attr}-${idx}`}>
          <Grid item xs={m.size}>
            <FormControl fullWidth={true}>
              <Typography 
                className={classes.formLabel} 
                variant="subtitle2" 
                gutterBottom>
                {m.label}
              </Typography>
              <ReactQuill 
                id={m.key} 
                modules={editorModules}
                onChange={ props.onRtfChange(m.attr, idx) }
                placeholder={`${m.label} hinzufügen`}
                value={m.value}
              />
            </FormControl>
          </Grid>
        </React.Fragment>
      );
    }
  });
  return row;
}

