import React from 'react';
import AuthUserContext from './AuthUserContext';
import decode from 'jwt-decode';

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    else
      return false;
  }
  catch (err) {
    return false;
  }
};

const isAuthenticated = () => {
  const token = localStorage.getItem('accessToken');
  return !!token && !isTokenExpired(token);
};

const userFromToken = () => {
  const token = localStorage.getItem('accessToken');
  const decoded = decode(token);
  return {id: decoded.user_id, roles: decoded.roles };
};

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      if (isAuthenticated() ) {
        this.setState(() => ({ authUser: userFromToken() }));
      }
    }

    render() {
      const { authUser } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
}

export default withAuthentication;
