import React from 'react';
import { Delete, Visibility } from "@material-ui/icons";
import { ProductContext } from '../../../providers/ProductProvider';

import ConfirmDialog from '../../ConfirmDialog';
import VariantSetActiveCheckbox from './VariantSetActiveCheckbox';

import { blueGrey, red } from '@material-ui/core/colors';

import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

function TableHeader(props) {

  return (
    <TableHead>
      <TableRow>
        <TableCell>Aktiv</TableCell>
        <TableCell>Angelegt am</TableCell>
        <TableCell>Anzahl Varianten</TableCell>
        <TableCell>&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function ProductVariantSetsTable(props) {
  const { onShowSet, variantSets } = props;
  const {actions, ctxProduct } = React.useContext(ProductContext);
  const [confirmDelete, setConfirmDelete ] = React.useState( {id: null, showDialog: false});

  const handleDialogClose = () => {
    setConfirmDelete({id: null, showDialog: false});
  };
  const handleDelete = () => {
    setConfirmDelete({id: null, showDialog: false});
    actions.onVariantsSetDelete(confirmDelete.id);
  };

  return (
    <React.Fragment>
    <Table>
      <TableHeader />
      <TableBody>
        {variantSets.map( (row, idx) => (
          <TableRow key={idx}>
            <TableCell><VariantSetActiveCheckbox variantSet={row}/></TableCell>
            <TableCell>{row.created_at}</TableCell>
            <TableCell>{row.variants.length}</TableCell>
            <TableCell>
              <Button color="primary"
                onClick= { () => onShowSet(row.id) }>
                <Visibility style={{ color: blueGrey[600],fontSize: 24 }} />
              </Button>
              <Button color="primary"
                onClick= { () => setConfirmDelete({id:row.id, showDialog:true}) }>
                <Delete style={{ color: red[600], fontSize: 24 }} />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <ConfirmDialog
       show={confirmDelete.showDialog}
       proceed={handleDelete}
       cancel={handleDialogClose}
       confirmation="Varianten wirklich löschen?"/>
  </React.Fragment>
  );
}
