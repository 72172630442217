import React from "react";
import { Switch, Route } from 'react-router-dom';
import ProductList from './ProductList.jsx';
import ProductContainer from './ProductContainer.jsx';
import { SnackbarProvider } from 'notistack';

import { ProductsStateProvider } from '../../providers/ProductsStateProvider';
import { productsReducer} from  '../../reducers/productsReducer';

const initialState = {
  productSearch: {
    sort: {
      field: 'internal_name',
      fieldType: 'string',
      direction: 'asc',
    },
    query: {
      multi_match: {
        searchTerm: '*',
        fields: [
          'internal_name',
          'title',
          'ams_linecode',
          'ams_productcode',
          'insurance_sector',
        ]
      }
    },
    filters: {
      is_discarded: false,
    },
  },
  paginator: {
    perPage: 5,
    total: 18,
  },
  page: {
    page: 1,
    per_page: 10,
  }
};

const Products = () => (
  <SnackbarProvider>
    <ProductsStateProvider initialState={initialState} reducer={productsReducer}>
      <Switch>
        <Route exact path='/products' component={ProductList}/>
        <Route path='/products/:productId' component={ProductContainer}/>
      </Switch>
    </ProductsStateProvider>
  </SnackbarProvider>
);

export default Products;
