import React from 'react';
import { DatePicker } from '@material-ui/pickers';

import 'react-quill/dist/quill.snow.css';
import '../../quill-tv.css';
import ReactQuill from 'react-quill';

import { Input, Grid, Typography } from '@material-ui/core';


const AssetData = (props) => {
  const {
    asset, 
    classes, 
    localDescriptionLong, 
    onDescriptionChange,
    onDateChange
    } = props; 
  return (
    <Grid container item xs={12} spacing={1} style={{paddingTop: "20px"}}>

      <Grid item xs={3}>
        <Typography 
          align='right' 
          className={classes.title} 
          variant="subtitle2" 
          gutterBottom>
          Title
        </Typography>
      </Grid>
      <Grid item xs={9} className={classes.info}>
        <Input
          classes={ {input: classes.inputSmall}}
          value={asset.title}
          fullWidth={true}
          inputProps={{
            'aria-label': 'Titel',
          }}
          onChange={props.onChange('title')}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography 
          align='right' 
          className={classes.title} 
          variant="subtitle2" 
          gutterBottom>
          FileUrl
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography
          className={classes.info}
          variant="subtitle2"
          gutterBottom>
          <a href={asset.file.service_url}>S3-Link</a>
        </Typography>
      </Grid>
      
      <Grid item xs={3} zeroMinWidth>
        <Typography 
          align='right' 
          className={classes.title} 
          variant="subtitle2" 
          gutterBottom 
          noWrap>
          Kurzbeschreibung:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Input
          classes={ {input: classes.inputSmall}}
          value={asset.description_short || ''}
          fullWidth={true}
          inputProps={{
            'aria-label': 'Short Description',
          }}
          onChange={props.onChange('description_short')}
        />
      </Grid>

      <Grid item xs={3} zeroMinWidth>
        <Typography
          align='right' 
          className={classes.title}
          variant="subtitle2"
          gutterBottom
          noWrap>
          Beschreibung
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <ReactQuill value={localDescriptionLong}
          onChange={onDescriptionChange } />
      </Grid>

      <Grid item xs={3}>
        <Typography
          align='right'
          className={classes.title}
          variant="subtitle2"
          gutterBottom>
          Gültig ab
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <DatePicker
          autoOk
          clearable
          disableToolbar
          format="dd/MM/yyyy"
          variant="dialog"
          value={asset.publication_on && new Date(asset.publication_on)}
          onChange={ onDateChange('publication_on') }
        />
      </Grid>

      <Grid item xs={3}>
        <Typography
          align='right'
          className={classes.title}
          variant="subtitle2"
          gutterBottom>
          Gültig bis
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <DatePicker
          autoOk
          clearable
          disableToolbar
          format="dd/MM/yyyy"
          variant="dialog"
          value={ asset.publication_off && new Date(asset.publication_off)}
          onChange={ onDateChange('publication_off') }
        />
      </Grid>

      <Grid item xs={3}>
        <Typography 
          align='right' 
          className={classes.title} 
          variant="subtitle2" 
          gutterBottom>
          Externer Dateiname
        </Typography>
      </Grid>
      <Grid item xs={9} className={classes.info}>
        <Input
          classes={ {input: classes.inputSmall}}
          value={asset.external_filename || ''}
          fullWidth={true}
          inputProps={{
            'aria-label': 'Titel',
          }}
          onChange={props.onChange('external_filename')}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography 
          align='right' 
          className={classes.title} 
          variant="subtitle2" 
          gutterBottom>
          Interner Dateiname
        </Typography>
      </Grid>
      <Grid item xs={9} className={classes.info}>
        <Input
          classes={ {input: classes.inputSmall}}
          value={asset.internal_filename || ''}
          fullWidth={true}
          inputProps={{
            'aria-label': 'Titel',
          }}
          onChange={props.onChange('internal_filename')}
        />
      </Grid>

     </Grid>
  );
};

export default AssetData;
