import React from "react";
import DynamicRowForm from '../input-dynamic-row-form/index';
import { IconButton, Typography, makeStyles } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { AppSettingsContext } from '../../providers/AppSettingsProvider';
import { ProductContext } from '../../providers/ProductProvider';


function highlightSchemaBuilder(ui) {
  return [
    {
      attr: "position", 
      key: "position", 
      label: "Position", 
      type: "hidden",
      props: {min: 0, max: 50}, 
      size: 1, 
      value: '' 
    },
    {
      attr: "title", 
      key: "title", 
      label: "Title", 
      type: "dropdown",
      values: ui.highlights_titles,
      size: 4, 
      value: '' 
    },
    {
      attr: "content", 
      key: "valueItem", 
      label: "Content", 
      type: "text",
      props: {required: true}, 
      size: 12, 
      value: '' 
    },
  ];
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  button: {
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    padding: 'unset',
  },
  icon: {
    fontSize: 20,
    opacity: 0.7,
  },
  title: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: "400",
  },
}));

function emptyComparative(array, pos) {
  const emptyComparative = 
    array.reduce((obj, item) => {
      obj[item.attr] = item.value;
      return obj
    }, {});

  emptyComparative.position = pos;
  return emptyComparative;
}


function ProductHighlights(props) {
  const classes = useStyles();
  const { appSettings } = React.useContext(AppSettingsContext);
  const { ctxProduct, actions } = React.useContext(ProductContext);

  let highlightTitles = [];

  try {
    const insuranceSectorUiSettings = appSettings.data.insurance_sector_ui[ctxProduct.insurance_sector];
    if (!insuranceSectorUiSettings) {
      throw new Error(`missing appSettings.insurance_sector_ui for product.insurance_sector=${ctxProduct.insurance_sector}`);
    }
    if (!insuranceSectorUiSettings.hasOwnProperty("highlights_titles") ) {
      throw new Error(`missing appSettings.insurance_sector_ui.highlights_titles for product.insurance_sector=${ctxProduct.insurance_sector}`);
    }

    highlightTitles = insuranceSectorUiSettings.highlights_titles;
    //console.debug("highlightTitles:", highlightTitles);
  }
  catch(error) {
    console.error(error);
  }

  const highlightSchema = highlightSchemaBuilder({
    "highlights_titles": highlightTitles,
  });

  const onRowChange = (attr, value, idx) => {
    //console.debug(`onRowChange called attr=${attr} value=${value} idx=${idx}`);
    actions.onDynamicFormAttrChange("highlights", attr, value, idx);
  }

  const onRowReorder = (src,dst) => {
    //console.debug(`onRowReorder called src=${src} dst=${dst}`);
    actions.onDynamicFormRowReorder("highlights", src, dst);
  }

  const onRowRemove = (idx) => {
    actions.onDynamicFormRowRemove("highlights", idx);
  }

  const AddHighlightsButon = () => (
    <div style={{ display: 'flex', alignItems: 'center', margin: '5px 0 5px 0'}}>
      <Typography className={classes.title} noWrap variant="subtitle2">
        Highlights
      </Typography>
      <IconButton 
        className={classes.button} 
        aria-label="Add highlight" 
        onClick={actions.onDynamicFormAddEmptyRow(emptyComparative(highlightSchema, ctxProduct.highlights.length + 1), "highlights")}>
        <AddCircleOutline className={classes.icon}/>
      </IconButton>
    </div>
  );

  return (
    <div className={classes.root}>
      <AddHighlightsButon />
      <DynamicRowForm 
        schema={highlightSchema}
        rows={ctxProduct.highlights}
        onRowChange={onRowChange}
        onRowRemove={onRowRemove}
        onRowReorder={onRowReorder}
      />
      {ctxProduct.highlights.length > 0 && <AddHighlightsButon /> }
    </div>
  );
}

export default ProductHighlights;
